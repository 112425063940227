import useLabels from "../../../hooks/useLabels";
import Alert, { AlertProps } from "../../../ui/alert/Alert";
import Button from "../../../ui/button/Button";
import { FC, useMemo } from "react";
import { NotificationType, useErrorHandlerContext } from "../ErrorContext";
import { formatDetails } from "../helpers";
import { RequiredIdentifierType, RetryType } from "../types";
import style from "../ErrorHandler.module.scss";

export type NotificationCompoundProps = RequiredIdentifierType & RetryType & { alertOverride?: AlertProps; };
export const NotificationCompound: FC<NotificationCompoundProps> = ({ id, alertOverride, retry, isStandalone, className }) => {
    const labels = useLabels();
    const { getError } = useErrorHandlerContext();
    const error = getError(id);

    const isValid = useMemo(() => {
        if (isStandalone) {
            return false;
        }
        else if (error?.type !== 'notification' || !error) {
            return true;
        }
    }, [isStandalone, error]);

    const e = useMemo(() => error as NotificationType, [error]);

    if (isValid) return null;

    return <div className={className}>
        <Alert
            type='error'
            headline={e?.headline ?? labels.unknownErrorDescription}
            description={e?.description}
            collapseDetails
            displayDetailsCta={labels.showMoreDetails}
            details={formatDetails(e?.details)}
            {...alertOverride}
            detailsClassName={style.details}
        />
        {retry && <Button onClick={async () => await retry.onAsyncClick()}>{retry.headline}</Button>}
    </div>;
};
