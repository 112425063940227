import React, { type ReactNode, useCallback, useMemo, useState } from 'react';
import { DfAccordion } from '@danfoss/mosaic-react';
import { AccordionVariantEnum } from '@danfoss/mosaic-core';
import { BaseUIType } from '../../models/UITypes';

export type AccordionProps = BaseUIType & {
    headline: string;
    content: ReactNode | string | number;

    variant?: AccordionVariantEnum;
    isDisabled?: boolean;
    className?: string;

    isInitiallyOpen?: boolean;
    unmountHiddenContent?: boolean;

    // Sync with external state 
    externalState?: {
        isOpen: boolean;
        onToggle: (isOpen: boolean) => void;
    };
};

const Accordion: React.FC<AccordionProps> = ({ unmountHiddenContent = false, testId, headline, content, isInitiallyOpen, variant, isDisabled, className, externalState }) => {
    const [localIsOpen, setLocalIsOpen] = useState(!!isInitiallyOpen);

    const onExternalToggleHandler = useCallback(() => {
        if (isDisabled) return;
        if (externalState?.onToggle) return externalState?.onToggle?.(!externalState.isOpen);
    }, [isDisabled, externalState]);

    const onToggleHandler = useCallback(() => {
        if (isDisabled) return;
        setLocalIsOpen(s => !s);
    }, [isDisabled]);

    const isExtended = useMemo(() => externalState?.isOpen ?? localIsOpen, [externalState, localIsOpen]);

    return (
        <DfAccordion data-testid={testId} isExpanded={isExtended} onHeaderClick={!!externalState ? onExternalToggleHandler : onToggleHandler} className={className} isDisabled={isDisabled} variant={variant}>
            <div slot='header'>
                {headline}
            </div>
            <div slot='content'>
                {(unmountHiddenContent && !isExtended) ? null : content}
            </div>
        </DfAccordion>
    );
};

export default Accordion;