import { danfossAssetsBaseUrlObj, sidePanelElementId } from "../constants/consts";
import EventHandler from "../services/EventHandler";
import { createActionsErrorException, ActionsErrorExceptions, ObjectTypes } from "../ui/markdown-wrapper/hooks/useActions/useActions";
import { isEnumValue } from "./enumHelper";
import { getWrap } from "./listsHelpers";
import { equals, trimChar } from "./stringHelpers";

export const MAX_ROUTE_PATH_DEPTH = 5;
const onNavigate = new EventHandler<{ path: string, hash: string; }>();

// Helper function to ensure that a string starts with a `#` symbol.
const prefix = '#/';
const suffix = '/';
const appendHash = (s: string, isSidePanelHash = true) => {
    s = trimChar(s, ['#', '/']);
    return `${isSidePanelHash ? prefix : '#'}${s}${s && isSidePanelHash ? suffix : ''}`;
};

export const processHashPaths = (currentRoute: string, newPath: string, encode = true, limit: number = MAX_ROUTE_PATH_DEPTH) => {
    // If the new path is empty, return the current route as there's no new path to process.
    if (!newPath)
        return appendHash(currentRoute);

    // Remove leading and trailing `#` symbols from the new path.
    newPath = trimChar(newPath, ['#']);
    // Remove leading and trailing `/` symbols from the new path.
    newPath = trimChar(newPath, ['/'], false);

    // If encoding is enabled (default is true), encode the URL path for safe transmission over HTTP.
    if (encode) {
        newPath = newPath.replace(/^([./]+)?(.*)$/, (_, r, p) => `${(r || "")}${encodeURIComponent(p)}`);
    }

    // If there's no current route, initialize it as an empty string.
    if (!currentRoute) currentRoute = "";

    // Extract the object type from the new hash path.
    const { objectType } = extractHashProps(trimChar(newPath, ['/', '.']));
    // If no valid object type is found, return the new path without further processing.
    if (!objectType)
        return appendHash(newPath, false);

    // Remove leading and trailing `#` or `/` from the current route.
    currentRoute = trimChar(currentRoute, ['#', '/']);
    // If the current route is not empty, prepend it with a `/`.
    if (currentRoute) currentRoute = '/' + currentRoute + '/';

    const segments = getHashRoutePaths(currentRoute);
    const lastSegment = getWrap(segments, -1);
    const { objectType: lastSegmentObjectType } = extractHashProps(lastSegment);

    if (!lastSegmentObjectType)
        return appendHash(newPath);

    // If the new path is not an absolute path (doesn't start with `/`):
    if (!newPath.startsWith('/')) {
        // Split the current route into segments by `/`.
        const segments = getHashRoutePaths(currentRoute);
        // Get the last segment of the current route.
        const lastSegment = getWrap(segments, -1);

        // If the last segment of the current route is the same as the new path (ignoring `/`), return the current route as-is.
        if (lastSegment === trimChar(newPath, '/'))
            return appendHash(currentRoute);

        // Create an exception for when the view depth limit is exceeded.
        const exception = createActionsErrorException(ActionsErrorExceptions.viewDepthLimitExceeded);
        // If the segment limit is exceeded and the exception already exists in the path, return the current route as-is.
        if (limit && segments.length > limit && segments.includes(exception)) return appendHash(currentRoute);
        // If the segment limit is exactly reached, set the new path as the exception (view depth limit exceeded).
        if (limit && segments.length === limit) newPath = exception;
    }

    // If the current route is not a valid URL (i.e., doesn't start with `http`), prepend a mock origin to it.
    if (!currentRoute.startsWith("http")) {
        if (!currentRoute.startsWith('/'))
            currentRoute = '/' + currentRoute;

        currentRoute = "http://mock" + currentRoute;
    }

    // Construct a new URL based on the new path and the current route.
    const url = new URL(newPath, currentRoute);
    // Append a `#` to the generated URL path and return it as the processed route.
    const path = appendHash(url.pathname + '/');
    return appendHash(path);
};

export const getHashPath = (path: string) => {
    if (!path.includes('#')) return { uriWithoutLastPath: '', lastPath: '', objectType: '', objectId: '' };
    const paths = path.replace('#', '').split('/').filter(x => x);
    const uriWithoutLastPath = paths.slice(0, paths.length - 1).join('/');

    const lastPath = paths[paths.length - 1];
    const { objectType, objectId } = extractHashProps(lastPath);

    return { uriWithoutLastPath, lastPath, objectType, objectId };
};

export const getHashRoutePaths = (route: string) => {
    return route.replaceAll('#', '').split('/').filter(x => x);
};

export const makeHashPath = (type?: string, id?: string) => {
    return [type, id].filter(Boolean).join('-');
};

export const extractHashProps = (hash: string) => {
    let objectType = '';
    let objectId = '';
    if (hash) {
        let fistDashIndex = hash?.indexOf('-');
        if (fistDashIndex === -1)
            fistDashIndex = hash.length;

        objectType = hash.substring(0, fistDashIndex);
        objectId = hash.substring(fistDashIndex + 1);
    }
    if (objectType && !isEnumValue(ObjectTypes, objectType)) {
        objectType = '';
        objectId = '';
    }
    return { objectType: objectType || '', objectId: objectId || '' };
};

const getValidUrl = (url?: string): URL | undefined => {
    url = url?.trim();
    if (!url) return;
    // URL.canParse throws a build error on old versions, that's why we cast to any
    if ((URL as any).canParse?.(url) === false) return;
    // We will wrap in a try/catch block just in case canParse is not available.
    try {
        return new URL(url);
    }
    catch {
        return;
    }
};

const applyImplicitHashRouting = (url: URL, currentLocation: string, isInSidePanel: boolean) => {

    if (!equals(url.host, danfossAssetsBaseUrlObj.host))
        return url;

    const urlFormat = getWrap(url.pathname.split('.'), -1).toLowerCase().trim();
    if (urlFormat !== "pdf")
        return url;

    // If url is originating from the side panel, we 
    // will let it stack over the current views, otherwise
    // we make replace the current views
    return new URL(`${currentLocation.split('#')[0]}#${isInSidePanel ? '' : '/'}pdf-${url.toString()}`);
};

const openOutside = (url: string | URL, newWindow: boolean) => {
    window.open(url, newWindow ? undefined : "_blank");
    return;
};

const globalAnchorClickHandler = (e: MouseEvent): void => {
    // If the event has already been prevented by some other handler, exit early.
    if (e.defaultPrevented)
        return;

    // Get the HTML element that triggered the event. If there's no valid target, exit early.
    const originTarget = e?.target as HTMLElement;
    if (!originTarget?.closest)
        return;

    // Find the closest ancestor element that is an anchor (`<a>`) tag.
    let anchor = originTarget.closest('a') as HTMLAnchorElement;

    // If no anchor is found, exit early (i.e., the clicked element isn't a link).
    if (!anchor)
        return;

    // Get the href attribute of the anchor tag and attempt to construct a valid URL object.
    const href = anchor.href;
    let url = getValidUrl(href);

    // If the URL is invalid or empty, exit early.
    if (!url)
        return;

    // Prevent the default anchor click behavior to manage navigation ourselves.
    e.preventDefault();

    // Get the current window's location object (current URL info).
    const location = window.location;

    // Check if the clicked link is inside the side panel by seeing if it is a descendant of the side panel element.
    const isInSidePanel = !!originTarget.closest("#" + sidePanelElementId);

    // Check if the Shift or Ctrl/Command key was pressed during the click.
    const isShiftDown = e.shiftKey;
    const isCtrlDown = e.ctrlKey || e.metaKey;

    // Check if the anchor element has a target that is not `_self` (i.e., it should open in a new tab or window).
    const isExternalTarget = anchor.target && anchor.target !== "_self";

    // If the link is meant to open in a new tab or window (external target, Shift, or Ctrl/Command key pressed), 
    // open it in a new window or tab and stop further execution.
    if (isExternalTarget || isShiftDown || isCtrlDown)
        return openOutside(url, isShiftDown);

    // Apply implicit hash routing if the URL points to certain internal assets (like PDFs), 
    // handling the special routing logic for links from the side panel.
    url = applyImplicitHashRouting(url, location.href, isInSidePanel);

    // Determine if the URL points to an external site by comparing the current host with the link's host.
    const isExternal = url.host !== location.host;

    // If it's an external link (host differs from the current host), open it in a new window or tab.
    if (isExternal)
        return openOutside(url, isShiftDown);

    // Extract the pathname (route) and query parameters from the URL.
    const path = url.pathname + url.search;
    let hash = url.hash;

    // If there's a hash fragment in the URL, process it further.
    if (hash) {
        // Get the current hash from the location (removing the leading `#`).
        const currentHash = trimChar(location.hash, "#");
        // Trim and prepare the new hash (without leading or trailing `#`).
        const newHash = trimChar(hash, "#", true, false);

        // Process the new hash paths to adjust the navigation route.
        hash = processHashPaths(currentHash, newHash);
        // Trim any leading or trailing `#` characters from the processed hash.
        hash = trimChar(hash, "#", true, false);
    }

    // Invoke the custom navigation handler with the new path and hash.
    onNavigate.invoke({ path, hash });
};



/**
 * Setup the handling of all anchor `<a />` tags clicks across the UI.
 * Behavior: 
 * 1. External links: Any external link will by default opened in a tab.
 *      1. If the link hash implicit internal routing rules (for example, DamHub PDF): 
 *          1. And if ctrl/cmd and shift key are not pressed, and the link does not have a target="_blank", 
 *          2. The link will be converted into its respective internal hash route
 *              1. If the clicked link is within the side panel, the generated hash route will be relative to the current route (see 2.2 below)
 *      1. If `shit` key is pressed, the link will be opened in a new window
 * 2. Internal links
 *      1. if ctrl/cmd or shift key is pressed, or the link ha a target="_blank", then it will be opened in a new tab or window.
 *      2. Otherwise, if the link is a hash route (explicit or implicit), it will be processed as follows:
 *          1. If the hash route does not contain one of the supported object types (pdf, trace, etc..) then it will be treated as a regular hash link
 *          2. #/route: This is considered a root hash route (it will not be appended to the current route)
 *          3. #route or #../route: This is considered a relative route to the current hash route
 *          4. The relative syntax of hash routing works in the same way you expect the regular link to work when it comes to relativity  
 * @returns EventHandler which is invoked for local anchors clicks. It returns both the path and hash routes
 */
export const setupGlobalAnchorClickHandler = () => {
    const doc = window?.document;
    doc?.removeEventListener('click', globalAnchorClickHandler);
    doc?.addEventListener('click', globalAnchorClickHandler);
    return onNavigate;
};