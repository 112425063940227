import useLabels from '../../../hooks/useLabels';
import { AnchorHeadline } from './AnchorHeadline';
import style from '../Information.module.scss';

export const About = () => {
    const labels = useLabels();

    return (
        <div className={style.about}>
            <AnchorHeadline headline={labels.aboutHeadline} />

            <p>{labels.aboutDescription}</p>
            <br />
            <p>{labels.aboutNoteHeadline}</p>
            <ul>
                <li><b>{labels.aboutNote1a}</b> {labels.aboutNote1b}</li>
                <li><b>{labels.aboutNote2}</b></li>
                <li><b>{labels.aboutNote3a}</b> {labels.aboutNote3b}</li>
            </ul>
            <br />
            <p>{labels.aboutOutro}</p>
        </div>
    );
};
