import React from 'react';
import { getClassNames } from '../../helpers/classHelpers';
import { Status } from '../../helpers/statusHelpers';
import styles from './Chip.module.scss';

export enum Version {
    minified = 'minified',
}


export type ChipProps = {
    id?: string;
    label?: string;
    icon?: React.ReactElement;
    isActive?: boolean;
    className?: string;
    version?: Version;
    theme?: Status;
    minChipWidth?: 30;
    onClick?: () => void;
};

const Chip: React.FC<ChipProps> = ({ id, label, version, theme, icon, isActive, className, minChipWidth, onClick }) => {
    const onClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!onClick) return;

        e.preventDefault();
        e.stopPropagation();
        onClick();
    };

    return (
        <div id={id} onClick={onClickHandler} style={{ minWidth: minChipWidth }} className={getClassNames(['df-chip assist', theme && styles[`theme-${theme}`], version && styles[`version-${version}`], styles.chip, !!onClick && styles['clickable'], isActive && 'selected choice multi', className])} role='button' tabIndex={0}>
            {icon}
            {label && <span>{label}</span>}
        </div>
    );
};

export default Chip;