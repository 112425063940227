import Button from '../../../ui/button/Button';
import useLabels from '../../../hooks/useLabels';
import { AnchorHeadline } from './AnchorHeadline';
import { useCallback } from 'react';
import { useAuth } from '../../../contexts/auth/AuthContext';
import { Icon } from '../../../ui/icon/Icon';
import { Icons } from '../../../ui/icon/icons/material';
import { IconStyles } from '../../../ui/icon/Icon.types';
import style from '../Information.module.scss';

// This text does not require localization. We will hardcode it here.
const feedbackEmail = (labels: ReturnType<typeof useLabels>, hostname: string, user?: string) => ({
    address: labels.feedbackAddress,
    subject: `${labels.feedbackSubject}${hostname?.includes('localhost') ? ' (LOCAL)' : hostname.includes('-uat.') ? ' (UAT)' : ''}`,
    body: labels.feedbackBody(user ?? ''),
});

const sendFeedbackEmail = (labels: ReturnType<typeof useLabels>, hostname: string, user?: string) => {
    const { address, subject, body } = feedbackEmail(labels, hostname, user);

    const params = { subject, body };

    const path = `mailto:${address}`;
    const query = Object.entries(params).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&');

    const mailWindow = window.open(`${path}?${query}`, 'mail');
    if (mailWindow) {
        // In some browsers, mailto links always result in an blank tab (if we do not want them to take over the current window)
        // Below is an attempt to clean up that blank tab when the user return the current window
        mailWindow.focus();
        const closeMailWindow = () => mailWindow.close();
        mailWindow.addEventListener('unload', () => window.removeEventListener('focus', closeMailWindow));
        window.addEventListener('focus', closeMailWindow);
    }
};

export const Feedback = () => {
    const { user } = useAuth();
    const labels = useLabels();
    const handleReportIssue = useCallback(() => sendFeedbackEmail(labels, window.location.hostname, user?.name), [labels, user]);

    return (
        <div>
            <AnchorHeadline headline={labels.feedbackHeadline} />
            <div>
                <p>{labels.feedbackDescription}</p>
                <i className={style.disclaimer}>{labels.feedbackDisclaimer1}
                    &nbsp;
                    "
                    <Icon.Base
                        size={16}
                        title={labels.likeTitle}
                        iconName={Icons.thumbUp}
                        iconStyle={IconStyles.outlined}
                    />
                    &nbsp;
                    <Icon.Base
                        size={16}
                        title={labels.dislikeTitle}
                        iconName={Icons.thumbDown}
                        iconStyle={IconStyles.outlined}
                    />
                    "
                    &nbsp;
                    {labels.feedbackDisclaimer2}
                    .
                </i>
                <Button onClick={handleReportIssue} label={labels.feedbackButton} isSmall />
            </div>
        </div>
    );
};
