const readableFileSize = (bytes?: number) => {
    if (!bytes && bytes !== 0) return "";
    if (bytes === 0) return "0 B";
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    if (isNaN(i)) return "";
    const rounding = i <= 1 ? 0 : 1;
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(rounding)) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

const getExtension = (fileName: string) => {
    const parts = fileName.split('.');
    const name = parts.slice(0, -1).join('.');
    const extension = '.' + parts[parts.length - 1];

    return {
        name,
        extension
    };
};

const isFileSizeValid = (file: File, maxSizeB: number) => file.size <= maxSizeB;


export { readableFileSize, getExtension, isFileSizeValid };