import EventHandler from '../EventHandler';
import { IBackendEventsService } from '../IBackendEventsService';


class ServiceBackendEventsHelper {

    constructor(private events: Record<string, EventHandler<any>>, private backendEvents: IBackendEventsService) {
    }

    bind = () => {
        this.bindBackendEvents();
    };
    unbind = () => {
        this.unbindBackendEvents();
    };

    private bindBackendEvents = () => {
        Object
            .entries(this.events)
            .forEach(([eventName, eventHandler]) => this.backendEvents.on(eventName, eventHandler.invoke));
    };

    private unbindBackendEvents = () => {
        Object
            .entries(this.events)
            .forEach(([eventName, eventHandler]) => this.backendEvents.off(eventName, eventHandler.invoke));
    };
}

export default ServiceBackendEventsHelper;