import React, { type PropsWithChildren } from 'react';
import BackendEventProvider from './BackendEventsContext';
import MockBackendEventsService from '../../services/MockBackendEventsService';

const MockBackendEventsServiceProvider: React.FC<PropsWithChildren> = ({ children }) => {
    return <BackendEventProvider service={MockBackendEventsService.shared()}>
        {children}
    </BackendEventProvider>;
};

export default MockBackendEventsServiceProvider;