import useStateRef from '../../hooks/useStateRef';

const useChatInfo = () => {
    const [persona, setPersona] = useStateRef<string>();

    return {
        chatInfo: {
            persona,
            setPersona
        }
    };
};

export default useChatInfo;