import useLabels from '../../hooks/useLabels';
import ButtonAsync from '../../ui/button/ButtonAsync';
import Input from '../../ui/input/Input';
import Modal from '../../ui/modal/Modal';
import Button, { ButtonThemes } from '../../ui/button/Button';
import Textarea from '../../ui/textarea/Textarea';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { ErrorHandler } from '../../contexts/error-handler/ErrorHandler';
import { getClassNames } from '../../helpers/classHelpers';
import style from './DocumentUpdateModal.module.scss';

const VALID_CHARS = /[A-Za-z0-9\s()[\]_.-]+/;
const VALID_FILE_NAME_CHARS = /^[A-Za-z0-9\s()[\]_.-]+$/;

type DocumentUpdateModalProps = {
    errorId: string;
    registerError: (headline: string, description: string, error?: any) => void;
    removeError: (errorId: string) => void;
    initialName?: string;
    initialDescription?: string;
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (name: string, description: string) => Promise<void>;
    errorMessage?: string;
};

const DocumentUpdateModal: React.FC<DocumentUpdateModalProps> = ({
    errorId,
    registerError,
    removeError,
    initialName = '',
    initialDescription = '',
    isOpen,
    onClose,
    onSubmit,
}) => {
    const labels = useLabels();
    const l = useMemo(() => ({
        header: labels.documentUpdateHeader,
        namePlaceholder: labels.namePlaceholder,
        descriptionPlaceholder: labels.descriptionPlaceholder,
        cancel: labels.cancel,
        update: labels.update,
        invalidFileNameError: labels.invalidFileNameError,
        uploadErrorHeadline: labels.uploadErrorHeadline,
        invalidFileNameLength: labels.invalidFileNameLength,
        invalidFileNameChars: labels.invalidFileNameChars,
    }), [labels]);

    const [name, setName] = useState(initialName);
    const [description, setDescription] = useState(initialDescription);

    useEffect(() => {
        setName(initialName);
        setDescription(initialDescription);
    }, [initialName, initialDescription]);

    const invalidNameMessage = useMemo((): string | undefined => {
        if (!name.trim()) return l.invalidFileNameError;
        if (name.length > 255) return l.invalidFileNameLength;
        if (!VALID_FILE_NAME_CHARS.test(name)) {
            const invalidCharsSet = new Set(name.split('').filter(char => !VALID_CHARS.test(char)));
            return l.invalidFileNameChars + ': ' + Array.from(invalidCharsSet).join(' ');
        };
    }, [name, l]);

    const handleSubmit = useCallback(async () => {
        removeError(errorId);

        try {
            await onSubmit(name, description); // Allow empty description
        }
        catch (err) {
            registerError(l.uploadErrorHeadline, '', err);
        }
    }, [removeError, errorId, registerError, l.uploadErrorHeadline, onSubmit, name, description]);

    return (
        <Modal
            closeOnEscape
            closeOnBackdropClick
            isOpen={isOpen}
            headline={l.header}
            className={style['document-update-modal']}
            onClose={onClose}
            size='medium'
            body={(
                <>
                    <ErrorHandler.Notification id={errorId} />

                    <div className={style['input-container']}>
                        <fieldset className={getClassNames([style['df-form-field'], invalidNameMessage && style.error])}>
                            <b className={style.label}>{l.namePlaceholder}</b>
                            <Input
                                placeholder={l.namePlaceholder}
                                value={name}
                                onChange={setName}
                                className={style.input}
                            />
                            {invalidNameMessage && (
                                <p className={style['error-message']}>
                                    {invalidNameMessage}
                                </p>
                            )}
                        </fieldset>

                        <fieldset className={style['df-form-field']}>
                            <b className={style.label}>{l.descriptionPlaceholder}</b>
                            <Textarea
                                rows={5}
                                resize='none'
                                placeholder={l.descriptionPlaceholder}
                                value={description}
                                onChange={setDescription}
                                className={style.input}
                            />
                        </fieldset>
                    </div>
                </>
            )}
            footer={(
                <>
                    <Button label={l.cancel} onClick={onClose} theme={ButtonThemes.textPrimary} />
                    <ButtonAsync
                        label={l.update}
                        onClick={handleSubmit}
                        isDisabled={!!invalidNameMessage}
                    />
                </>
            )}
        />
    );
};

export default DocumentUpdateModal;