import Async from './Async';
import Base from './Base';
import Svg from './Svg';
import { AdaptiveIconProps, AsyncIconProps, IconProps, SvgIconProps } from '../Icon.types';
import { Icons } from '../icons/material';
import { SvgIcons } from '../icons/svg';
import { useMemo } from 'react';
import { Image } from '../../image/Image';
import { getEnumByKeyOrValue } from '../../../helpers/enumHelper';

const urlPrefixes = ['https://', 'http://', 'data:image', 'blob:'];
export default function Adaptive(props: AdaptiveIconProps) {

    const iconName = useMemo(() => getEnumByKeyOrValue(Icons, props.iconName, true), [props.iconName]);
    const svgIconName = useMemo(() => getEnumByKeyOrValue(SvgIcons, props.iconName, true), [props.iconName]);
    const url = urlPrefixes.find(start => props.iconName.startsWith(start)) ? props.iconName : undefined;

    if (!!props.onAsyncClick) return <Async {...props as AsyncIconProps} />;
    else if (iconName) return <Base {...props as IconProps} iconName={iconName} />;
    else if (svgIconName) return <Svg {...props as SvgIconProps} iconName={svgIconName} />;
    else if (url) return <Image.Base src={url} showLoadingWhenFetching minifyStyle height={props.imageSize || props.size} width={props.imageSize || props.size} alt={props.title} />;

    console.error(`Icon is not found in Icons or SvgIcons enum. Falling back to default icon.`, { props });
    return <Base {...props as IconProps} iconName={Icons.report} />;
};
