import { CustomSelectOptionBaseType, CustomSelectOptionType, SelectChangeEventArgsType, SelectOptionType } from '@danfoss/mosaic-core';
import { DfSelectCustomEvent } from '@danfoss/mosaic-core/loader';
import { DfSelect } from '@danfoss/mosaic-react';
import { ChangeEvent, useCallback, useState } from 'react';
import { BaseUIType } from '../../models/UITypes';
import { isNumber } from '../../helpers/typeHelpers';

export type SelectEventType = DfSelectCustomEvent<SelectChangeEventArgsType<false, false>>;

type Props = {
    /** Sets default selection value */
    selectedOptionIndex?: number;
    options: CustomSelectOptionType[];
    onChange: (index?: CustomSelectOptionBaseType) => void;
    value?: string;
    isDisabled?: boolean;
    showSelectedOptions?: boolean;
};

const Select = ({ testId, value, isDisabled, selectedOptionIndex, showSelectedOptions = false, options, onChange }: BaseUIType & Props) => {
    const handleChange = useCallback((e: SelectEventType) => onChange(e.detail.value as CustomSelectOptionBaseType), [onChange]);

    return (
        <DfSelect
            options={options}
            data-testid={testId}
            disabled={isDisabled}
            showSelectedOptions={showSelectedOptions}
            value={value}
            defaultSelectValue={isNumber(selectedOptionIndex) ? options?.[selectedOptionIndex] : undefined}
            onSelectChange={handleChange}
        />
    );
};

export const SelectStatic = ({ label, testId, value, options, onChange }: { label?: string, testId?: string, value?: string, options: SelectOptionType[], onChange: (value: string) => void; }) => {
    const [val, setVal] = useState(value);

    const handleChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setVal(value);
        onChange(value);
    }, [onChange]);

    return <fieldset className="df-form-field" data-testid={testId}>
        {label && <label className="df-field-label" htmlFor="select-5">{label}</label>}
        <select value={val} id="select-5" className="df-select" onChange={(e) => handleChange(e as any)}>
            {options.map(({ value, label }) => <option key={value} value={value}>{label}</option>)}
        </select>
    </fieldset>;
};

export default Select;