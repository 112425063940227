import useLabels from '../../../hooks/useLabels';
import AsyncMarkdownWrapper from '../../../ui/markdown-wrapper/AsyncMarkdownWrapper';
import { VISIBLE_RELEASES } from '../config';
import { AnchorHeadline } from './AnchorHeadline';
import { ReleaseTimeline } from './ReleaseTimeline';


const UIReleaseNotes = ({ active, version }: { active: boolean, version: string; }) => {
    if (!active) return null;
    const url = `${process.env.PUBLIC_URL}/release-notes/v${version}/README.md`;
    return <AsyncMarkdownWrapper url={url} />;
};

export const UiUxChanges = () => {
    const labels = useLabels();
    return <div>
        <AnchorHeadline headline={labels.uiUxHeadline} />
        {VISIBLE_RELEASES.map((release, index) => <ReleaseTimeline key={index} content={(isOpen) => <UIReleaseNotes active={isOpen} version={release.version} />} {...release} />)}
    </div>;
};