import Base from './Base';
import { AsyncIconProps } from '../Icon.types';
import React, { useCallback, useRef, useState } from 'react';
import { Icons } from '../icons/material';

export default function Async(props: AsyncIconProps) {
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const [statusIcon, setStatusIcon] = useState<Icons>();
    const [asyncIsLoading, setAsyncIsLoading] = useState<boolean>();

    const handleFeedbackFlashing = useCallback((icon: Icons.clear | Icons.check) => {
        if (props.isLoading || !props.flashFeedbackIcon) return;
        setStatusIcon(icon);

        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => setStatusIcon(undefined), 2000);
    }, [props.flashFeedbackIcon, props.isLoading]);

    const handleClick = useCallback(async (e: React.MouseEvent) => {
        const handler = props.onAsyncClick;
        if (!handler) return;

        setAsyncIsLoading(true);

        try {
            const promise = await handler(e);
            handleFeedbackFlashing(Icons.check);
            setAsyncIsLoading(false);
            return promise;
        }
        catch (e) {
            handleFeedbackFlashing(Icons.clear);
            setAsyncIsLoading(false);
        }

    }, [handleFeedbackFlashing, props.onAsyncClick]);

    return <Base
        {...props}
        isLoading={asyncIsLoading || props.isLoading}
        iconName={statusIcon || props.iconName}
        onClick={handleClick}
    />;
};