import { isDevelopmentEnv } from "../../helpers/envConfigHelper";

export const TARGET_ANCHOR_KEY = 'is';

export const RELEASES: {
    version: string;
    date: string;
    /** If marked as true it will appear only in localhost env. */
    draft?: boolean;
}[] = [
        {
            version: '1.7.0',
            date: 'N/A',
            draft: true,
        },
        {
            version: '1.6.0',
            date: '2024-12-02',
        },
        {
            version: '1.5.0',
            date: '2024-10-30',
        },
        {
            version: '1.4.0',
            date: '2024-09-23',
        },
        {
            version: '1.3.0',
            date: '2024-09-03',
        },
        {
            version: '1.2.0',
            date: '2024-07-02',
        },
        {
            version: '1.1.0',
            date: '2021-04-24',
        },
        {
            version: '1.0.0',
            date: '2021-03-14',
        },
    ];


export const VISIBLE_RELEASES = RELEASES.filter(release => {
    const isDevelopment = isDevelopmentEnv();
    if (isDevelopment) return release;
    return !release.draft;
});

export const CURRENT_RELEASES_VERSION = RELEASES.find(release => {
    const isDevelopment = isDevelopmentEnv();
    if (isDevelopment) return release;
    return !release.draft;
});

export const headlineValue = (version: string, draft?: boolean) => checkReleaseVersion(`Version: ${version}`, draft);
export const checkReleaseVersion = (label: string, draft?: boolean) => (draft ? '(Draft) - ' : '') + label;
