import { useEffect } from "react";

const TARGET_DATA_ATTR_KEY = 'data-table-config'; // TODO: Change this to the actual class name

type TableConfig = {
    src: string;
    map: unknown;
};

const getTargetElements = () => document.querySelectorAll(`[${TARGET_DATA_ATTR_KEY}]`);

const init = () => {
    const targetElements = getTargetElements();

    targetElements.forEach(async (element) => {
        const htmlElement = element as HTMLElement;
        const { src, map } = (htmlElement.dataset as unknown) as TableConfig;

        if (src && map) {
            console.log({ src, map });
        }
    });
};

export default function useDynamicTable({ isActive }: { isActive: boolean; }) {
    useEffect(() => {
        if (!isActive) return;
        init();
        return () => {};
    }, [isActive]);
}