import Card from '../../ui/card/Card';
import { getClassNames } from '../../helpers/classHelpers';
import { isObject } from '@danfoss/mosaic-core';
import { Icons } from '../../ui/icon/icons/material';
import { Starter } from '../../models/types';
import styles from './ChatRecommendations.module.scss';

const ChatStarters = ({ className, starters, isDisabled, onSubmit, onPrefill }: { className?: string, starters?: Starter[], isDisabled?: boolean, onSubmit: (recommendation: string) => Promise<void>; onPrefill: (recommendation: string) => void; }) => {
    if (!starters?.length) return null;
    return (
        <div className={getClassNames([className, styles['chat-recommendations'], starters.length % 3 === 0 && styles['three-column-layout']])}>
            {starters.map((s) => {
                const label = isObject(s) ? s.prompt : s;
                const hasAutoSubmit = typeof s === 'object' && s.autoSubmit;

                return <Card
                    key={label}
                    description={label}
                    className={styles.card}
                    iconClassName={styles.icon}
                    titleClassName={styles.title}
                    isDisabled={isDisabled}
                    icon={{ iconName: hasAutoSubmit ? Icons.send : Icons.keyboardExternalInput, size: 20 }}
                    onClick={() => {
                        if (hasAutoSubmit) onSubmit(label);
                        else onPrefill(label);
                    }}
                />;
            })}
        </div>
    );
};

export default ChatStarters;