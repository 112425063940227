import Button, { ButtonThemes } from '../../ui/button/Button';
import useLabels from '../../hooks/useLabels';
// import { ObjectTypes, usePathRouting } from '../../ui/markdown-wrapper/hooks/useActions/useActions';
import { IconStyles, IconTheme } from '../../ui/icon/Icon.types';
import { Icon } from '../../ui/icon/Icon';
import { Icons } from '../../ui/icon/icons/material';
import { BackendEvent, SessionEventTypes } from '../../models/BackendEvents';
import styles from './SystemEvent.module.scss';

type ExtendedBackendEvent = BackendEvent & { quantity?: number; };

const SystemEvent = ({ event }: { event: ExtendedBackendEvent; }) => {
    const props = useGetMessage(event) || {};

    if (!event || !props.message) return null;

    return <div className={styles['system-event']}>
        <span className='container'>
            <span className='event'>
                <Message {...props} />
                <MessageAsLink {...props} />
                <MessageWithCta {...props} />
            </span>
        </span>
    </div>;
};

// 

const useGetMessage = (event: ExtendedBackendEvent) => {
    const labels = useLabels();
    // const { invokePathNavigateToSidePanel } = usePathRouting();
    const appendQuantity = (message: string) => (((event.quantity || 1) > 1) ? `${event.quantity} x ` : '') + message;

    const list: Record<string, MessageProps> = {
        [SessionEventTypes.updated]: {
            message: 'subject' in (event.metadata) ? appendQuantity(labels.chatNameUpdated + ' ' + event.metadata.subject) : undefined,
        },
        [SessionEventTypes.documentAdded]: {
            message: appendQuantity(labels.documentAdded),
            // callbackLabel: labels.documentAddedCta,
            // callback: () => invokePathNavigateToSidePanel(ObjectTypes.documents, true)
        },
        [SessionEventTypes.documentRemoved]: {
            message: appendQuantity(labels.documentRemoved),
        },
    };

    return (list)?.[event.type];
};

// 

export type MessageProps = {
    message?: string;
    callbackLabel?: string;
    callback?: () => void;
};


const Message = ({ message, callback, callbackLabel }: MessageProps) => {
    if (callback || callbackLabel) return null;

    return <>
        {message}
    </>;
};

const MessageAsLink = ({ message, callbackLabel, callback }: MessageProps) => {
    if (!callback || callbackLabel) return null;

    return <Button className={styles['message-as-link']} onClick={callback} theme={ButtonThemes.textSecondary}>
        <div>
            <Icon.Base className={styles.icon} iconStyle={IconStyles.filled} title='' theme={IconTheme.inherit} iconName={Icons.link} />
            {message}
        </div>
    </Button>;
};


const MessageWithCta = ({ message, callbackLabel, callback }: MessageProps) => {
    if (!callback || !callbackLabel) return null;

    return <>
        {message}
        &nbsp;
        <Button className={styles['message-with-cta']} onClick={callback} label={callbackLabel} theme={ButtonThemes.textPrimary} />
    </>;
};

export default SystemEvent;