import React from 'react';
import { About } from './elements/About';
import { AiAssistantChanges } from './elements/AiAssistantChanges';
import { ReleaseNotesOverview } from './elements/ReleaseNotesOverview';
import { UiUxChanges } from './elements/UiUxChanges';
import { useInitAnchorScrollTo } from './elements/useInitAnchorScrollTo';
import { Feedback } from './elements/Feedback';
import style from './Information.module.scss';


const InformationView: React.FC = () => {
    useInitAnchorScrollTo();

    return (
        <div className={style.information}>
            <About />
            <Feedback />
            <ReleaseNotesOverview />
            <div className={style['wrapper-with-line-break']}>
                <AiAssistantChanges />
                <UiUxChanges />
            </div>
        </div>
    );
};

export default InformationView;
