import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export type SortableRenderFunction<T> = (info: {
    item: T;
    isActive: boolean;
    isDragged: boolean;
    props?: React.HTMLAttributes<HTMLElement>;
    handleProps?: React.HTMLAttributes<HTMLElement>;
    ref: (node: HTMLElement | null) => void;
}) => JSX.Element;

type SortableItemProps<T> = {
    id: UniqueIdentifier;
    item: T;
    renderFunction: SortableRenderFunction<T>;
    isDragged?: boolean;
};

const SortableItem = <T extends object>({ id, item, renderFunction, isDragged = false }: SortableItemProps<T>) => {
    const { setNodeRef, attributes, listeners, transform, transition, activeIndex, index } = useSortable({ id });

    return renderFunction({
        item,
        ref: setNodeRef,
        props: {
            ...attributes,
            style: {
                transform: CSS.Transform.toString(transform),
                transition
            }
        },
        handleProps: listeners,
        isActive: index >= 0 && activeIndex === index,
        isDragged
    });
};

export default SortableItem;