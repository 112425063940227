import Checkbox from '../../../../../../../checkbox/Checkbox';
import Prompt from '../../../../../../../prompt/Prompt';
import useLabels from '../../../../../../../../hooks/useLabels';
import useInfo from '../../../../../../../../hooks/useInfo';
import Description from '../../../../../../../description/Description';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { SelectStatic } from '../../../../../../../select/Select';
import { getClassNames } from '../../../../../../../../helpers/classHelpers';
import { Icon } from '../../../../../../../icon/Icon';
import { IconTheme } from '../../../../../../../icon/Icon.types';
import { Icons } from '../../../../../../../icon/icons/material';
import { useParams } from 'react-router-dom';
import { TestIds } from '../../../../../../../../mocks/ids';
import { useAdaptive } from '../../../../../../../../contexts/adaptive/AdaptiveContext';
import { isString } from '../../../../../../../../helpers/typeHelpers';
import { transformDataRowToPresentation } from '../document-list/DocumentList';
import { readableFileSize } from '../../../../../../../../helpers/fileHelpers';
import { tenderAssistantKey } from '../../../../../../../../constants/consts';
import styles from './DocumentListActions.module.scss';

type Props = {
    items: ReturnType<typeof transformDataRowToPresentation>,
    selectedIds: string[];
    onSelectAll: () => void;
    onDeselectAll: () => void;
    onEdit: (documentData: { fileId: string, name: string; description: string; }) => void;
    onDownload: () => void;
    onDelete: () => void;
    onRefresh: () => void;
    onCopy: (selectedIds: string[], assistant: string, hidePanelAfterCopy?: boolean) => void;
};

const DocumentListActions = ({ items, selectedIds, onRefresh, onSelectAll, onDeselectAll, onEdit, onCopy, onDownload, onDelete }: Props) => {
    const labels = useLabels();
    const { chatId } = useParams();
    const [modalOpen, setModalOpen] = useState<'copy' | 'delete'>();
    const isInNewChat = useMemo(() => !chatId, [chatId]);

    const handleCloseModal = useCallback(() => setModalOpen(undefined), []);
    const invokeCopyModal = useCallback(() => setModalOpen('copy'), []);
    const invokeDeleteModal = useCallback(() => setModalOpen('delete'), []);
    const handleDownload = useCallback(() => onDownload(), [onDownload]);

    const renderSelectedNames = useMemo(() => <Description threshold={80} labels={{ seeLess: labels.seeLess, seeMore: labels.seeMore }} description={items.filter(item => selectedIds.includes(item.id)).map(item => item.name).join(', \n\n')} />, [items, labels.seeLess, labels.seeMore, selectedIds]);

    const handleRefresh = useCallback(() => onRefresh(), [onRefresh]);

    const handleEdit = useCallback(() => {
        if (selectedIds.length !== 1) return console.warn('No documents selected to edit');

        const selectedDocument = items.find(item => item.id === selectedIds[0]);
        if (!selectedDocument) return console.warn('Document not found, cannot edit');

        onEdit({
            fileId: selectedDocument.id,
            name: selectedDocument.name,
            description: selectedDocument.description
        });
    }, [items, selectedIds, onEdit]);

    const isAllSelected = useMemo(() => items.length === selectedIds.length, [items.length, selectedIds.length]);

    const actionsMap = useMemo(() => [
        {
            label: labels.docListDownload,
            iconName: Icons.download,
            isDisabled: selectedIds.length !== 1,
            onClick: handleDownload,
            testId: TestIds.downloadButton
        },
        {
            label: labels.docListDelete,
            iconName: Icons.delete,
            isDisabled: selectedIds.length < 1,
            onClick: invokeDeleteModal,
            testId: TestIds.deleteButton
        },
        {
            label: labels.docListEdit,
            iconName: Icons.edit,
            isDisabled: selectedIds.length !== 1,
            onClick: handleEdit,
            testId: TestIds.editButton
        },
        !isInNewChat ? {
            label: labels.docListCopy,
            iconName: Icons.copy,
            isDisabled: selectedIds.length === 0,
            onClick: invokeCopyModal,
            testId: TestIds.copyButton
        } : undefined,
        {
            label: '',
            iconName: Icons.refresh,
            onClick: handleRefresh,
            testId: TestIds.refreshButton
        }
        // TODO add responsive menu to accommodate the all items in this list
    ].filter(Boolean) as ListActionLabelProps[], [labels.docListDownload, labels.docListDelete, labels.docListEdit, labels.docListCopy, selectedIds.length, handleDownload, invokeDeleteModal, handleEdit, isInNewChat, invokeCopyModal, handleRefresh]);

    return (
        <div className={styles['actions-container']}>
            <Checkbox isChecked={isAllSelected} onChange={isAllSelected ? onDeselectAll : onSelectAll} />
            <div className={styles.actions}>
                {actionsMap?.map((props, i) => <ListActionLabel key={i} {...props} />)}
            </div>

            <DeleteModal
                isOpen={modalOpen === 'delete'}
                onClose={handleCloseModal}
                renderSelectedNames={renderSelectedNames}
                onDelete={onDelete} />

            <CopyModal
                isOpen={modalOpen === 'copy'}
                onClose={handleCloseModal}
                renderSelectedNames={renderSelectedNames}
                selectedItems={items.filter(item => selectedIds.includes(item.id))}
                onCopy={(selectedAssistant) => {
                    const minimizeSidePanel = [tenderAssistantKey].includes(selectedAssistant);
                    onCopy(selectedIds, selectedAssistant, minimizeSidePanel);
                }}
            />
        </div>
    );
};

type ListActionLabelProps = {
    iconName: Icons;
    isDisabled: boolean;
    label: string;
    title?: string;
    onClick?: () => void;
    testId: string;
};

const ListActionLabel = ({ title, iconName, isDisabled, label, testId, onClick }: ListActionLabelProps) => {
    return (
        <label data-testid={testId} className={getClassNames([isDisabled && styles['_disabled']])} onClick={isDisabled ? undefined : onClick}>
            <Icon.Base theme={IconTheme.inherit} size={18} iconName={iconName} title={title ?? ''} isClickable={!isDisabled} />
            {label}
        </label>
    );
};

export default DocumentListActions;

const CopyModal = ({ onCopy, onClose, isOpen, selectedItems, renderSelectedNames }: { selectedItems?: ReturnType<typeof transformDataRowToPresentation>, isOpen: boolean, onCopy: (selectedAssistant: string) => void, onClose: () => void, renderSelectedNames: ReactNode; }) => {
    const labels = useLabels();
    const { chatInfo } = useAdaptive();
    const { allowedPersonaOptions, getPersonaFromKey } = useInfo();

    const [selectedAssistant, setSelectedAssistant] = useState<string | undefined>(chatInfo.persona);
    const { documents } = getPersonaFromKey(selectedAssistant || '-') ?? {};
    const { formats, length: maxSize, max } = documents || {};

    const readableAssistantList = useCallback((list: { name: string; }[]) => list.map?.(({ name }) => name).join(', '), []);

    const inValidMessage = useMemo(() => {
        if (max && selectedItems && selectedItems?.length > max) return labels.copyErrorValidateMaxSize(max);

        const exceedsSize = maxSize ? selectedItems?.filter(({ size }) => size > maxSize) : [];
        if (!!exceedsSize?.length) return labels.copyErrorValidateMaxLimit(readableFileSize(maxSize), readableAssistantList(exceedsSize));

        const unsupportedFormats = formats && selectedItems?.filter(({ extension, }) => !formats.includes(extension));
        if (!!unsupportedFormats?.length) return labels.copyErrorValidateExtension(readableAssistantList(unsupportedFormats));
    }, [labels, formats, max, maxSize, readableAssistantList, selectedItems]);


    const allowedOptions = useMemo(() => allowedPersonaOptions.filter(({ documents }) => documents?.enabled).map(({ title: label, key: value }) => ({ value, label })), [allowedPersonaOptions]);

    const renderSelectAssistant = useMemo(() => {
        return <div className={styles['select-container']}>
            {allowedOptions.length > 1 && <SelectStatic
                label={labels.copySelectChatAssistant}
                value={selectedAssistant}
                options={allowedOptions}
                onChange={setSelectedAssistant}
            />}
        </div>;
    }, [allowedOptions, labels.copySelectChatAssistant, selectedAssistant, setSelectedAssistant]);

    const renderInValidMessage = useMemo(() => {
        if (!inValidMessage) return null;
        return <div className={styles['invalid-message']}>
            {inValidMessage}
        </div>;
    }, [inValidMessage]);

    const confirmCopy = useCallback(() => {
        if (!isString(selectedAssistant)) return;
        onCopy(selectedAssistant);
        onClose();
    }, [selectedAssistant, onCopy, onClose]);

    return <Prompt
        headline={labels.copyToNewChatHeadline}
        header={labels.copyToNewChatDescription}
        body={<div className={styles.content}>
            {renderSelectedNames}
            {renderSelectAssistant}
            {renderInValidMessage}
        </div>}
        secondaryButton={{ label: labels.copyToNewChatCancelCta }}
        primaryButton={{ label: labels.copyToNewChatCta, onClick: confirmCopy, isDisabled: !!inValidMessage }}
        isOpen={isOpen}
        onClose={onClose}
    />;
};


const DeleteModal = ({ onDelete, onClose, isOpen, renderSelectedNames }: { isOpen: boolean, onDelete: () => void, onClose: () => void, renderSelectedNames: ReactNode; }) => {
    const labels = useLabels();

    const confirmDelete = useCallback(() => {
        onDelete();
        onClose();
    }, [onClose, onDelete]);

    return <Prompt
        headline={labels.deleteDocumentModalTitle}
        header={labels.deleteDocumentConfirmationText}
        body={renderSelectedNames}
        secondaryButton={{ label: labels.deleteDocumentCancelButton }}
        primaryButton={{ label: labels.deleteDocumentDeleteButton, onClick: confirmDelete }}
        isOpen={isOpen}
        onClose={onClose}
    />;
};