import React, {} from 'react';
import { getClassNames } from '../../helpers/classHelpers';
import style from './WindowHeader.module.scss';

export type HeaderProps = {
    left?: React.ReactNode;
    center?: React.ReactNode;
    right?: React.ReactNode;
    className?: string;
    disableInteractions?: boolean;
};

const WindowHeader: React.FC<HeaderProps> = ({ className, disableInteractions, left, center, right }) => {
    return (<>
        <header className={getClassNames([className, style.header])}>
            <div className={style.left}>
                {left}
            </div>

            <div className={style.center}>
                {center}
            </div>

            <div className={style.right}>
                {right}
            </div>

            {disableInteractions && <div className={style.overlay}></div>}
        </header>
    </>);
};

export default WindowHeader;