import Accordion from '../../../../../../../accordion/Accordion';
import Alert from '../../../../../../../alert/Alert';
import MarkdownWrapper from '../../../../../../MarkdownWrapper';
import useStorage from '../../../../../../../../hooks/useStorage';
import useLabels from '../../../../../../../../hooks/useLabels';
import { useEffect, useMemo, useState } from 'react';
import { useAppInfo } from '../../../../../../../../contexts/app-info/AppInfoContext';
import styles from './Information.module.scss';

const STORE_SCOPE_KEY = 'side-panel';
const STORE_KEY = 'docs-info-expanded';

const Information = () => {
    const labels = useLabels();
    const [isOpen, setIsOpen] = useState(true);
    const { documents } = useAppInfo();

    const { get, set } = useStorage(STORE_SCOPE_KEY, 'local', 'json');
    const handleSetIsOpen = (value: boolean) => {
        setIsOpen(value);
        set(STORE_KEY, value);
    };

    useEffect(() => {
        (async () => {
            setIsOpen(await get(STORE_KEY));
        })();
    }, [get]);

    const { instructions, limitations } = documents || {} as any;

    const content = useMemo(() => {
        return (
            <div className={styles['content']}>
                {instructions && <Alert children={<MarkdownWrapper markdown={instructions} />} />}
                {limitations && <Alert type={'warning'} children={<MarkdownWrapper markdown={limitations} />} />}
            </div>
        );
    }, [instructions, limitations]);

    if (!instructions && !limitations) return null;

    return (
        <Accordion
            className={styles['information']}
            unmountHiddenContent
            externalState={{
                isOpen,
                onToggle: handleSetIsOpen
            }}
            headline={labels.docInfoHeader}
            content={content}
        />
    );
};

export default Information;