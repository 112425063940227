import EnvConfigHelper from '../../../helpers/envConfigHelper';
import { HTTPDataService } from '../../../services/HTTPDataService';
import { TourIds } from '../consts';
import { ToursResponseType } from '../types/ToursTypes';
import { IToursService } from './IToursService';

class ToursService extends HTTPDataService implements IToursService {
    baseURL = EnvConfigHelper.get('api-base-url', '');

    constructor(public getAccessToken: () => Promise<string | undefined>, public get: (k: string) => any, public set: (k: string, value: any) => void) {
        super();
    }

    // TODO: Connect to API
    getTours = async () => {
        const seen = this.get('seen') as string[];
        const values = Object.values(TourIds);

        const unseen = values.filter((value) => !seen?.includes(value));
        return { unseen } as ToursResponseType;
    };

    // TODO: Connect to API
    setTours = async (ids: string[]) => {
        const seen = this.get('seen') as string[];
        const data = [...(seen || []), ...ids].filter(Boolean);
        this.set('seen', data);
    };
}

export default ToursService;