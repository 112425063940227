import useLabels from '../../../hooks/useLabels';
import { Icon } from '../../../ui/icon/Icon';
import { useCallback, useMemo } from 'react';
import { copyToClipboard } from '../../../helpers/stringHelpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { dictionaryToQueryString, queryStringToDictionary } from '../../../helpers/routeHelpers';
import { delimit } from '../../../helpers/listsHelpers';
import { TARGET_ANCHOR_KEY } from '../config';
import { Icons } from '../../../ui/icon/icons/material';
import style from '../Information.module.scss';


const normalize = (string: string) => string.toLowerCase().replaceAll('/', '').replaceAll(':', '').replaceAll(/ /g, '-');
export const normalizeAnchorId = (key: string, prefix?: string) => delimit([prefix && normalize(prefix), normalize(key)], '-');

export const AnchorHeadline = ({ headline, type = 'h2', prefix }: { headline: string; type?: 'h2' | 'h5'; prefix?: string; }) => {
    const labels = useLabels();
    const location = useLocation();
    const navigate = useNavigate();

    const id = normalizeAnchorId(headline, prefix);

    const copy = useCallback(() => {
        const hash = { [TARGET_ANCHOR_KEY]: id };
        const prev = queryStringToDictionary(location.hash);
        const hashString = dictionaryToQueryString({ dict: { ...prev, ...hash }, delimiter: '#' });
        const fullUrl = `${window.location.origin}${location.pathname}${location.search}${hashString}`;
        copyToClipboard(fullUrl);
        navigate({ ...location, search: location.search, hash: hashString }, { replace: true });
    }, [id, location, navigate]);

    const props = { id, className: style['anchor-headline'], onClick: copy };

    const content = useMemo(() => {
        return <>
            {headline}
            <Icon.Base className={style['anchor-icon']} iconName={Icons.link} title={labels.anchorHeadlineTitle} />
        </>;
    }, [labels, headline]);

    if (type === 'h5') return <h5 {...props}>{content}</h5>;
    return <h2 {...props}>{content}</h2>;
};
