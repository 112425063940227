import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaSize } from '../../hooks/useMediaSize';
import { MediaSize } from '../../constants/consts';
import { useLocation } from 'react-router-dom';

export type RightSidebarProps = {
    header?: React.ReactNode;
    children?: React.ReactNode;
    metadata?: Record<string, string>;
};

export type LayoutContextProps = {
    isLeftSidebarVisible: boolean;
    showLeftSidebar: () => void;
    hideLeftSidebar: () => void;

    isRightSidebarVisible: boolean;
    showRightSidebar: () => void;
    hideRightSidebar: () => void;

    isShowingTermsAndConditions?: boolean;
    setIsShowingTermsAndConditions: (isShowing: boolean) => void;

    /** Since there is no clear way of detecting wether  */
    hasCookieConsent?: boolean,
    setHasCookieConsent: (hasConsent: boolean) => void;
};

const defaultLayout = {
    isLeftSidebarVisible: false,
    showLeftSidebar: () => {},
    hideLeftSidebar: () => {},

    isRightSidebarVisible: false,
    showRightSidebar: () => {},
    hideRightSidebar: () => {},

    isShowingTermsAndConditions: false,
    setIsShowingTermsAndConditions: () => {},

    hasCookieConsent: false,
    setHasCookieConsent: () => {},
};

const LayoutContext = createContext(defaultLayout as LayoutContextProps);

const LayoutProvider = (props: PropsWithChildren<{}>) => {
    const location = useLocation();
    const cookieProps = useAttachCookieRenewListener();

    const [isShowingTermsAndConditions, setIsShowingTermsAndConditions] = useState(false);
    const [isRightSidebarVisible, setIsRightSidebarVisible] = useState(false);
    const [isLeftSidebarVisible, setIsLeftSidebarVisible] = useState(false);

    const showLeftSidebar = useCallback(() => setIsLeftSidebarVisible(true), []);
    const hideLeftSidebar = useCallback(() => setIsLeftSidebarVisible(false), []);

    const showRightSidebar = useCallback(() => setIsRightSidebarVisible(true), []);

    const hideRightSidebar = useCallback(() => {
        setIsRightSidebarVisible(false);
    }, []);

    const isTabletSize = useMediaSize(ms => ms >= MediaSize.md);
    const autoPresetLeftSidebar = useMemo(() => !isRightSidebarVisible && isTabletSize, [isRightSidebarVisible, isTabletSize]);

    const value = useMemo(() => ({
        isLeftSidebarVisible: isLeftSidebarVisible || autoPresetLeftSidebar,
        showLeftSidebar,
        hideLeftSidebar,

        isRightSidebarVisible,
        showRightSidebar,
        hideRightSidebar,

        isShowingTermsAndConditions,
        setIsShowingTermsAndConditions,

        ...cookieProps,
    }), [isLeftSidebarVisible, autoPresetLeftSidebar, showLeftSidebar, hideLeftSidebar, isRightSidebarVisible, showRightSidebar, hideRightSidebar, isShowingTermsAndConditions, setIsShowingTermsAndConditions, cookieProps]);

    // Close right sidebar upon location change
    const pathnameRef = useRef(location.pathname);
    useEffect(() => {
        if (pathnameRef.current && location.pathname === pathnameRef.current) return;
        pathnameRef.current = location.pathname;
        hideRightSidebar();
    }, [location, hideRightSidebar]);

    return <LayoutContext.Provider value={value}>
        {props.children}
    </LayoutContext.Provider>;
};

export default LayoutProvider;

export const useLayout = () => useContext(LayoutContext);

const useAttachCookieRenewListener = () => {
    const [hasCookieConsent, setHasCookieConsent] = useState<boolean | undefined>();
    return { hasCookieConsent, setHasCookieConsent };
};