import { useCallback, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { closestCenter, DndContext, DragEndEvent, DragOverlay, DragStartEvent, KeyboardSensor, PointerSensor, UniqueIdentifier, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import SortableListItem, { SortableRenderFunction } from './SortableListItem';


type SortableListProps<T> = {
    items: T[];
    getItemId?: (item: T) => UniqueIdentifier;
    renderItem: SortableRenderFunction<T>;
    onSort: (oldIndex: number, newIndex: number) => void;
};

const defaultGetItemId = (item: any) => item.id;

export const SortableList = <T extends object>({ items, getItemId = defaultGetItemId, renderItem, onSort }: SortableListProps<T>) => {
    const itemIds = useMemo(() => items.map(getItemId), [items, getItemId]);

    const [activeId, setActiveId] = useState<UniqueIdentifier>();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );


    const handleDragCancel = () => setActiveId(undefined);

    const handleDragStart = useCallback((event: DragStartEvent) => {
        setActiveId(event.active.id);
    }, []);

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;
            if (over && active.id !== over.id) {
                const oldIndex = itemIds.indexOf(active.id);
                const newIndex = itemIds.indexOf(over.id);
                if (onSort) onSort(oldIndex, newIndex);
            }
            setActiveId(undefined);
        },
        [itemIds, onSort]
    );


    return (
        <>
            <DndContext
                sensors={sensors}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
                collisionDetection={closestCenter}
                modifiers={[restrictToFirstScrollableAncestor]}
            >
                <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
                    {itemIds.map((id, i) => <SortableListItem key={i} id={id} item={items[i]} renderFunction={renderItem} />)}
                </SortableContext>

                {createPortal(
                    <DragOverlay adjustScale={false} dropAnimation={null}>
                        {activeId
                            ? <SortableListItem id={activeId} item={items[itemIds.indexOf(activeId)]} renderFunction={renderItem} isDragged />
                            : <></>
                        }
                    </DragOverlay>,
                    document.body
                )}
            </DndContext>
        </>
    );
};
