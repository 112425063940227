import { AxiosResponse } from 'axios';

export const formatDetails = (detail?: Record<string, unknown>) => {

    // Remove sensitive data from RAW the error details
    if ((detail && detail?.config as AxiosResponse)?.headers?.Authorization) {
        (detail.config as AxiosResponse).headers.Authorization = 'Bearer Token';
    }

    return detail ? <pre>{JSON.stringify(detail, undefined, 4)}</pre> : undefined;
};