import React from 'react';
import MarkdownWrapper from '../../../ui/markdown-wrapper/MarkdownWrapper';
import useInfo from '../../../hooks/useInfo';
import useLabels from '../../../hooks/useLabels';
import AsyncMarkdownWrapper from '../../../ui/markdown-wrapper/AsyncMarkdownWrapper';
import { CURRENT_RELEASES_VERSION, checkReleaseVersion } from '../config';
import { AnchorHeadline } from './AnchorHeadline';

export const ReleaseNotesOverview = () => {
    const labels = useLabels();
    const rawVersion = checkReleaseVersion('v' + CURRENT_RELEASES_VERSION?.version!, CURRENT_RELEASES_VERSION?.draft);
    return (
        <div>
            <AnchorHeadline headline={labels.releaseNotesHighlights} />
            <h4>{labels.releaseNotesUiUx} - {rawVersion}</h4>
            <UiUxHighlight version={CURRENT_RELEASES_VERSION?.version!} />
            <AiHighlight />
        </div>
    );
};


const UiUxHighlight = ({ version }: { version: string; }) => {
    const url = `${process.env.PUBLIC_URL}/release-notes/v${version}/HIGHLIGHTS.md`;
    return <AsyncMarkdownWrapper url={url} />;
};

const AiHighlight = () => {
    var { allowedPersonaOptions } = useInfo();
    return <>
        {
            allowedPersonaOptions
                .filter(p => p.versionHighlights)
                .map((p, i) => <React.Fragment key={i}>
                    <h4>{p.title} - v{p.version}</h4>
                    <MarkdownWrapper markdown={p.versionHighlights || "- "} />
                </React.Fragment>)
        }
    </>;
};
