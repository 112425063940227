import EventHandler from './EventHandler';
import { IBackendEventsService } from './IBackendEventsService';

/**
 * To mock backend events for your tests, call MockBackendEventsService.dispatch(eventName, message);
 */
class MockBackendEventsService implements IBackendEventsService {

    private readonly eventHandlers: { [eventName: string]: EventHandler<unknown>; } = {};

    private getEventHandler = (eventName: string) => this.eventHandlers[eventName] ??= new EventHandler<unknown>();

    on = (eventName: string, callback: (message: unknown) => void) => {
        this.getEventHandler(eventName).on(callback);
    };

    off = (eventName: string, callback: (message: unknown) => void) => {
        this.getEventHandler(eventName).off(callback);
    };

    private static _shared = new MockBackendEventsService();
    static shared = () => this._shared;

    static dispatch = (eventName: string, message: unknown) => {
        this.shared().getEventHandler(eventName).invoke(message);
    };
}

export default MockBackendEventsService;

