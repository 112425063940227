import useLabels from './useLabels';
import { useCallback, useMemo, } from 'react';
import { rawLlmToUI } from '../helpers/transformHelpers';
import { useAppInfo } from '../contexts/app-info/AppInfoContext';
import { sortItemsBy } from '../helpers/listsHelpers';
import { DocumentInfoType, PersonaOption } from '../models/types';
import { advancedAssistantKey, advancedAssistantRoute, pimAssistantKey, pimAssistantRoute } from '../constants/consts';
import { SvgIcons } from '../ui/icon/icons/svg';

export default function useInfo() {
    const labels = useLabels();
    const { llms, personas, access, documents: docs } = useAppInfo();

    const llmOptions = useMemo(() => {
        if (!llms) return [];
        return llms
            .map(rawLlmToUI)
            .sort((a, b) => a.sort - b.sort);
    }, [llms]);

    // Do not return this object directly in this hook's response, as it exposes all personas not taking into account the IAM
    const personaOptions = useMemo(() => {
        if (!personas?.length) return [];

        let items = [...sortItemsBy(personas, "sort"), {
            key: advancedAssistantKey,
            route: advancedAssistantRoute,
            title: labels.advancedTitle,
            icon: SvgIcons.InstantMix,
            avatar: SvgIcons.InstantMix,
            description: labels.advancedDescription,
            documents: {
                enabled: true,
            },
        }].map((persona) => {
            const { enabled, formats, instructions, length, limitations, max } = persona.documents || {};
            const documents: Partial<DocumentInfoType> = {
                formats: formats || docs?.formats,
                instructions: instructions || docs?.instructions,
                length: length || docs?.length,
                limitations: limitations || docs?.limitations,
                max: max || docs?.max,
                enabled: enabled || docs?.enabled,
            };

            // Assign routeKey to PIM assistant. 
            // Before it was using 'dcs' route as a mean to select persona via URI - while other personas wasn't having this option. 
            // Thus here we're making sure that the URI is pointing to legacy route path instead of persona key.
            if (persona.key === pimAssistantKey) persona.route = pimAssistantRoute;
            persona.route = persona?.route || persona.key;

            return {
                ...persona,
                documents
            };
        }) as PersonaOption[];

        return items;
    }, [docs, labels.advancedDescription, labels.advancedTitle, personas]);

    const allowedPersonaOptions = useMemo(() => {
        return personaOptions.filter(p => !p.key || !!access?.personas?.[p.key]);
    }, [personaOptions, access]);

    const getPersonaFromKey = useCallback((key: string) => personaOptions?.find(p => p.key === key), [personaOptions]);

    const getPersonaFromRouteKey = useCallback((key: string) => personaOptions?.find(p => p?.route === key), [personaOptions]);

    const getLlmFromKey = useCallback((key: string) => llms?.find(llm => llm.key === key), [llms]);

    const getLlmLabelFromKey = useCallback((key: string) => getLlmFromKey(key)?.title || key, [getLlmFromKey]);

    const getPersonaLabelFromKey = useCallback((key: string) => getPersonaFromKey(key)?.title ?? key, [getPersonaFromKey]);

    return {
        llmOptions,
        allowedPersonaOptions,
        getPersonaFromKey,
        getPersonaFromRouteKey,
        getLlmFromKey,
        getLlmLabelFromKey,
        getPersonaLabelFromKey
    };
}