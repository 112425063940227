import ChatWindowHeader from '../chat-window-header/ChatWindowHeader';
import useSessionEvents from '../hooks/useSessionEvents';
import useLoadChatMessages from '../hooks/useLoadChatMessages';
import useRefLive from '../../hooks/useRefLive';
import Loader from '../../ui/loader/Loader';
import Alert from '../../ui/alert/Alert';
import Message from '../message/Message';
import { ChatViewLayoutProps } from '../../layouts/chat-view-layout/ChatViewLayout';
import { Data } from '../../ui/markdown-wrapper/hooks/useActions/actions/data/Data';
import { ObjectTypes } from '../../ui/markdown-wrapper/hooks/useActions/useActions';
import { useCallback, useEffect, useMemo } from 'react';
import { SessionEventTypes, SessionEventTypesPrefixes } from '../../models/BackendEvents';
import { sortBy } from '../../helpers/listsHelpers';
import style from './WorkflowWindow.module.scss';


const WorkflowWindow = ({ onOpenSidebar, chatId }: ChatViewLayoutProps) => {
    const { events, setEvents } = useSessionEvents({});
    const { chat, isLoadingChatHistory, chatHistoryError, renderChatHistoryError, reload } = useLoadChatMessages({ chatId });
    const lastWorkflowEvent = useMemo(() => sortBy(events, "timestamp", true).find(x => x.type.startsWith(SessionEventTypesPrefixes.workflow)), [events]);

    useEffect(() => {
        setEvents([]);
    }, [chatId, setEvents]);

    useEffect(() => {
        setEvents(chat?.events ?? []);
    }, [chat, setEvents]);


    const reloadIfNeeded = useCallback((event: SessionEventTypes, prevEvent: SessionEventTypes) => {
        if (!prevEvent)
            return;

        const type = event;

        if (!type)
            return;
        if ([SessionEventTypes.workflowFailed, SessionEventTypes.workflowCompleted].includes(type)) {
            reload(undefined, true, true);
        }
    }, [reload]);

    useRefLive(lastWorkflowEvent?.type as SessionEventTypes, reloadIfNeeded);


    const content = useMemo(() => {
        if (isLoadingChatHistory || !lastWorkflowEvent)
            return <div><Loader isVisible /></div>;

        if (!!chatHistoryError)
            return renderChatHistoryError;

        switch (lastWorkflowEvent?.type) {
            case SessionEventTypes.workflowFailed:
                return <Alert type='error' headline='Error' description={lastWorkflowEvent.metadata?.error} />;

            case SessionEventTypes.workflowCompleted:
                return <>
                    <Data objectId={lastWorkflowEvent.metadata.artifact ?? 'data'} objectType={ObjectTypes.data} chatId={chat?.session.session_id} />
                    {
                        chat?.messages.map(m => <Message
                            key={m.id}
                            persona={chat?.session.persona}
                            chatId={chat?.session.session_id}
                            feedback={chat.feedback?.find((f) => f.message_id === m.id)}
                            message={m} />)
                    }
                    <br />
                    <br />
                    <br />
                </>;

            case SessionEventTypes.workflowUpdated:
                return <Alert type='info' headline='Please wait' description={(lastWorkflowEvent.metadata?.state ?? "Processing") + ".."} />;
        }
    }, [lastWorkflowEvent, chat?.session.session_id, chat?.messages, chat?.feedback, chat?.session.persona, isLoadingChatHistory, chatHistoryError, renderChatHistoryError]);

    return (
        <div className={style['workflow']}>
            <div className={style['wrapper']}>
                <ChatWindowHeader
                    initialTitle={chat?.session?.subject || ''}
                    onBurgerClick={onOpenSidebar}
                // onRenameChat={onRenameChatHandler}
                />
                <div className={style['content']}>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default WorkflowWindow;