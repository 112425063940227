import useEffectAsync, { CancellationToken } from '../../hooks/useEffectAsync';
import useLabels from '../../hooks/useLabels';
import { AppRoute } from '../../router/Routing';
import { useCallback, useMemo, useState } from 'react';
import { useChat } from '../../contexts/chat/ChatContext';
import { useNavigate } from 'react-router-dom';
import { GetChatResponse } from '../../models/ChatTypes';
import { ErrorHandler } from '../../contexts/error-handler/ErrorHandler';
import { useErrorHandlerContext } from '../../contexts/error-handler/ErrorContext';
import { NewChatButton } from '../../layouts/chat-view-layout/ChatViewLayout';
import useCachedPromise from '../../hooks/useCachedPromise';

type Props = {
    active?: boolean;
    chatId?: string;
};

const useLoadChatMessages = ({ active = true, chatId }: Props) => {
    const labels = useLabels();
    const navigate = useNavigate();
    const { getChat } = useChat();

    const [chat, setChat] = useState<GetChatResponse>();
    const [isLoadingChatHistory, setIsLoadingChatHistory] = useState(true);
    const { errorId, registerError, removeError, getError } = useErrorHandlerContext();
    const error = getError(errorId);


    const getData = useCachedPromise(() => getChat(chatId!), [chatId]);
    const reload = useCallback(async (cancellationToken?: CancellationToken, silent = false, force = false) => {
        removeError(errorId);

        try {
            if (!silent) {
                setIsLoadingChatHistory(true);
            }

            const chat = await getData(force);

            if (cancellationToken?.cancelled)
                return;
            setChat(chat);
        } catch (err: any) {

            if (cancellationToken?.cancelled)
                return;

            registerError({
                [errorId]: {
                    type: 'modal',
                    headline: labels.historyErrorHeadline,
                    notification: {
                        headline: labels.historyErrorOccurredWhileLoading,
                        description: labels.chatNotFound,
                        details: err
                    },
                    onClose: () => navigate(AppRoute.chat)
                }
            });
        } finally {
            if (!silent) {
                setIsLoadingChatHistory(false);
            }
        }

    }, [getData, errorId, labels, navigate, registerError, removeError]);

    useEffectAsync(token => () => {
        if (!chatId || !active) return;
        reload(token);
    }, [labels, chatId, active, reload]);

    const renderChatHistoryError = useMemo(() => <ErrorHandler.Modal id={errorId} footer={<NewChatButton />} />, [errorId]);

    return { chat, isLoadingChatHistory, chatHistoryError: error, renderChatHistoryError, reload };
};

export default useLoadChatMessages;