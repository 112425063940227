import usePersistentInputState from '../../views/chat-view/usePersistentInputState';
import usePersistentAutoSubmitState from '../../views/chat-view/usePersistentLoadingForState';
import MockChatService from '../../services/MockChatService';
import ChatProvider from './ChatContext';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { useAuth } from '../auth/AuthContext';
import { useBackendEvents } from '../backend-events/BackendEventsContext';

const MockChatServiceProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const persistentInputs = usePersistentInputState();
    const persistentAutoSubmit = usePersistentAutoSubmitState();
    const persistent = { ...persistentInputs, ...persistentAutoSubmit };

    const { getAccessToken } = useAuth();
    const backendEvents = useBackendEvents();
    const mockService = useMemo(() => new MockChatService(getAccessToken, backendEvents), [getAccessToken, backendEvents]);
    useEffect(() => {
        mockService.bindEvents();
        return () => mockService.unbindEvents();
    }, [mockService]);

    return <ChatProvider {...mockService} persistent={persistent}>
        {children}
    </ChatProvider>;
};

export default MockChatServiceProvider;