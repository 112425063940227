import * as signalR from '@microsoft/signalr';
import EnvConfigHelper from '../helpers/envConfigHelper';
import { HTTPDataService } from './HTTPDataService';
import { IBackendEventsService } from './IBackendEventsService';
import EventHandler from './EventHandler';
import { isJwtExpired } from '../helpers/jwtHelper';

type NegotiationRepose = {
    url: string;
    access_token: string;
};
class BackendEventsService extends HTTPDataService implements IBackendEventsService {
    baseURL = EnvConfigHelper.get('api-base-url', '');

    constructor(public getAccessToken: () => Promise<string | undefined>) {
        super();

    }
    private didInit = false;
    init = async () => {
        if (this.didInit)
            return;

        this.didInit = true;

        try {
            const negotiation = await this.negotiate();
            this.connect(negotiation);
        }
        catch (e) {
            console.error('Failed to initialize SignalR connection for the BackendEventsService', e);
        }
    };


    private connection: signalR.HubConnection | undefined;
    private connect = (negotiation: NegotiationRepose) => {

        if (this.connection)
            return;

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(negotiation.url, {
                accessTokenFactory: async () => {

                    if (isJwtExpired(negotiation.access_token, 60))
                        negotiation = await this.negotiate();

                    return negotiation.access_token;
                },
            })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (_) => {
                    return 15e3;
                }
            })
            .build();

        this.connection.start();
        this.connection.onclose(() => console.info('SignalR: closed'));
        this.connection.onreconnecting(() => console.info('SignalR: reconnecting'));
        this.connection.onreconnected(() => console.info('SignalR: reconnected'));
        this.alignHandlers();
    };

    private negotiate = () => {
        return this.post<NegotiationRepose>('api/signalr/negotiate');
    };

    private readonly eventHandlers: { [eventName: string]: EventHandler<unknown>; } = {};
    private getEventHandler = (eventName: string) => this.eventHandlers[eventName] ??= new EventHandler<unknown>();

    on = (eventName: string, callback: (message: unknown) => void) => {
        this.getEventHandler(eventName).on(callback);
        this.alignHandlers();
    };

    off = (eventName: string, callback: (message: unknown) => void) => {
        this.getEventHandler(eventName).off(callback);
        this.alignHandlers();
    };

    private alignHandlers = () => {
        const conn = this.connection;
        if (!conn)
            return;

        Object.entries(this.eventHandlers).forEach(([eventName, eventHandler]) => {
            conn.off(eventName);
            eventHandler.peekHandlers().forEach(handler => conn.on(eventName, payload => {
                try {
                    handler(JSON.parse(payload));
                }
                catch (e) {
                    console.error(`Failed to parse event: '${eventName}'. Payload: ${payload}`);
                }
            }));
        });
    };

    private static _shared?: BackendEventsService;
    static configure = (getAccessToken: () => Promise<string | undefined>) => {
        if (!this._shared)
            this._shared = new BackendEventsService(getAccessToken);

        this._shared.getAccessToken = getAccessToken;
        return this._shared;

    };

    public static shared = () => {
        if (this._shared) return this._shared;
        throw new Error('You must call configure first before using the shared instance.');
    };
}

export default BackendEventsService;