import PageLayout from '../../layouts/page-layout/PageLayout';
import Information from '../../elements/information/Information';
import style from './InformationView.module.scss';

const InformationView: React.FC = () => {
    return (
        <PageLayout mainClassName={style['information-view']}>
            <div className={style['content-wrapper']}>
                <Information />
            </div>
        </PageLayout>
    );
};

export default InformationView;