import ToursService from '../services/ToursService';
import ToursContext from './ToursContext';
import { useAuth } from '../../../contexts/auth/AuthContext';
import { PropsWithChildren, useMemo, } from 'react';
import useStorage from '../../../hooks/useStorage';

const ToursProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { getAccessToken } = useAuth();
    const { get, set } = useStorage('tours', 'local', 'json');
    const service = useMemo(() => new ToursService(getAccessToken, get, set), [get, getAccessToken, set]);

    return <ToursContext {...service}>
        {children}
    </ToursContext>;
};

export default ToursProvider;