import { Step } from 'react-joyride';
import { TourIds } from '../consts';
import { AppRoute } from '../../../router/Routing';
import { TourReturnType, ToursSharedTargets } from '../Tours';
import { defaultPersonaKey } from '../../../constants/consts';
import { ObjectTypes } from '../../../ui/markdown-wrapper/hooks/useActions/useActions';
import { DEMO_TOUR_SUCCESS_FILE_UPLOAD_KEY } from './useTourFileUploads';

export enum TourCatalogTargets {
    catalogCta = 'tac-catalog-cta',
    personaList = 'tac-persona-list',
    star = 'tac-star',
    preferenceList = 'tac-preferences',
    search = 'tac-search',
    select = 'tac-select',
}

const tourId = TourIds.assistantCatalog;
const useTourCatalog = (): TourReturnType => {
    return {
        id: tourId,
        label: 'Assistant Catalog feature',
        data: {
            steps: [
                {
                    target: '#' + ToursSharedTargets.tourContainer,
                    title: 'Assistant Catalog',
                    content: 'Browse the chat assistants & pin favorite.',
                    placement: 'center',
                    data: {
                        pathname: AppRoute.catalog,
                        hash: '',
                        timeout: 300,
                        onBackPathname: defaultPersonaKey,
                        onBackHash: ObjectTypes.documents + '-' + DEMO_TOUR_SUCCESS_FILE_UPLOAD_KEY
                    }
                },
                {
                    target: '#' + TourCatalogTargets.catalogCta,
                    content: 'Introducing: The Assistant Catalog. Click to find out more!',
                    placement: 'auto',
                    data: {
                        pathname: AppRoute.catalog,
                    },
                },
                {
                    target: '#' + TourCatalogTargets.personaList,
                    content: 'Assistants are now grouped in a catalog for easier navigation. We also call them Personas.',
                    placement: 'auto',
                },
                {
                    target: '#' + TourCatalogTargets.star,
                    content: 'Use the ⭐ to add assistants to your favorites list, for quick access later.',
                    placement: 'auto',
                },
                {
                    target: '#' + TourCatalogTargets.preferenceList,
                    content: 'Assistants you have added to favorites, appear under the My assistant menu here.',
                    placement: 'auto',
                },
                {
                    target: '#' + TourCatalogTargets.search,
                    content: 'You can also use the Search bar to find the assistant you are looking for.',
                    placement: 'auto',
                },
                {
                    target: '#' + TourCatalogTargets.select,
                    content: 'To select an Assistant, simply click on it.',
                    placement: 'auto',
                },
                {
                    target: '#' + TourCatalogTargets.catalogCta,
                    content: 'To open up the Assistant Catalog again, click on it here.',
                    placement: 'auto',
                }
            ] as Step[],
        }
    };
};

export default useTourCatalog;