import { useEffect } from 'react';
import { BrowserCookieInformationService } from '../services/BrowserCookieInformationService';
import { useLayout } from '../contexts/layout/LayoutContext';

export default function useAnalytics() {
    const { setHasCookieConsent } = useLayout();

    // const onFunctionalConsentGiven = useCallback(() => {
    //     DynatraceService.initialize();
    // }, []);
    // const onFunctionalConsentRemoved = useCallback(() => {
    //     console.warn('Consent not given for cookies.');
    // }, []);


    useEffect(() => {
        const h = () => setHasCookieConsent(true);

        BrowserCookieInformationService.initialize(async () => 'en', {
            onStatisticalConsentGiven: h,
            onMarketingConsentGiven: h,
            onFunctionalConsentGiven: h,
            onFunctionalConsentRemoved: h
        });
    }, [setHasCookieConsent]);
}