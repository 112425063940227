// Helpers 
const dateFormatOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
const dateMonthInWordsFormatOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
const dateTimeFormatOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
/**Format date to string for specified  culture 
 * @param date ISO 8601 data string (2021-03-16T06:31:10+00:00) or Epoch date (Ex: Date.now()) or Date object
*/
const formatDateObject = (dateObj: Date, monthInWords: boolean, culture: string, includeTime: boolean) => {
    if (monthInWords) return dateObj.toLocaleDateString(culture, includeTime ? dateTimeFormatOptions : dateMonthInWordsFormatOptions);
    return dateObj.toLocaleDateString(culture, includeTime ? dateTimeFormatOptions : dateFormatOptions);
};

export const formatDate = (date: string | number | Date, culture = 'en', includeTime = false, monthInWords = false): string => {
    if (!date) return "";
    try {
        const dateObj = date instanceof Date ? date : new Date(date);

        return formatDateObject(dateObj, monthInWords, culture, includeTime);
    }
    catch (e) { console.error(e); }
    return "";
};

export const parseDate = (isoDateString: string, defaultValue = new Date(1)): Date => {
    if (!isoDateString) return defaultValue;
    try {
        const dateObj = new Date(isoDateString);
        if (isNaN(dateObj.getHours())) return defaultValue;
        return dateObj;
    }
    catch { return defaultValue; }
};

export const isoDate = (date: Date = new Date(), useUTC = false) => {
    if (useUTC) {
        date = new Date(new Date().toUTCString());
    }

    return date.toISOString();
};

export const getWeekDays = (locale: string) => {
    const baseDate = new Date(Date.UTC(2017, 0, 2)); // just a Monday
    const weekDays = [];

    for (let i = 0; i < 7; i++) {
        weekDays.push({ day: baseDate.toLocaleDateString(locale, { weekday: 'long' }), index: i + 1 === 7 ? 0 : i + 1 });
        baseDate.setDate(baseDate.getDate() + 1);
    }

    return weekDays;
};

export enum Days {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday
}


export const getTodayIndex = () => {
    return new Date().getDay();
};

/** Expects time string in following format: `hh:mm` and returns Date */
export const parseTimeStringToDate = (time: string) => {
    const date = new Date();
    const hh = parseInt(time.split?.(':')?.[0]);
    const mm = parseInt(time.split?.(':')?.[1]);

    date.setHours(hh);
    date.setMinutes(mm);
    date.setMilliseconds(0);

    return date;
};

export enum InTimeRangeEnum {
    belowRange,
    aboveRange,
    inRange,
    error
}

/** Time range for hours and minutes on a day. Not usable for date ranges!  */
export const isInTimeRange = (secondsFrom: Date, secondsTo: Date, now: Date = new Date()): InTimeRangeEnum => {
    const currentTimeSeconds = getSecondsFromDateTime(now);
    const sf = getSecondsFromDateTime(secondsFrom);
    const st = getSecondsFromDateTime(secondsTo);

    if (sf === undefined || st === undefined || currentTimeSeconds === undefined) return InTimeRangeEnum.error;
    if (sf > currentTimeSeconds) return InTimeRangeEnum.belowRange;
    else if (st < currentTimeSeconds) return InTimeRangeEnum.aboveRange;
    else return InTimeRangeEnum.inRange;
};

export const getSecondsFromDateTime = (date: Date = new Date()): number | undefined => {
    if (!date) return undefined;
    const h = date.getHours() * 3600;
    const m = date.getMinutes() * 60;
    return h + m;
};

export const dateStringToTimestamp = (string?: string) => string ? new Date(string).getTime() : NaN;


export const formatDuration = (seconds: number, includeSeconds = false) => {
    return [
        Math.floor(seconds / 60 / 60),
        Math.floor(seconds / 60 % 60),
        includeSeconds ? Math.floor(seconds % 60) : -1
    ].filter(x => x > -1).join(":").replace(/\b(\d)\b/g, "0$1");
};


const adjustDays = (date: Date, days: number) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - days);
    return newDate;
};

export const deductDays = (date: Date, days: number = 1) => adjustDays(date, days);
export const addDays = (date: Date, days: number = 1) => adjustDays(date, -days);
export const isToday = (day: string, todayIndex: number) => getDayIndex(day) === todayIndex;
export const getDayIndex = (day: string) => parseInt(Days[day as any as Days]);

export const processFromToTime = (from: number, to: number) => `${processTime(from)}:${processTime(to)}`;
export const processTime = (time: number) => time > 9 ? time : `0${time}`;


export enum DateGroup {
    Today = 'today',
    Yesterday = 'yesterday',
    Within7Days = 'withing-7-days',
    Within30Days = 'within-30-days',
    January = 'january',
    February = 'february',
    March = 'march',
    April = 'april',
    May = 'may',
    June = 'june',
    July = 'july',
    August = 'august',
    September = 'september',
    October = 'october',
    November = 'november',
    December = 'december',
}
export function findDateGroup(date: string | Date): DateGroup {
    const currentDate = new Date();
    const inputDate = new Date(date);

    // Check if the date is today
    if (
        inputDate.getDate() === currentDate.getDate() &&
        inputDate.getMonth() === currentDate.getMonth() &&
        inputDate.getFullYear() === currentDate.getFullYear()
    ) {
        return DateGroup.Today;
    }

    // Check if the date is yesterday
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    if (
        inputDate.getDate() === yesterday.getDate() &&
        inputDate.getMonth() === yesterday.getMonth() &&
        inputDate.getFullYear() === yesterday.getFullYear()
    ) {
        return DateGroup.Yesterday;
    }

    // Check if the date is within the previous 7 days
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 7);
    if (inputDate >= sevenDaysAgo) {
        return DateGroup.Within7Days;
    }

    // Check if the date is within the previous 30 days
    const thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);
    if (inputDate >= thirtyDaysAgo) {
        return DateGroup.Within30Days;
    }

    // Get the month name
    const monthNames = [
        DateGroup.January, DateGroup.February, DateGroup.March,
        DateGroup.April, DateGroup.May, DateGroup.June,
        DateGroup.July, DateGroup.August, DateGroup.September,
        DateGroup.October, DateGroup.November, DateGroup.December,
    ];
    const monthName = monthNames[inputDate.getMonth()];

    return monthName;
}