import * as svgIcons from "../icons/svg";
import { useMemo } from "react";
import { SvgIconProps } from "../Icon.types";
import { getClassNames } from "../../../helpers/classHelpers";
import styles from "../Icon.module.scss";

const Svg = ({ testId, isClickable, className, iconName, size = 24, borderColor = 'blue', borderSize = 0, theme, fill = 'red', style, onClick, ...rest }: SvgIconProps) => {
    const SVGIcon = useMemo(() => svgIcons[iconName], [iconName]);
    if (!SVGIcon) return null;
    const clickable = ((onClick || isClickable));

    return <SVGIcon
        title={rest.title}
        fill="currentColor"
        style={style}
        tabIndex={(onClick || isClickable) ? 0 : undefined}
        onClick={onClick}
        className={
            getClassNames([
                className,
                styles.icon,
                styles[fill],
                clickable && styles.clickable,
                size && styles[`size-${size}`],
                fill && styles[`fill-${fill}`],
                theme && styles[`theme-${theme}`],
                !!borderSize && styles[`border-size-${borderSize}`],
                borderColor && styles[`border-color-${borderColor}`]
            ])
        }
    />;
};

export default Svg;