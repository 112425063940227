import { DependencyList, useRef, useCallback } from 'react';
import useRefLive from './useRefLive';
import useDepsShallowComparer from './useDepsShallowComparer';

export default function useCachedPromise<T>(promiseFactory: () => Promise<T>, promiseDeps: DependencyList) {
    const promiseFactoryRef = useRefLive(promiseFactory);
    const promiseRef = useRef<Promise<T>>();

    useDepsShallowComparer(() => {
        promiseRef.current = undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, promiseDeps);

    const call = useCallback(async (force = false) => {
        if (force || !promiseRef.current) {
            promiseRef.current = promiseFactoryRef.current();
        }
        return promiseRef.current;
    }, [promiseFactoryRef]);

    return call;
}
