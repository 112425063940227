import Button, { ButtonThemes } from '../button/Button';
import { useMemo, useState } from 'react';
import styles from './Description.module.scss';

const Description = ({ threshold, description, labels: { seeMore, seeLess } }: { labels: { seeMore?: string, seeLess?: string; }, threshold?: number, description: string; }) => {
    const className = styles.description;
    const [showAll, setShowAll] = useState(false);

    const expandCta = useMemo(() => {
        if (!threshold) return;
        if (description.length < threshold) return;
        return <>&nbsp;<Button className={styles['toggle-button']} label={showAll ? seeLess : seeMore} theme={ButtonThemes.textPrimary} onClick={() => setShowAll(s => !s)} /></>;
    }, [description.length, seeLess, seeMore, showAll, threshold]);

    if ((threshold && description.length < threshold) || showAll) {
        return <div className={className}>
            {description}
            {expandCta}
        </div>;
    }

    return <div className={className}>
        {threshold ? description.slice(0, threshold).trim() + ' ...' : description}
        {expandCta}
    </div>;
};

export default Description;