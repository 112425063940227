export enum Icons {
    spinner = 'progress_activity',
    thumbUp = 'thumb_up',
    thumbDown = 'thumb_down',
    report = 'report',
    copy = 'copy',
    info = 'info',
    check = 'check',
    clear = 'clear',
    schedule = 'schedule',
    menu = 'menu',
    star = 'star',
    starOutline = 'star_border',
    storefront = 'storefront',
    add = 'add',
    remove = 'remove',
    edit = 'edit',
    stop = 'stop',
    delete = 'delete',
    arrowUpward = 'arrow_upward',
    zoomIn = 'zoom_in',
    zoomOut = 'zoom_out',
    zoomOutAll = 'zoom_out_map',
    hideImage = 'hide_image',
    link = 'link',
    dragIndicator = 'drag_indicator',
    dragHandle = 'drag_handle',
    arrowBack = 'arrow_back',
    arrowForward = 'arrow_forward',
    pdf = 'picture_as_pdf',
    download = 'download',
    chevronRight = 'chevron_right',
    chevronDown = 'keyboard_arrow_down',
    chevronUp = 'keyboard_arrow_up',
    keyboardExternalInput = 'keyboard_external_input',
    refresh = 'refresh',
    openInNewWindow = 'open_in_new',
    'new-tab' = 'open_in_new',
    emptyCheckbox = 'check_box_outline_blank',
    checkbox = 'check_box',
    emptyRadio = 'radio_button_unchecked',
    radio = 'radio_button_checked',
    play = 'play_arrow',
    pause = 'pause',
    send = 'send',
    mute = 'volume_off',
    unmute = 'volume_up',
    uploadDocument = 'note_add',
    visibility = 'visibility',
    upload = 'upload',
    appRegistration = 'app_registration',
    hub = 'hub'
}