import Loader from '../../../../../loader/Loader';
import useEffectAsync from '../../../../../../hooks/useEffectAsync';
import useLabels from '../../../../../../hooks/useLabels';
import { useState, useCallback, useRef, ReactNode } from 'react';
import { guid } from '../../../../../../helpers/randomHelpers';
import { TestIds } from '../../../../../../mocks/ids';
import { WHITELISTED_FILE_SOURCES } from '../../../../../../constants/consts';
import styles from './Pdf.module.scss';

export type PdfProps = { url: string; pageNumber?: number; };

export const Pdf = ({ url, pageNumber }: PdfProps) => {
    const l = useLabels();

    const [urlWithOptions, setUrlWithOptions] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const key = useRef(guid()); // <- Key is needed here just for hot reload scenarios. Updating the key here so the iframe gets reloaded

    useEffectAsync(token => () => {
        setIsLoading(true);
        setTimeout(() => {
            if (token.cancelled) return;

            try {
                let viewUrl = url.replace(/.pdf#/i, ".pdf?");
                const pdfUrl = new URL(viewUrl);

                const params = {
                    "view": "Fit",
                    "toolbar": "0",
                    "navpanes": "0",
                    "page": pageNumber?.toString() || pdfUrl.searchParams.get("page") || "1"
                };
                Object.entries(params).forEach(([k, v]) => pdfUrl.searchParams.append(k, v));
                viewUrl = pdfUrl.toString().replace(".pdf?", ".pdf#");
                setUrlWithOptions(viewUrl);
            }
            catch {
                // URL constructor throws an error if the URL is invalid 
                setUrlWithOptions("about:blank");
                setIsLoading(false);
                console.error('Invalid URL');
            }
        }, 500); // Give time to sidebar animation to finish

    }, [url]);

    const isLoadingFlaggerRef = useRef<NodeJS.Timeout>();
    const handleOnLoad = useCallback(() => {
        clearTimeout(isLoadingFlaggerRef.current);
        isLoadingFlaggerRef.current = setTimeout(() => { setIsLoading(false); }, 500);
    }, []);

    const hasUrl = urlWithOptions !== "about:blank";
    const wrapWithContainer = useCallback((children: ReactNode) => {
        return <div style={{ height: '100%' }} data-testid={TestIds.pdfContainer} className={styles['pdf']}>{children}</div>;
    }, []);

    const somethingWentWrong = useCallback((label = l.pdfCannotBeDisplayed) => <p data-testid={TestIds.pdfError} className={styles.error}>{label}</p>, [l]);
    if (!isLoading && !hasUrl) return wrapWithContainer(somethingWentWrong(l.pdfIncorrectLink));
    if (!isLoading && !(url.includes('.pdf') || !urlWithOptions.startsWith('https://') || !WHITELISTED_FILE_SOURCES.some(domain => urlWithOptions.includes(domain)))) return wrapWithContainer(somethingWentWrong());

    return wrapWithContainer(<>
        {urlWithOptions && <iframe data-testid={TestIds.pdfViewer} className={styles['pdf-viewer']} src={urlWithOptions} title={l.pdfViewer} onLoad={handleOnLoad} key={key.current} />}
        {isLoading && <div data-testid={TestIds.pdfViewerLoader} className={styles['loader-wrapper']}><Loader /></div>}
    </>);
};

export default Pdf;
