import { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { useRef, useState } from 'react';

export type TourCallbackProps = CallBackProps;
export type TourCallbackState = (state: TourCallbackProps) => Promise<void>;
export type ClickHandlerProps = {
    onStart?: TourCallbackState;
    onClose?: TourCallbackState;
    onFinish?: TourCallbackState;
    onNext?: TourCallbackState;
    onPrev?: TourCallbackState;
    onSkipped?: TourCallbackState;
};

const useTourState = () => {
    const reachedLastStepRef = useRef(false);
    const [run, setRun] = useState(true);
    const [stepIndex, setStepIndex] = useState(0);

    const onClickHandler = ({ onStart, onFinish, onClose, onNext, onPrev, onSkipped }: ClickHandlerProps) => async (joyride: CallBackProps) => {
        const { action, index, status, type, size } = joyride || {};
        const isStarting = type === EVENTS.TOUR_START && action === ACTIONS.START;
        const isSkippedStatus = status === STATUS.SKIPPED;
        const isCloseAction = action === ACTIONS.CLOSE;
        const isPrevAction = action === ACTIONS.PREV;
        const isNextAction = action === ACTIONS.NEXT;
        const isStepAfterEvent = type === EVENTS.STEP_AFTER;
        const isLastStep = stepIndex + 1 === size;
        reachedLastStepRef.current = isLastStep;

        if (isStarting) {
            await onStart?.(joyride);
            setStepIndex(0);
        }

        // Back
        else if (isPrevAction && isStepAfterEvent) {
            await onPrev?.(joyride);
            setStepIndex(index - 1);
        }

        // Next
        else if (isNextAction && isStepAfterEvent && !isLastStep) {
            await onNext?.(joyride);
            setStepIndex(index + 1);
        }

        // Skip
        else if (isSkippedStatus) {
            await onSkipped?.(joyride);
            setRun(false);
        }

        // Close (by X icon, ESC, or click outside)
        else if (!reachedLastStepRef.current && isCloseAction) {
            await onClose?.(joyride);
            setRun(false);
        }

        // Finishing the tour after seeing the last step
        else if ((reachedLastStepRef.current && isCloseAction) || (isNextAction && isStepAfterEvent && isLastStep)) {
            await onFinish?.(joyride);
            setRun(false);
        }
    };

    return {
        onClickHandler,
        setRun,
        stepIndex,
        run,
    };
};

export default useTourState

