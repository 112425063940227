import PersonasCatalog from '../../elements/personas-catalog/PersonasCatalog';
import { ChatViewLayoutProps } from '../../layouts/chat-view-layout/ChatViewLayout';
import styles from './CatalogView.module.scss';

const CatalogView = (props: ChatViewLayoutProps) => {
    return <div className={styles['catalog-view']}>
        <PersonasCatalog {...props} />
    </div>;
};

export default CatalogView;