import { useMemo } from 'react';
import useRefLive from './useRefLive';


export function useStateTimestamp<T>(state: T) {
    // eslint-disable-next-line 
    const timestamp = useMemo(() => new Date(), [state]);
    return timestamp;
}

export function useStateTimestampRef<T>(state: T) {
    const timestamp = useStateTimestamp(state);
    const timestampRef = useRefLive(timestamp);
    return timestampRef;
}
