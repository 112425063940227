import Button, { ButtonThemes } from '../../../ui/button/Button';
import parse from 'html-react-parser';
import { forwardRef } from 'react';
import { TooltipRenderProps } from 'react-joyride';
import { getClassNames } from '../../../helpers/classHelpers';
import styles from './Tooltip.module.scss';

const Tooltip = forwardRef<HTMLButtonElement, TooltipRenderProps>((props, ref) => {
    const { size, backProps, continuous, index, primaryProps, skipProps, step, isLastStep, tooltipProps } = props;

    return (
        // Since this component is used by portal that doesn't allow to add external class names we need to add __.df-mosaic__ to get it's styles
        <div className={getClassNames([styles['tooltip-container'], 'df-mosaic'])} {...tooltipProps}>
            {step.title && <div className={styles.header}>
                <h5 className="">{step.title}</h5>
                {/* {!step.hideCloseButton && <Icon.Base iconName={Icons.clear} title={closeProps.title || closeProps['aria-label']} onClick={(e) => closeProps.onClick(e as any as React.MouseEvent<HTMLElement>)} />} */}
            </div>}

            {step.content && <div className={styles.content}>
                {typeof step.content === 'string' ? parse(step.content) : step.content}
            </div>}

            {<div className={styles.navigation}>
                {continuous && (index + 1) !== size ?
                    <Button
                        isSmall
                        label={skipProps.title}
                        title={skipProps.title || skipProps['aria-label']}
                        theme={ButtonThemes.textSecondary}
                        onClick={skipProps.onClick}
                    />
                    : <div />}

                {<div className={styles['secondary-navigation']}>
                    {index > 0 && <Button
                        isSmall
                        label={backProps.title}
                        title={backProps.title || backProps['aria-label']}
                        theme={ButtonThemes.secondary}
                        onClick={backProps.onClick}
                    />}
                    {continuous && <Button
                        isSmall
                        label={isLastStep ? 'Finish' : primaryProps.title}
                        title={primaryProps.title || primaryProps['aria-label']}
                        theme={ButtonThemes.secondary}
                        onClick={primaryProps.onClick}
                    />}
                </div>}
            </div>}
        </div>
    );
});


export default Tooltip;