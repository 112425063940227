import Pagination from '../ui/pagination/Pagination';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { chunkBy } from '../helpers/listsHelpers';

const usePagination = <T,>({ total, data = [], pageSize = 10, onBack, onNext }: { total?: number, data?: T[], pageSize?: number; useBePagination?: boolean, onNext?: (page: number) => Promise<void>, onBack?: (page: number) => Promise<void>; }) => {
    const [pageIndex, setPageIndex] = useState(1);

    useEffect(() => { setPageIndex(1); }, [data]);

    const totalPageCount = useMemo(() => Math.ceil((total ?? data.length) / pageSize), [total, data, pageSize]);

    const chunkedPageData = useMemo(() => chunkBy(data, pageSize) ?? [], [data, pageSize]);

    const isShowingPagination = useMemo(() => totalPageCount > 1, [totalPageCount]);

    const currentPageChunk = useMemo(() => {
        return chunkedPageData?.[pageIndex - 1] ?? chunkedPageData?.[0] ?? [];
    }, [pageIndex, chunkedPageData]);

    const renderPagination = useCallback((className?: string) => {
        if (!isShowingPagination) return null;

        return <Pagination
            hrefNext=''
            hrefPrev=''
            className={className}
            totalCount={totalPageCount}
            pageIndex={pageIndex}
            onNext={async ({ e }) => {
                e.preventDefault();
                setPageIndex(pageIndex => {
                    const isExceedingBounds = pageIndex + 1 > totalPageCount;
                    const value = (isExceedingBounds) ? totalPageCount : pageIndex + 1;
                    if (!isExceedingBounds) onNext?.(value);
                    return value;
                });

            }}
            onBack={async ({ e }) => {
                e.preventDefault();
                setPageIndex(pageIndex => {
                    const isExceedingBounds = pageIndex - 1 < 1;
                    const value = (isExceedingBounds) ? 1 : pageIndex - 1;
                    if (!isExceedingBounds) onBack?.(value);
                    return value;
                });
            }}

        />;
    }, [isShowingPagination, totalPageCount, pageIndex, onNext, onBack]);

    return {
        isShowingPagination,
        pageIndex,
        currentPageChunk,
        setPageIndex,
        renderPagination,
    };

};

export default usePagination;
