import { type PropsWithChildren, useContext, useEffect, useMemo, } from 'react';
import { AuthContext } from '../auth/AuthContext';
import AppInfoProvider from './AppInfoContext';
import AppInfoService from '../../services/AppInfoService';
import { useBackendEvents } from '../backend-events/BackendEventsContext';

const AppInfoServiceProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { getAccessToken } = useContext(AuthContext);
    const backendEvents = useBackendEvents();

    const service = useMemo(() => new AppInfoService(getAccessToken, backendEvents), [getAccessToken, backendEvents]);

    useEffect(() => {
        service.bindEvents();
        return () => service.unbindEvents();
    }, [service]);

    return <AppInfoProvider service={service}>
        {children}
    </AppInfoProvider>;
};

export default AppInfoServiceProvider;