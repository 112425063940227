export type JwtPayload = {
    sub?: string; // Subject (usually user ID)
    iat?: number; // Issued At (timestamp)
    exp?: number; // Expiration time (timestamp)
    nbf?: number; // Not Before (timestamp)
    [key: string]: any; // Allow additional properties
};

export const parseJwt = (token: string): JwtPayload | null => {
    if (typeof token !== 'string') {
        console.error('Invalid token: must be a string');
        return null;
    }

    const parts = token.split('.');

    // Ensure the token has three parts
    if (parts.length !== 3) {
        console.error('Invalid token: must have three parts separated by dots');
        return null;
    }

    try {
        const payloadBase64 = parts[1];

        // Validate Base64 encoding
        if (!/^[A-Za-z0-9-_]+$/.test(payloadBase64)) {
            console.error('Invalid token: payload is not properly base64url-encoded');
            return null;
        }

        // Decode Base64URL to Base64
        const base64 = payloadBase64.replace(/-/g, '+').replace(/_/g, '/');
        const decodedPayload = atob(base64);

        // Parse the JSON payload
        const parsedPayload = JSON.parse(decodedPayload);

        // Ensure the payload is an object
        if (typeof parsedPayload !== 'object' || parsedPayload === null) {
            console.error('Invalid token: payload is not a valid JSON object');
            return null;
        }

        return parsedPayload as JwtPayload;
    } catch (e) {
        console.error('Error parsing token:', e);
        return null;
    }
};

export const isJwtExpired = (token: string, gracePeriodSeconds: number = 60): boolean => {
    const payload = parseJwt(token);

    if (!payload) {
        console.error('Invalid token: Unable to parse');
        return true; // Treat invalid tokens as expired
    }

    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const expirationTime = payload.exp;

    if (typeof expirationTime !== 'number') {
        console.error('Invalid token: Missing or invalid "exp" claim');
        return true; // Treat tokens without a valid expiration time as expired
    }

    return currentTime > (expirationTime - gracePeriodSeconds);
};
