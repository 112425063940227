import { FC, useMemo } from "react";
import { NotificationsType, useErrorHandlerContext } from "../ErrorContext";
import { RequiredIdentifierType } from "../types";
import { NotificationCompound } from './NotificationCompound';
import { AlertProps } from '../../../ui/alert/Alert';
import { formatDetails } from '../helpers';
import style from "../ErrorHandler.module.scss";

export type NotificationsCompoundProps = RequiredIdentifierType & { alertOverride?: AlertProps; };
export const NotificationsCompound: FC<NotificationsCompoundProps> = ({ alertOverride, id, className, isStandalone }) => {
    const { getError } = useErrorHandlerContext();
    const error = getError(id);

    const isValid = useMemo(() => {
        if (isStandalone) {
            return false;
        }
        else if (error?.type !== 'notifications' || !error) {
            return true;
        }
    }, [isStandalone, error]);

    if (isValid) return null;
    const e = error as NotificationsType;

    return <div className={style.notifications}>
        {e?.notifications?.map((notification, index) => <NotificationCompound
            key={index}
            className={className}
            isStandalone
            alertOverride={{ headline: notification.headline, description: notification.description, details: formatDetails(notification.details), ...alertOverride }}
        />)}
    </div>;
};
