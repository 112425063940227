import useInfo from './useInfo';
import useLabels from './useLabels';
import { useMemo } from 'react';
import { useAuth } from '../contexts/auth/AuthContext';
import { defaultPersonaIcon } from '../constants/consts';
import { getAvatarHuman } from '../helpers/avatarHelpers';
import { ChatMessageResponse } from '../models/ChatTypes';

const wrapVersion = (version: string | undefined) => version ? `(v${version})` : undefined;

export default function useMessageProps(type: ChatMessageResponse['type'], currentChatLLM?: string, currentChatPersona?: string) {
    const { user } = useAuth();
    const labels = useLabels();
    const { getLlmLabelFromKey, getPersonaFromKey } = useInfo();

    const props = useMemo(() => {

        const isGpt = type === 'ai';

        let isBeta = false;
        let senderName = "";
        let senderExtraName = "";
        let senderAvatar = "";

        if (isGpt) {
            senderName = labels.advancedAssistantTitle;
            senderAvatar = defaultPersonaIcon;

            if (currentChatPersona) {
                const persona = getPersonaFromKey?.(currentChatPersona || '');
                isBeta = persona?.beta ?? false;
                senderName = persona?.title ?? currentChatPersona;
                senderExtraName = wrapVersion(persona?.version) ?? "";
                senderAvatar = persona?.avatar ?? senderAvatar;
            }
            else if (currentChatLLM) {
                senderExtraName = getLlmLabelFromKey(currentChatLLM);
            }
        }
        else {
            senderAvatar = getAvatarHuman(user);
            senderName = user?.name || user?.username || '';
        }

        return {
            isGpt,
            senderAvatar,
            senderName,
            isBeta,
            senderExtraName,
        };
    }, [type, labels.advancedAssistantTitle, currentChatPersona, currentChatLLM, getPersonaFromKey, getLlmLabelFromKey, user]);

    return props;
}