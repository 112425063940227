import React, { type PropsWithChildren, createContext, useContext } from 'react';
import { IBackendEventsService } from '../../services/IBackendEventsService';


const defaultBackendService: IBackendEventsService = {
    on: function () {
        throw new Error('Function not implemented.');
    },
    off: function () {
        throw new Error('Function not implemented.');
    }
};

export const BackendEventContext = createContext<IBackendEventsService>(defaultBackendService);

const BackendEventProvider: React.FC<PropsWithChildren<{ service: IBackendEventsService; }>> = ({ children, service }) => {
    return <BackendEventContext.Provider value={service}>
        {children}
    </BackendEventContext.Provider>;
};

export const useBackendEvents = () => useContext(BackendEventContext);

export default BackendEventProvider;