
import Chip from '../chip/Chip';
import MarkdownWrapper from '../markdown-wrapper/MarkdownWrapper';
import { Icon } from '../icon/Icon';
import { Image } from '../image/Image';
import { HeroData } from '../../models/SidePanelTypes';
import { IconTheme } from '../icon/Icon.types';
import { useState } from 'react';
import { Icons } from '../icon/icons/material';
import styles from './CustomHero.module.scss';

type Props = HeroData['properties'];

const CustomHero: React.FC<Props> = ({ title, images, subtitle, text, tags, code, externalLink }: Props) => {
    return (
        <div className={styles['custom-hero']}>
            <Images title={title} images={images} />
            <div className={styles['info-container']}>
                <Title title={title} />
                <Code code={code} title={title} />
                <Subtitle subtitle={subtitle} />
                {text && subtitle && <br />}
                <Text text={text} />
                <ExternalLinks externalLink={externalLink} />
                <Tags tags={tags} />
            </div>
        </div >
    );

};

export default CustomHero;



const Text = ({ text }: { text: HeroData['properties']['text']; }) => {
    return <>{text && <MarkdownWrapper markdown={text} />}</>;
};
const Subtitle = ({ subtitle }: { subtitle: HeroData['properties']['subtitle']; }) => {
    return <>{subtitle && <b>{subtitle}</b>}</>;
};

const Code = ({ code, title }: { code: HeroData['properties']['code'], title: HeroData['properties']['title']; }) => {
    return <> {code !== title && code && <Chip className={styles.code} label={code} />}</>;
};

const Title = ({ title }: { title: HeroData['properties']['title']; }) => {
    return <>{title && <h5 className={styles.title}>{title}</h5>}</>;
};

const Tags = ({ tags }: { tags: HeroData['properties']['tags']; }) => <>
    {!!tags?.length && <div className={styles['tags']}>
        {tags.map((tag, index) => <Chip isActive key={index} label={tag} />)}
    </div>}
</>;

const ExternalLinks = ({ externalLink }: { externalLink: HeroData['properties']['externalLink']; }) => {
    const map: Record<string, Icons> = {
        'pdf': Icons.pdf,
        'download': Icons.download,
        'new-tab': Icons.openInNewWindow,
    };
    
    return <div className={styles.links}>
        {externalLink?.map(({ url, icon, text }, i) => {
            const iconName = map?.[icon as keyof typeof Icons];
            return <a key={i} className={styles.link} href={url} target="_blank" rel="noreferrer noopener">
                {iconName && <Icon.Base className={styles.icon} iconName={iconName} title={text ?? ''} theme={IconTheme.inherit} />}
                <span className={styles.text}>{text}</span>
            </a>;
        })}
    </div>;
};

const Images = ({ title, images }: { title: HeroData['properties']['title'], images: HeroData['properties']['images']; }) => {
    const [image, setImage] = useState<string>();

    const imgMap = !images ? [] : images.map((src) => ({
        height: 200,
        with: 240,
        src,
        alt: title,
        showLoadingWhenFetching: true
    }));

    if (!imgMap.length) return null;
    return <>
        <Image.Custom className={styles.image} images={imgMap} enableThumbs onExpand={setImage} />
        <Image.Modal targetImage={image} images={imgMap.map(x => ({ src: x.src }))} onClose={() => setImage(undefined)} />
    </>;
};