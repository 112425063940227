import { useCallback, useEffect, useMemo, useState } from 'react';
import { advancedAssistantKey, advancedAssistantRoute, defaultPersonaKey } from '../../constants/consts';
import { UserPreferences } from '../../models/types';
import { isString } from '../../helpers/typeHelpers';
import { useChat } from '../chat/ChatContext';
import { useAppInfo } from '../app-info/AppInfoContext';
import useInfo from '../../hooks/useInfo';
import { PreferencesEvent, PreferencesEventTypes } from '../../models/BackendEvents';
import { useStateTimestampRef } from '../../hooks/useStateTimestamp';

export const ALWAYS_PINED_PERSONAS: string[] = [];
export const INITIALLY_PINED_PERSONAS: string[] = [defaultPersonaKey];

const useCatalogFavorites = () => {
    const { userId, preferences, events: { preferences: preferencesEvents } } = useAppInfo();
    const { allowedPersonaOptions } = useInfo();
    const { setUserPreferences } = useChat();
    const [preferencesState, setState] = useState<UserPreferences>({ personas_pinned: preferences?.personas_pinned ?? [], personas_disable_auto_pin: preferences?.personas_disable_auto_pin ?? [] });
    const stateTimestamp = useStateTimestampRef(preferencesState);

    useEffect(() => {
        const handler = (event: PreferencesEvent) => {
            const eventTimestamp = new Date(event.timestamp);
            if (event.user_id !== userId)
                // The backend only pushes update event for user's own preferences, but we add this check as a safety measure. 
                return;

            if (event.type !== PreferencesEventTypes.updated)
                return;

            /**
             * Understanding the timestamp check below
             * We update the local state as soon as we get the response from the server,
             * and the server event is fired afterwards. We keep a timestamp after updating
             * the local state, and if the server timestamp is less than the local timestamp,
             * then we can skip the event. 
             * This also helps in case there is a latency in the server event and the user 
             * updates the local state quickly, then we're sure the server event will not 
             * override the local (newer) state 
             */

            if (eventTimestamp < stateTimestamp.current)
                return;

            setState(event.metadata);
        };
        preferencesEvents.on(handler);
        return () => {
            preferencesEvents.off(handler);
        };
    }, [preferencesEvents, stateTimestamp, userId]);

    const state = useMemo(() => {
        let personas_pinned = preferencesState.personas_pinned ?? [];
        if (personas_pinned) {
            const allowedPersonaKeys = allowedPersonaOptions.map(x => x.key);
            personas_pinned = personas_pinned.filter(x => allowedPersonaKeys.includes(x));
        }

        if (!personas_pinned?.length) {
            personas_pinned = [...INITIALLY_PINED_PERSONAS];
        }
        return { ...preferencesState, personas_pinned };
    }, [preferencesState, allowedPersonaOptions]);

    const processPreventPersonasPinningOnChatCreation = useCallback((prev: UserPreferences, persona: string) => {
        return prev?.personas_disable_auto_pin?.includes(persona) ? prev?.personas_disable_auto_pin : [...(prev?.personas_disable_auto_pin || []), persona];
    }, []);

    const handlePreventPersonasPinningOnChatCreation = useCallback((persona: string) => {
        setState(prev => ({ ...prev, preventPersonasPinningOnChatCreation: processPreventPersonasPinningOnChatCreation(prev, persona) }));
    }, [processPreventPersonasPinningOnChatCreation]);

    const handleSetFavorite = useCallback(async (persona: string, disableAutoPin = false) => {
        try {
            let newState;
            if (state.personas_pinned?.includes(persona)) {
                newState = await setUserPreferences('remove-items', { personas_pinned: [persona] });
            }
            else {
                const updates = { personas_pinned: [persona] } as Record<string, string[]>;
                if (disableAutoPin) updates.personas_disable_auto_pin = [persona];
                newState = await setUserPreferences('add-items', updates);
            }

            return setState({ ...newState });
        }
        catch (e) {
            console.error(e);
        }
    }, [state, setUserPreferences]);

    const handleSetFavorites = useCallback(async (personas_pinned: string[]) => {
        const newState = await setUserPreferences('replace', { personas_pinned });
        setState({ personas_pinned: newState.personas_pinned, personas_disable_auto_pin: newState.personas_disable_auto_pin });
    }, [setUserPreferences]);

    const handleSetAsFavoriteOnInitialPrompt = (persona: string) => {
        if (!isString(persona) || (state?.personas_pinned?.includes(persona) || state?.personas_disable_auto_pin?.includes(persona))) return;
        handleSetFavorite(persona, true);
    };

    const firstFavorite = useMemo(() => {
        const value = state.personas_pinned?.[0];
        if (value === advancedAssistantKey) return advancedAssistantRoute;
        return value;
    }, [state.personas_pinned]);

    return {
        catalogFavorites: {
            ...state,
            firstFavorite,
            /** Disallow pinning if there is only one persona that is pinned */
            disallowPinning: state.personas_pinned?.length === 1,
            toggleFavorite: handleSetFavorite,
            setFavorites: handleSetFavorites,
            /** This function is relevant for use-case of marking persona as favorite automatically once first prompt is submitted  */
            setAsFavoriteOnInitialPrompt: handleSetAsFavoriteOnInitialPrompt,
            setPersonasDisableAutoPin: handlePreventPersonasPinningOnChatCreation
        }
    };
};

export default useCatalogFavorites;