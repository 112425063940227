import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useInfo from '../../hooks/useInfo';
import ListItemContent from '../../ui/list/ListItemContent';
import useLabels from '../../hooks/useLabels';
import List, { ListItemContentProps } from '../../ui/list/List';
import { fallbackIcon } from '../personas-catalog/PersonasCatalog';
import { ChatInputModel } from '../chat-input';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../router/Routing';
import { Icon } from '../../ui/icon/Icon';
import { Icons } from '../../ui/icon/icons/material';
import { advancedAssistantKey } from '../../constants/consts';
import { useAdaptive } from '../../contexts/adaptive/AdaptiveContext';
import { SortableList } from '../../ui/sortable-list/SortableList';
import { useLayout } from '../../contexts/layout/LayoutContext';
import { getClassNames } from '../../helpers/classHelpers';
import { IconTheme } from '../../ui/icon/Icon.types';
import { ALWAYS_PINED_PERSONAS } from '../../contexts/adaptive/useCatalogFavorites';
import { TourCatalogTargets } from '../../features/tours/hooks/useTourCatalog';
import styles from './PersonaPreferences.module.scss';

type Props = {
    persona?: string;
    newPromptOrigin?: string;
    showActivePersona?: boolean;
    onModelMerge: (sessionId: string, inputModel: Partial<ChatInputModel>) => void;
};

// Needed because DragDropContext requires a unique id for each item and advanced assistant key is empty string thus library assumes it's not provided.
const advancedAssistantMockId = 'advanced-assistant-mock';

const PersonaPreferences: React.FC<Props> = ({ persona, newPromptOrigin, showActivePersona, onModelMerge }) => {
    const labels = useLabels();
    const navigate = useNavigate();
    const { getPersonaFromKey } = useInfo();
    const { hideLeftSidebar } = useLayout();
    const { catalogFavorites } = useAdaptive();

    const [order, setOrder] = useState<string[]>(catalogFavorites?.personas_pinned ?? []);

    // Set order on new value addition
    useEffect(() => {
        setOrder(catalogFavorites?.personas_pinned);
    }, [catalogFavorites]);

    const personas = useMemo(() => {
        return order?.map((item, index) => {
            const persona = getPersonaFromKey(item)!;
            return {
                ...persona,
                id: item,
                icon: persona.avatar ?? fallbackIcon,
                order: index,
                onClick: () => {
                    navigate(persona.route ? "/" + persona.route : AppRoute.chat);
                    hideLeftSidebar();
                }
            };
        });
    }, [hideLeftSidebar, navigate, order, getPersonaFromKey]);

    const handleRemoveFromFavorites = useCallback(async (persona: { key: string; }) => {
        setOrder(s => {
            const ns = [...s];
            const index = ns.indexOf(persona.key);
            if (index > -1) ns.splice(index, 1);
            return ns;
        });
        await catalogFavorites.toggleFavorite(persona.key);
    }, [catalogFavorites]);

    const handleSortOrderChange = useCallback((newSortOrder: string[]) => {
        setOrder(newSortOrder);
        catalogFavorites.setFavorites(newSortOrder);
    }, [catalogFavorites]);

    const listItems = useMemo((): ListItemContentProps[] => {
        const personaKey = newPromptOrigin ?? persona;
        return personas.map((persona) => ({
            ...persona,
            className: showActivePersona && [persona.key, persona.route].includes(personaKey) ? styles['selected'] : '',
            hoverIconClassName: getClassNames([(ALWAYS_PINED_PERSONAS.includes(persona.key) || catalogFavorites.disallowPinning) && styles.hidden, styles['pinning-icon']]),
            hoverIcon: <Icon.Async
                title=''
                iconName={Icons.star}
                theme={IconTheme.accent}
                className={getClassNames([styles['chat-icon']])}
                onAsyncClick={async () => handleRemoveFromFavorites(persona)}
            />,
            onHoverIconClick: () => handleRemoveFromFavorites(persona)
        }));
    }, [catalogFavorites.disallowPinning, handleRemoveFromFavorites, newPromptOrigin, persona, personas, showActivePersona]);

    return (
        <div className={styles['persona-preferences']}>
            <div className={styles["list"]}>
                <ul id={TourCatalogTargets.preferenceList}>
                    <SortableList
                        items={listItems}
                        getItemId={(item) => item.id || advancedAssistantMockId}
                        renderItem={({ ref, item, isActive, isDragged, props, handleProps }) => {
                            return (
                                <>
                                    <ListItemContent
                                        ref={ref}
                                        item={item}
                                        props={props}
                                        className={getClassNames([styles["list-item"], styles.none, isActive && styles.isActive, isDragged && styles.isDragged])}
                                        children={<div className={getClassNames([styles.handle, catalogFavorites?.disallowPinning && styles.hidden])}{...handleProps}>
                                            <Icon.Base title={''} className={styles.icon} iconName={Icons.dragIndicator} isClickable />
                                        </div>}
                                    />
                                </>
                            );
                        }}
                        onSort={(oldIndex, newIndex) => {
                            const newItems = listItems.slice();
                            newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);
                            handleSortOrderChange(newItems.map(({ id }) => {
                                if (id === advancedAssistantMockId) return advancedAssistantKey;
                                return id;
                            }));
                        }}
                    />
                </ul>

                <div className={styles['open-catalog']}>
                    <List listItems={[
                        {
                            id: TourCatalogTargets.catalogCta,
                            title: labels.personasCatalogExplore,
                            icon: Icons.appRegistration,
                            onClick: () => {
                                navigate(AppRoute.catalog);
                                hideLeftSidebar();
                            }
                        }
                    ]} />
                </div>

            </div>
        </div >

    );
};

export default PersonaPreferences;