import { UserPreferences } from './types';

export const SessionEventTypesPrefixes = {
    document: "document.",
    workflow: "workflow."
};

export enum SessionEventTypes {
    created = "created",
    updated = "updated",
    deleted = "deleted",

    documentAdded = "document.added",
    documentRemoved = "document.removed",

    workflowUpdated = "workflow.updated",
    workflowFailed = "workflow.failed",
    workflowCompleted = "workflow.completed"
}

export enum DocumentEventTypes {
    created = "created",
    updated = "updated",
    deleted = "deleted",
    copied = "copied",
    indexing = "indexing",
    indexed = "indexed",
    failed = "failed",
    processed = "processed",
    processingScanning = "processing.scanning",
    processingEmbedding = "processing.embedding",
}

type BaseWebSocketMessage<T = {}> = {
    event_id: string;
    timestamp: string;
    metadata: T;
};

export type SessionEvent = BaseWebSocketMessage<{ subject?: string, documents?: string[]; state?: string; artifact?: string; error?: string; }> & {
    type: SessionEventTypes;
    session_id: string;
};

export type DocumentEvent = BaseWebSocketMessage<{}> & {
    type: DocumentEventTypes;
    document_id: string;
};

export enum PreferencesEventTypes {
    updated = "updated"
}
export type PreferencesEvent = BaseWebSocketMessage<UserPreferences> & {
    type: PreferencesEventTypes;
    user_id: string;
};

export type BackendEventTypes = SessionEventTypes | DocumentEventTypes;
export type BackendEvent = SessionEvent | DocumentEvent;