import { useEffect, useRef } from "react";
import useRefLive from "./useRefLive";

type Diff<T> = { [k in keyof Partial<T>]: { prev: Partial<T>[k], curr: Partial<T>[k]; } };
/**Invokes the callback with only updated value and object with difference. Useful for debugging state changes. 
 * Exact equality check applied on first level props
 */
export default function useDepsShallowComparer<T>(callback: (updates: Partial<T>, diff: Diff<T>) => void, deps: T) {
    const depsRef = useRef(deps);
    const callbackRef = useRefLive(callback);
    useEffect(() => {
        const o = depsRef.current;
        const n = depsRef.current = deps;
        const diff = {} as Diff<T>;
        const updates = {} as Partial<T>;
        for (const k in o) {
            const prev = o[k];
            const curr = n[k];
            if (prev === curr) {
                continue;
            }
            updates[k] = curr;
            diff[k] = { prev, curr };
        }

        if (Object.keys(diff).length)
            callbackRef.current(updates, diff);
    }, [deps, callbackRef]);
}