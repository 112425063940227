import React, { FC, memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import PageLayout from '../../layouts/page-layout/PageLayout';
import Sidebar, { type EnrichedListItem } from '../../elements/sidebar/Sidebar';
import SidebarHeader from '../../elements/sidebar-header/SidebarHeader';
import Button, { ButtonProps, ButtonThemes } from '../../ui/button/Button';
import SidebarChildren from '../../elements/sidebar-children/SidebarChildren';
import useDisclaimer from '../../hooks/useDisclaimer';
import useLabels from '../../hooks/useLabels';
import useCachedPromise from '../../hooks/useCachedPromise';
import PersonaPreferences from '../../elements/persona-preferences/PersonaPreferences';
import { Icon } from '../../ui/icon/Icon';
import { IconTheme } from '../../ui/icon/Icon.types';
import { findDateGroup } from '../../helpers/dateHelpers';
import { AppRoute, getChatItemRoute } from '../../router/Routing';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SessionResponse } from '../../models/ChatTypes';
import { useChat } from '../../contexts/chat/ChatContext';
import { getClassNames } from '../../helpers/classHelpers';
import { useMediaSize } from '../../hooks/useMediaSize';
import { defaultPersonaIcon, MediaSize, sidePanelElementId } from '../../constants/consts';
import { HistoryBody, HistoryFooter, useHistoryDeletionModal } from '../../elements/history/History';
import { useLayout } from '../../contexts/layout/LayoutContext';
import { TestIds } from '../../mocks/ids';
import { Icons } from '../../ui/icon/icons/material';
import { extractHashProps, getHashRoutePaths } from '../../helpers/hashRouting';
import { useChatSidePanelContent } from '../../ui/markdown-wrapper/hooks/useActions/useActions';
import { useAdaptive } from '../../contexts/adaptive/AdaptiveContext';
import style from './ChatViewLayout.module.scss';
import useInfo from '../../hooks/useInfo';

export const PERSISTENT_INPUT_KEY = 'input-history';

export type ChatViewLayoutProps = {
    newPrompt?: boolean;
    newPromptOrigin?: string;
    currentChat?: SessionResponse;
    chatId?: string;
    onOpenSidebar?: () => void;
    onReloadChats?: (force?: boolean) => Promise<void>;

    children?: (props: Omit<ChatViewLayoutProps, 'children'>) => ReactNode;
};

const ChatViewLayout: FC<ChatViewLayoutProps> = memo(({ children, newPrompt, newPromptOrigin }) => {
    const navigate = useNavigate();
    const { chatId } = useParams();
    const { getPersonaFromKey } = useInfo();
    const { deleteChat, deleteAllChats: deleteChats, persistent: { mergeInput } } = useChat();


    const { isLeftSidebarVisible, isRightSidebarVisible, showLeftSidebar, hideLeftSidebar, hideRightSidebar, showRightSidebar } = useLayout();

    const { chatInfo, chatHistory } = useAdaptive();

    // Initial chat history load
    const getInitialData = useCachedPromise(chatHistory.reload, [chatHistory.reload]);
    useEffect(() => {
        getInitialData();
    }, [getInitialData]);

    const chats = chatHistory.items;

    const handleDeleteChat = useCallback(async (id: string) => {
        const isCurrent = chatId === id;

        try {
            await deleteChat(id);
        }
        catch (e) {
            console.error('Failed to delete chat.', e);
        }
        finally {
            await chatHistory.reload(true);
            if (isCurrent) navigate(AppRoute.chat);
        }
    }, [chatHistory, chatId, deleteChat, navigate]);

    const handleDeleteAllChats = useCallback(async () => {
        if (!chats?.length) return Promise.reject<void[]>() as any;
        try {
            await deleteChats();
        } catch (e) {
            console.error('Failed to delete all chats.', e);
        }
        finally {
            chatHistory.reload(true);
            hideLeftSidebar();
            navigate(AppRoute.chat);
        }
    }, [chats, deleteChats, chatHistory, hideLeftSidebar, navigate]);

    const history = useMemo<EnrichedListItem[]>(() => {
        return chats?.map((chat) => {
            return {
                id: chat.session_id,
                title: chat.subject || 'Missing subject',
                date: chat.created,
                icon: chat.persona ? getPersonaFromKey(chat.persona)?.avatar || defaultPersonaIcon : defaultPersonaIcon,
                dateGroup: findDateGroup(chat.updated),
                url: getChatItemRoute(chat.session_id),
            };
        }) ?? [];
    }, [chats, getPersonaFromKey]);

    const currentChat = useMemo(() => chats?.find(chat => chat.session_id === chatId), [chatId, chats]);

    // Set current persona
    useEffect(() => {
        const chatPersona = currentChat?.persona === null ? '' : currentChat?.persona;
        const newChatPersona = newPromptOrigin;
        chatInfo.setPersona(newChatPersona || chatPersona); // This check is needed because persona can be null or undefined, in case of undefined it is not loaded yet
        return () => { chatInfo.setPersona(undefined); };
    }, [chatInfo, currentChat, newPromptOrigin]);

    const { deleteModalChatId, isDeleteAllModalOpen, setDeleteModalChatId, setIsDeleteAllModalOpen } = useHistoryDeletionModal();

    const props = useMemo(() => ({
        listItems: history,
        deleteModalChatId,
        isDeleteAllModalOpen,
        setDeleteModalChatId,
        setIsDeleteAllModalOpen,
        onDeleteChat: handleDeleteChat,
        onDeleteAllChats: handleDeleteAllChats,
        onCloseSidebar: hideLeftSidebar,
    }), [history, deleteModalChatId, isDeleteAllModalOpen, setDeleteModalChatId, setIsDeleteAllModalOpen, handleDeleteChat, handleDeleteAllChats, hideLeftSidebar]);

    const isMobile = useMediaSize((ms) => ms <= MediaSize.sm);
    const isTablet = useMediaSize((ms) => ms <= MediaSize.lg);


    const { stack: hashStack, up: hashStackUp, clear: hashStackClear } = useHashRouteStack();

    // Relevant to enforce certain CSS classes that are dependent on __isRightSidebarVisible__
    useEffect(() => {
        if (hashStack.length > 0) showRightSidebar();
        else hideRightSidebar();
    }, [hashStack.length, hideRightSidebar, isRightSidebarVisible, showRightSidebar]);

    const handleSideBarOpen = useMemo(() => {
        if (isTablet && isRightSidebarVisible) return hashStackClear;
        if (isMobile) return showLeftSidebar;
        else return undefined;
    }, [hashStackClear, isMobile, isRightSidebarVisible, isTablet, showLeftSidebar]);

    return <PageLayout testId={TestIds.chatViewLayout}>
        <div className={getClassNames([style['chat-view-layout'], isLeftSidebarVisible && style['opened-left'], isRightSidebarVisible && style['opened-right']])}>
            <Sidebar
                isOpen={isLeftSidebarVisible}
                className={style['left-sidebar']}
                onCloseSidebar={hideLeftSidebar}
                content={[
                    {
                        // headerChildren: <SidebarHeader>
                        //     <b className={style['headline']}>{l.catalogHeadline}</b>
                        //     <div className={style['header-icons']}>
                        //         {isMobile && <Icon.Base iconName={Icons.clear} theme={IconTheme.dark} onClick={hideLeftSidebar} title='' />}
                        //     </div>
                        // </SidebarHeader>,
                        scrollChildren: <>
                            <PersonaPreferences
                                showActivePersona={newPrompt}
                                newPromptOrigin={newPromptOrigin}
                                onModelMerge={mergeInput}
                                persona={currentChat?.persona}
                            />
                            {/* <div>
                                <b className={style['chat-history-headline']}>{labels.chatHistory}</b>
                            </div> */}
                            <HistoryBody {...props} />
                        </>
                    }
                ]}
            />

            <div className={style.main}>
                {children?.({ chatId: chatId!, currentChat, onReloadChats: chatHistory.reload, onOpenSidebar: handleSideBarOpen, newPrompt, newPromptOrigin })}
            </div>

            <SidePanelView hashStack={hashStack} {...hashStack} onClose={hashStackUp} />
        </div>

        <Disclaimer />
        <HistoryFooter {...props} />
    </PageLayout>;
});

export default ChatViewLayout;

type HashRouteStackItem = { type: string, id: string; chatId: string; };
type HashRouteStack = HashRouteStackItem[];
const useHashRouteStack = () => {
    const navigate = useNavigate();
    const { hash } = useLocation();
    const { chatId } = useParams();
    const [stack, setStack] = useState<HashRouteStack>([]);

    const hashes = useMemo(() => getHashRoutePaths(hash), [hash]);
    useEffect(() => {
        const newStack: HashRouteStack = [];
        for (const hash of hashes) {
            const { objectType, objectId } = extractHashProps(hash);
            newStack.push({ type: objectType, id: objectId, chatId: chatId! });
        }

        setStack(s => {
            if (JSON.stringify(newStack) === JSON.stringify(s))
                return s;
            return newStack;

        });
    }, [chatId, hashes]);

    const up = useCallback(() => {
        const newHashes = [...hashes];
        newHashes.pop();
        navigate({ hash: newHashes.join("/") });
    }, [hashes, navigate]);

    const clear = useCallback(() => {
        navigate({ hash: '' });
    }, [navigate]);

    return { stack, up, clear };
};

const SidePanelView = memo(({ hashStack, onClose }: { hashStack: HashRouteStack, onClose: () => void; }) => {
    const factory = useChatSidePanelContent();

    const content = useMemo(() => hashStack.map(({ chatId, type, id }, i) => {
        const isRoot = i === 0;
        const { children, header } = factory(chatId, type, id);

        const headerChildren = <SidebarHeader iconName={isRoot ? Icons.clear : Icons.arrowBack} onClose={onClose}>
            {header}
        </SidebarHeader >;

        const scrollChildren = <SidebarChildren className={style.children} > {children}</SidebarChildren >;

        return {
            className: getClassNames([style[type]]),
            headerChildren,
            scrollChildren
        };
    }), [factory, hashStack, onClose]);


    return (
        <Sidebar
            id={sidePanelElementId}
            direction='right'
            className={style['right-sidebar']}
            isOpen={content.length > 0}
            onCloseSidebar={onClose}
            content={content}
        />
    );
});


const Disclaimer = memo(() => {
    const { renderDisclaimer } = useDisclaimer();
    return renderDisclaimer({ className: style.disclaimer });
});

export const NewChatButton = memo(({ onClick }: ButtonProps) => {
    const labels = useLabels();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const l = useMemo(() => {
        return {
            nc: labels.newChat,
        };
    }, [labels.newChat]);

    const isMobile = useMediaSize((ms) => ms <= MediaSize.sm);

    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        onClick?.(e);
        if (pathname !== AppRoute.catalog) navigate(AppRoute.catalog);
    };

    return <Button
        className={style.cta}
        label={l.nc}
        isSmall={isMobile}
        theme={ButtonThemes.secondary}
        onClick={handleOnClick}
        icon={<Icon.Base title='' theme={IconTheme.inherit} iconName={Icons.add} />} // Title not desired since it's used in button
    />;
});