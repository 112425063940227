import { Status } from '../../helpers/statusHelpers';
import styles from './StatusHeader.module.scss';

export const StatusHeader = ({ headline, status }: { headline: string; status?: Status; }) => {
    return <div className={styles['status-header']}>
        {status && <label className="df-status-label" data-status={status} />}
        <b>{headline}</b>
    </div>;
};

export default StatusHeader;