import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { ErrorContext, ErrorStackType } from "./ErrorContext";
import { ModalCompoundProps, ModalCompound } from "./compound/ModalCompound";
import { NotificationCompoundProps, NotificationCompound } from "./compound/NotificationCompound";
import { NotificationsCompound, NotificationsCompoundProps } from './compound/NotificationsCompound';

type ErrorHandlerCompound = {
    /** Pop up */
    Modal: React.FC<ModalCompoundProps>;
    /** Injectable error display component */
    Notification: React.FC<NotificationCompoundProps>;
    /** Multiple - Injectable error display component */
    Notifications: React.FC<NotificationsCompoundProps>;
};

const ErrorHandler: FC<PropsWithChildren> & ErrorHandlerCompound = ({ children }) => {
    const [errorStack, setErrorStack] = useState<ErrorStackType>({});

    const getError = useCallback((errorId?: string) => errorStack?.[errorId ?? ''], [errorStack]);

    const registerError = useCallback((error: ErrorStackType) => setErrorStack((prev) => ({ ...prev, ...error })), []);

    const removeError = useCallback((errorId: string) => setErrorStack((prev) => {
        delete prev[errorId];
        return { ...prev };
    }), []);

    const memoizedContextValue = useMemo(() => ({ errorStack, getError, registerError, removeError }), [errorStack, getError, registerError, removeError]);

    return (
        <ErrorContext.Provider value={memoizedContextValue}>
            {children}
        </ErrorContext.Provider>
    );
};

ErrorHandler.Modal = ModalCompound;
ErrorHandler.Notification = NotificationCompound;
ErrorHandler.Notifications = NotificationsCompound;

export { ErrorHandler };

