import React, { ReactNode, useMemo } from "react";
import { getClassNames } from '../../helpers/classHelpers';
import { generateUniqueId } from '@danfoss/mosaic-core';
import styles from "./Checkbox.module.scss";

const alias = "ui-checkbox";

export type UICheckboxProps = {
    className?: string;
    hidden?: boolean;
    label?: string;
    ariaLabel?: string;
    labelExtra?: ReactNode;
    description?: string;
    legend?: string;
    id?: string;
    isDisabled?: boolean;
    isChecked?: boolean | 'intermediate';
    hideCheckbox?: boolean;
    hasError?: boolean;
    checkboxWrapper?: (checkbox: ReactNode) => ReactNode;
    onChange?: (checked: boolean) => void;
    onBlur?: () => void;
};

/** Component's description */
const UICheckbox: React.FC<UICheckboxProps> = ({
    label,
    ariaLabel,
    labelExtra,
    description,
    legend,
    isDisabled,
    isChecked,
    hideCheckbox,
    hasError,
    onChange,
    onBlur,

    id: customId,
    className,
    hidden
}) => {
    const containerClassNames = getClassNames([className, 'df-checkbox', hasError && styles.error, description && styles['_extra-spacing']]);
    const isIntermediate = isChecked === 'intermediate';
    const inputClassNames = getClassNames([isIntermediate && ['df-checkbox-intermediate'], hideCheckbox && styles['_hidden']]);
    const isCheckedValue = useMemo(() => {
        if (isIntermediate) return true;
        return isChecked ?? false;
    }, [isChecked, isIntermediate]);

    const id = useMemo(() => customId || generateUniqueId(), [customId]);

    if (hidden) return null;
    return (
        <fieldset className={getClassNames([alias, "df-checkbox-group"])}>
            <legend style={{ display: 'none' }}>{legend}</legend>
            <div className={containerClassNames}>
                <input
                    id={id}
                    type="checkbox"
                    className={inputClassNames}
                    checked={isCheckedValue}
                    disabled={isDisabled}
                    aria-label={ariaLabel}
                    aria-invalid={hasError}
                    onChange={({ target }) => onChange?.(target.checked)}
                    onBlur={onBlur}
                />
                {(label || description || labelExtra) && <label className={getClassNames([styles['label-container'], isDisabled && styles['_disabled']])} htmlFor={id}>
                    {label && (
                        <span className={styles['label']}>
                            {label}
                            {labelExtra}
                        </span>
                    )}
                    {description && <span className={styles['description']}>{description}</span>}
                </label>}
            </div>
        </fieldset>
    );
};

export default UICheckbox;
