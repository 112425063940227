import labels from "../../../../../../data/labels";
import DocumentList from "./components/document-list/DocumentList";
import Loader from "../../../../../loader/Loader";
import Information from './components/information/Information';
import useInfo from '../../../../../../hooks/useInfo';
import FileInput from '../../../../../file-input/FileInput';
import { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { useChat } from "../../../../../../contexts/chat/ChatContext";
import { DocumentInfoModel, DocumentRow } from "../../../../../../models/ChatTypes";
import { TestIds } from "../../../../../../mocks/ids";
import { DocumentUploadStatus } from '../../../../../../helpers/statusHelpers';
import { useErrorHandlerContext } from '../../../../../../contexts/error-handler/ErrorContext';
import { getExtension } from '../../../../../../helpers/fileHelpers';
import { ErrorHandler } from '../../../../../../contexts/error-handler/ErrorHandler';
import { AxiosProgressEvent } from 'axios';
import { useAppInfo } from '../../../../../../contexts/app-info/AppInfoContext';
import { useAdaptive } from '../../../../../../contexts/adaptive/AdaptiveContext';
import { DEMO_TOUR_FILE_UPLOAD_KEY, DEMO_TOUR_SUCCESS_FILE_UPLOAD_KEY } from '../../../../../../features/tours/hooks/useTourFileUploads';
import styles from "./Document.module.scss";


export const Documents = ({ chatId, objectId, showInfo = true, reportUploadStateChange }: { chatId: string; objectId?: string; showInfo?: boolean; reportUploadStateChange?: (data?: DocumentRow[]) => void; }) => {
    const { chatInfo } = useAdaptive();
    const { getPersonaFromKey } = useInfo();
    const { documents: globalDocuments } = useAppInfo();
    const { persistent: { getInput, mergeInput }, uploadDocument, fetchDocuments } = useChat();
    const { errorId, registerError, removeError } = useErrorHandlerContext();

    const promptOrigin = useMemo(() => chatInfo.persona!, [chatInfo.persona]);
    const documents = useMemo(() => getPersonaFromKey(promptOrigin ?? '-')?.documents || globalDocuments, [getPersonaFromKey, globalDocuments, promptOrigin]);

    const initialLoadRef = useRef(false);
    const [data, setData] = useState<DocumentRow[]>([]);

    useEffect(() => reportUploadStateChange?.(data), [data, reportUploadStateChange]);

    const [isLoading, setIsLoading] = useState(false);

    const persistentInput = useCallback(() => getInput(promptOrigin, { documents: [] }), [getInput, promptOrigin]);
    const setDocumentIds = useCallback((documents: string[]) => mergeInput(promptOrigin, { documents: [...(persistentInput().documents ?? []), ...documents] }), [mergeInput, persistentInput, promptOrigin]);
    const removeDocumentIds = useCallback((documents: string[]) => mergeInput(promptOrigin, { documents: (persistentInput().documents ?? []).filter(doc => !documents.includes(doc)) }), [mergeInput, persistentInput, promptOrigin]);


    const loadDocuments = useCallback(async () => {
        removeError(errorId);
        setIsLoading(true);

        try {
            const documentIds = persistentInput().documents ?? [];
            if (!documentIds.length && !chatId)
                return;

            initialLoadRef.current = true;

            const response = await fetchDocuments(chatId ? [] : documentIds, chatId);

            if (response.errors && Object.keys(response.errors).length > 0) {
                const errorMessages = Object.entries(response.errors).map(([_, errorMessage]) => `${errorMessage}`).join(", ");
                registerError({ [errorId]: { type: 'notification', headline: labels.fetchErrorHeadline, description: errorMessages } });
            }

            if (response.documents) {
                const rows = Object.values(response.documents).map((doc) => {
                    return ({
                        documentId: doc.id,
                        fileName: doc.display.name || "",
                        description: doc.display.description || "",
                        status: doc.state?.status || DocumentUploadStatus.Ready,
                        size: doc.size,
                        extension: doc.format,
                        created: doc.created
                    });
                });
                setData(rows);
            }
        }
        catch (error: any) {
            registerError({ [errorId]: { type: 'notification', headline: labels.fetchErrorHeadline, description: labels.fetchErrorMessage, details: error } });
        }
        finally {
            setIsLoading(false);
        }
    }, [removeError, errorId, persistentInput, chatId, fetchDocuments, registerError]);

    // Init documents load
    useEffect(() => { !initialLoadRef.current && loadDocuments(); }, [loadDocuments]);

    const handleFileUpload = useCallback(async (file: File, setUploadProgress: (progress: AxiosProgressEvent) => void) => {
        // Check if the file size is valid
        const { name: fileName } = getExtension(file?.name) || { extension: 'unknown', name: 'undefined' };

        const props: DocumentInfoModel = await uploadDocument(file, chatId, setUploadProgress);

        const { id, size, format, state } = props;
        if (!id) {
            console.error('Document ID is missing');
            return;
        }

        // Handle the case of new chat where the session ID is not yet available
        if (!chatId) setDocumentIds([id]);
        const uploadStatus = state?.status;
        if (!uploadStatus) {
            console.error('Upload status is missing');
            return;
        };

        setData(prev => [...prev, { ...props, fileName, description: '', documentId: id, size: size, extension: format, status: uploadStatus }]);
    }, [uploadDocument, chatId, setDocumentIds]);

    const isDemoTour = useMemo(() => [DEMO_TOUR_FILE_UPLOAD_KEY, DEMO_TOUR_SUCCESS_FILE_UPLOAD_KEY].includes(objectId || ''), [objectId]);

    const demoTour: DocumentRow[] = useMemo(() => [
        {
            documentId: 'demo-tour',
            fileName: 'Uploaded document name',
            description: 'This document contains some important information.',
            status: objectId === DEMO_TOUR_SUCCESS_FILE_UPLOAD_KEY ? DocumentUploadStatus.Ready : DocumentUploadStatus.Processing,
            size: 1345674,
            extension: 'pdf',
            created: new Date().toISOString()
        }
    ], [objectId]);


    return (
        <div className={styles.wrapper} data-testid={TestIds.documentView}>
            <FileInput
                fileList={data}
                maxUploadNumber={documents?.max}
                maxFileUploadSize={documents?.length}
                allowedFileFormats={documents?.formats}
                onFileUpload={handleFileUpload}
            />

            <ErrorHandler.Notifications className={styles.error} id={errorId} />

            {isLoading
                ? <Loader />
                : <>
                    <DocumentList
                        chatId={chatId}
                        documents={isDemoTour ? demoTour : data}
                        removePersistentDocsByIds={removeDocumentIds}
                        setIsLoading={setIsLoading}
                        onRefresh={loadDocuments}
                        setData={setData}
                        onDelete={() => {}}

                        errorId={errorId}
                        registerError={registerError}
                        removeError={removeError}
                    />
                    {showInfo && <Information />}
                </>
            }
        </div>
    );
};