import Tooltip from './Tooltip';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { FC } from 'react';

type Props = {
    run: boolean;
    steps?: Step[];
    stepIndex?: number;
    scrollDuration?: number;
    onClickHandler?: (data: CallBackProps) => void;
};

const Tour: FC<Props> = ({ run, steps, stepIndex, scrollDuration = 1, onClickHandler }) => {
    return (
        <Joyride
            run={run}
            tooltipComponent={Tooltip}
            disableScrolling
            beaconComponent={undefined}
            steps={steps}
            stepIndex={stepIndex}
            continuous
            scrollToFirstStep
            showProgress
            showSkipButton
            disableCloseOnEsc
            disableOverlayClose
            scrollDuration={scrollDuration}
            callback={onClickHandler}
        />
    );
};

export default Tour;