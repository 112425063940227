import { type PropsWithChildren, useEffect, useMemo, } from 'react';
import { useAuth } from '../auth/AuthContext';
import BackendEventProvider from './BackendEventsContext';
import BackendEventsService from '../../services/BackendEventsService';

const BackendEventsServiceProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { getAccessToken, user } = useAuth();

    const service = useMemo(() => BackendEventsService.configure(getAccessToken), [getAccessToken]);
    useEffect(() => {
        if (!user) return;
        service.init();
    }, [user, service]);
    return <BackendEventProvider service={service}>
        {children}
    </BackendEventProvider>;
};

export default BackendEventsServiceProvider;