import { Color } from "../../constants/color";
import { BaseUIType } from "../../models/UITypes";
import { Icons } from "./icons/material";
import { SvgIcons } from './icons/svg';

export type IconComponent = {
    Adaptive: React.FC<AdaptiveIconProps>;
    Async: React.FC<AsyncIconProps>;
    Base: React.FC<IconProps>;
    Svg: React.FC<SvgIconProps>;
};

export enum IconTheme {
    inherit = 'inherit',
    light = 'light',
    dark = 'dark',
    accent = 'accent',

    success = 'success',
    error = 'error',
    warning = 'warning',
    info = 'info',
}

export enum IconStyles {
    filled = 'material-icons',
    outlined = 'material-icons-outlined'
}

type IconSize = 96 | 54 | 42 | 24 | 22 | 20 | 18 | 16;
type BorderSize = 0 | 1 | 2;


// Base
export type IconProps = BaseUIType & {
    id?: string;
    title: string;
    size?: IconSize;
    className?: string;

    iconName: Icons;
    isLoading?: boolean;
    /** 
     * Usually icon is considered clickable when onClick handler is provided. 
     * However in some cases icons can be wrapped in a parent wrapper that handles clicking.
     * Thus an option to indicate override the default behavior is provided.
     * */
    isClickable?: boolean;
    isDisabled?: boolean;
    iconStyle?: IconStyles;
    theme?: IconTheme;
    dataAttributes?: Record<string, string>;
    onClick?: (e: React.MouseEvent<HTMLSpanElement | SVGSVGElement, MouseEvent>) => void;
};


// Async
export type AsyncIconProps = Omit<IconProps, 'onClick'> & {
    onAsyncClick: (e: React.MouseEvent) => Promise<void>;
    flashFeedbackIcon?: boolean;
};

export type SvgIconProps = Pick<IconProps, 'title' | 'className' | 'onClick' | 'size' | 'isClickable' | 'theme'> & BaseUIType & {
    iconName: SvgIcons;
    fill?: Color;
    borderSize?: BorderSize;
    borderColor?: Color;
    style?: React.CSSProperties;
};


// Adaptive
type ALL = Omit<SvgIconProps, "iconName"> & Omit<AsyncIconProps, "iconName" | "onAsyncClick"> & Pick<Partial<AsyncIconProps>, "onAsyncClick"> & Omit<IconProps, "iconName">;
export type AdaptiveIconProps = ALL & {
    iconName: Icons | SvgIcons | string;
    imageSize?: IconSize;
};
