import React, { useEffect, useState } from "react";
import { getClassNames } from '../../helpers/classHelpers';
import style from "./FileInputProgress.module.scss";

type Progress = {
    loaded?: number;
    total?: number;
};

type FileInputProgressProps = {
    completed?: boolean;
    hasError?: boolean;
    className?: string;
    progress?: Progress;
    onDoneLoading?: () => void;
    onDelayedDoneLoading?: () => void;
};

const SHOW_PROGRESS_BAR_AFTER = 50;
const HIDE_PROGRESS_BAR_AFTER = 3000;

const FileInputProgress: React.FC<FileInputProgressProps> = ({ className, completed, hasError, progress, onDoneLoading, onDelayedDoneLoading }) => {
    const [isShowing, setIsShowing] = useState(false);

    const getCurrentPercentage = (progress?: Progress) => progress ? Math.round(((progress?.loaded ?? NaN) / (progress?.total ?? NaN)) * 100) : NaN;
    const progressValue = getCurrentPercentage(progress);
    const isDoneLoading = progressValue === 100;

    useEffect(() => {
        if (!progress) return;
        const showTimeout = setTimeout(() => !isDoneLoading && setIsShowing(true), SHOW_PROGRESS_BAR_AFTER);

        let hideTimeout: NodeJS.Timeout;
        if (completed !== undefined ? isDoneLoading && completed : isDoneLoading) {
            onDoneLoading?.();
            hideTimeout = setTimeout(() => {
                setIsShowing(false);
                onDelayedDoneLoading?.();
            }, HIDE_PROGRESS_BAR_AFTER);
        }

        return () => {
            clearTimeout(showTimeout);
            clearTimeout(hideTimeout);
        };
    }, [completed, progress, isDoneLoading, onDoneLoading, onDelayedDoneLoading]);

    return (
        <div className={getClassNames([className, hasError && isDoneLoading && style.error, !hasError && isDoneLoading && style.success, !isShowing && style.hidden, style['file-input-progress'], isDoneLoading && !completed && style.pulse, "df-progress-bar"])}>
            <div
                role="progressbar"
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ width: progressValue + '%' }}
                aria-valuenow={progressValue}
            />
        </div>
    );
};

export default FileInputProgress;