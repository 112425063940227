import EnvConfigHelper from '../helpers/envConfigHelper';
import { HTTPDataService } from './HTTPDataService';
import type { InfoResponse, TermsResponse } from '../models/types';
import type { IAppInfoService } from './IAppInfoService';
import { defaultCulture } from '../constants/consts';
import ServiceBackendEventsHelper from './helpers/ServiceBackendEventsHelper';
import { IBackendEventsService } from './IBackendEventsService';
import EventHandler from './EventHandler';
import { PreferencesEvent } from '../models/BackendEvents';

class AppInfoService extends HTTPDataService implements IAppInfoService {
    baseURL = EnvConfigHelper.get('api-base-url', '');
    backendEventsHelper: ServiceBackendEventsHelper;
    constructor(public getAccessToken: () => Promise<string | undefined>, backendEvents: IBackendEventsService) {
        super();
        this.backendEventsHelper = new ServiceBackendEventsHelper(this.events, backendEvents);
    }

    private addBaseUrlToPersonaIcons = (info: InfoResponse) => {
        info.personas?.forEach(p => {
            if (p.icon?.startsWith('/static/'))
                p.icon = `${this.baseURL}${p.icon}`;

            if (p.avatar?.startsWith('/static/'))
                p.avatar = `${this.baseURL}${p.avatar}`;

        });
    };
    getInfo = async () => {
        return new Promise<InfoResponse>(async (resolve, reject) => {
            try {
                const result = await this.get<InfoResponse>('api/info');
                this.addBaseUrlToPersonaIcons(result);

                resolve(result);
            } catch (e) {
                console.error(e);
                reject(e);
            }
        });
    };

    getTerms = async () => {
        try {
            const uri = 'api/info/terms';
            const query = {
                culture: defaultCulture
            };
            const termsPromise = this.get<TermsResponse>(uri, query);
            return await termsPromise;
        } catch (e) {
            console.error(e);
            return Promise.reject(e);
        }
    };

    getPersonaVersionReleaseNotes = async (persona: string, version: string) => {
        const uri = 'api/info/personas-version-release-notes';
        const query = {
            persona, version
        };
        return this.get<string>(uri, query);
    };

    events = {
        preferences: new EventHandler<PreferencesEvent>()
    };

    bindEvents = () => {
        this.backendEventsHelper.bind();
    };
    unbindEvents = () => {
        this.backendEventsHelper.unbind();
    };
}

export default AppInfoService;