import React, { Fragment, useCallback, useMemo, useState } from 'react';
import useLabels from '../../hooks/useLabels';
import useScrollLock from '../../hooks/useScrollLock';
import Prompt from '../../ui/prompt/Prompt';
import Button, { ButtonIconPosition, ButtonThemes } from '../../ui/button/Button';
import List, { ListItemContentProps } from '../../ui/list/List';
import { Icon } from '../../ui/icon/Icon';
import { useLocation } from 'react-router-dom';
import { getClassNames } from '../../helpers/classHelpers';
import { groupBy } from '../../helpers/listsHelpers';
import { DateGroup } from '../../helpers/dateHelpers';
import { IconStyles, IconTheme } from '../../ui/icon/Icon.types';
import { Icons } from '../../ui/icon/icons/material';
import styles from './History.module.scss';

type Props = {
    listItems: EnrichedListItem[];
    isOpen?: boolean;
    onClose?: () => void;
    onDeleteChat?: (id: string) => Promise<void>;
    onDeleteAllChats?: () => Promise<void[]>;

    deleteModalChatId?: string;
    setDeleteModalChatId: (id?: string) => void;

    isDeleteAllModalOpen: boolean;
    setIsDeleteAllModalOpen: (isOpen: boolean) => void;
};

export type EnrichedListItem = ListItemContentProps & {
    date: string;
    dateGroup: DateGroup;
};

export const HistoryBody: React.FC<Omit<Props, 'deleteModalChatId' | 'isDeleteAllModalOpen'>> = ({ setDeleteModalChatId, setIsDeleteAllModalOpen, listItems, isOpen, onClose, onDeleteAllChats }) => {
    const labels = useLabels();
    const { pathname } = useLocation();

    const l = useMemo(() => {
        return {
            clearHistory: labels.clearHistory,
            deleteIconTitle: labels.deleteIconTitle,
            // Date groups
            'today': labels.today,
            'yesterday': labels.yesterday,
            'withing-7-days': labels.within7Days,
            'within-30-days': labels.within30Days,
            'january': labels.jan,
            'february': labels.feb,
            'march': labels.mar,
            'april': labels.apr,
            'may': labels.may,
            'june': labels.jun,
            'july': labels.jul,
            'august': labels.aug,
            'september': labels.sep,
            'october': labels.oct,
            'november': labels.nov,
            'december': labels.dec,
        };
    }, [labels]);

    useScrollLock(isOpen);

    const deleteIcon = useCallback((id: string) => {
        return <Icon.Base title={l.deleteIconTitle} className={getClassNames([styles.icon, 'df-icon icon-s'])} iconName={Icons.delete} iconStyle={IconStyles.filled} />;
    }, [l]);

    const controlledListItems = useMemo(() => {
        return listItems
            .map<EnrichedListItem>((item) => {
                const isCurrentRoute = item.url === pathname;

                return {
                    ...item,
                    className: getClassNames([styles['list-item'], isCurrentRoute && styles['active-route']]),
                    onClick: onClose,
                    onHoverIconClick: () => setDeleteModalChatId(item.id),
                    hoverIcon: deleteIcon(item.id),
                    hoverIconClassName: styles['delete-icon'],
                };
            });
    }, [listItems, pathname, onClose, deleteIcon, setDeleteModalChatId]);

    const dateGroups = useMemo(() => groupBy(controlledListItems, 'dateGroup'), [controlledListItems]);

    return <div className={styles.history}>
        {dateGroups.map(({ group, items }) => {
            return <Fragment key={group}>
                <label className={styles['group-title']}>{l[group] || group}</label>
                <List listItems={items} className={styles.list} />
            </Fragment>;
        })}
        {onDeleteAllChats && <div className={styles['delete-all']}>
            {!!dateGroups.length && <Button isDisabled={listItems.length === 0} iconPosition={ButtonIconPosition.beginning} icon={<Icon.Base size={22} isClickable theme={IconTheme.inherit} iconName={Icons.delete} title='' />} theme={ButtonThemes.customTextLightDarkToDark} onClick={() => setIsDeleteAllModalOpen(true)}>{l.clearHistory}</Button>}
        </div>}
    </div>;
};

export const HistoryFooter: React.FC<Props> = ({ onClose, onDeleteChat, onDeleteAllChats, setIsDeleteAllModalOpen, setDeleteModalChatId, deleteModalChatId, isDeleteAllModalOpen }) => {
    const labels = useLabels();

    const l = useMemo(() => {
        return {
            tbd: labels.tbd,
            clearHistoryHeadline: labels.clearHistoryHeadline,
            clearHistoryDescription: labels.clearHistoryDescription,
            clearHistory: labels.clearHistory,

            deleteChatHeadline: labels.deleteChatHeadline,
            deleteChatDescription: labels.deleteChatDescription,
            delete: labels.delete,
            cancel: labels.cancel,
        };
    }, [labels]);

    const onDeleteAllChatsHandler = useCallback(async () => {
        if (!onDeleteAllChats) return;
        await onDeleteAllChats();
        setIsDeleteAllModalOpen(false);
        onClose?.();
    }, [onClose, onDeleteAllChats, setIsDeleteAllModalOpen]);

    const renderDeleteAllChatsModal = useMemo(() => {
        return <Prompt
            isOpen={isDeleteAllModalOpen}
            onClose={() => setIsDeleteAllModalOpen(false)}
            headline={l.clearHistoryHeadline}
            body={l.clearHistoryDescription}
            primaryButton={{ label: l.clearHistory, onClick: onDeleteAllChatsHandler }}
            secondaryButton={{ label: l.cancel, }}
            closeOnBackdropClick
            closeOnEscape
        />;
    }, [setIsDeleteAllModalOpen, isDeleteAllModalOpen, l.clearHistory, l.clearHistoryDescription, l.clearHistoryHeadline, l.cancel, onDeleteAllChatsHandler]);


    const onDeleteChatHandler = useCallback((id: string) => {
        if (!onDeleteChat) return Promise.reject();

        const promise = onDeleteChat(id);
        promise.finally(() => setDeleteModalChatId(undefined));
        return promise;
    }, [onDeleteChat, setDeleteModalChatId]);

    const onDeleteModalChatHandler = useCallback(() => {
        if (!deleteModalChatId) return Promise.reject();
        return onDeleteChatHandler(deleteModalChatId);
    }, [deleteModalChatId, onDeleteChatHandler]);

    const renderDeleteChatModal = useMemo(() => {
        return <Prompt
            isOpen={!!deleteModalChatId}
            onClose={() => setDeleteModalChatId(undefined)}
            headline={l.deleteChatHeadline}
            body={l.deleteChatDescription}
            primaryButton={{ label: l.delete, onClick: onDeleteModalChatHandler }}
            secondaryButton={{ label: l.cancel, }}
            closeOnBackdropClick
            closeOnEscape
        />;
    }, [deleteModalChatId, l.cancel, l.delete, l.deleteChatDescription, l.deleteChatHeadline, setDeleteModalChatId, onDeleteModalChatHandler]);

    return <>
        {renderDeleteAllChatsModal}
        {renderDeleteChatModal}
    </>;
};

export const useHistoryDeletionModal = () => {
    const [isDeleteAllModalOpen, setIsDeleteAllModalOpen] = useState(false);
    const [deleteModalChatId, setDeleteModalChatId] = useState<string>();

    return { deleteModalChatId, setDeleteModalChatId, isDeleteAllModalOpen, setIsDeleteAllModalOpen };
}

