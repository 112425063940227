import React, { type PropsWithChildren, useEffect, useMemo } from 'react';
import AppInfoProvider from './AppInfoContext';
import MockAppInfoService from '../../services/MockAppInfoService';
import { useAuth } from '../auth/AuthContext';
import { useBackendEvents } from '../backend-events/BackendEventsContext';

const MockAppInfoServiceProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { getAccessToken } = useAuth();
    const backendEvents = useBackendEvents();

    const service = useMemo(() => new MockAppInfoService(getAccessToken, backendEvents), [getAccessToken, backendEvents]);

    useEffect(() => {
        service.bindEvents();
        return () => service.unbindEvents();
    }, [service]);

    return <AppInfoProvider service={service}>
        {children}
    </AppInfoProvider>;
};

export default MockAppInfoServiceProvider;