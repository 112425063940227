import { useEffect, useRef } from "react";

/**Retains a ref that is always updated with the latest value. Useful when a non-reactive ref is need to a reactive state*/
export default function useRefLive<T>(value: T, onChange?: (state: T, prevState: T) => void) {
    const ref = useRef(value);
    useEffect(() => {
        const current = ref.current;
        if (current === value)
            return;

        ref.current = value;
        onChange?.(value, current);
    }, [value, onChange]);
    return ref;
}

