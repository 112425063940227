import React from 'react';
import ListItemContent from './ListItemContent';
import { getClassNames } from '../../helpers/classHelpers';
import { LinkProps } from 'react-router-dom';
import { Icons } from '../icon/icons/material';
import { SvgIcons } from '../icon/icons/svg';
import style from './List.module.scss';

export type ListProps = {
    listItems: ListItemContentProps[];
    className?: string;
};

export type ListItemContentProps = {
    title: string;
    url?: string;
    linkProps?: Omit<LinkProps, 'to'>;
    id: string;
    onClick?: () => void;
    onHoverIconClick?: () => void;
    hoverIcon?: React.ReactElement;
    hoverIconClassName?: string;
    icon?: Icons | SvgIcons | string;
    className?: string;
};

const List: React.FC<ListProps> = ({ listItems, className }) => {
    return (
        <ul className={getClassNames([className, style.list])}>
            {listItems.map((item) => <ListItemContent key={item.id} item={item} />)}
        </ul>
    );
};

export default List;