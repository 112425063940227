import React, { type PropsWithChildren, useMemo } from 'react';
import Loader from '../../ui/loader/Loader';
import AdaptiveProvider from '../adaptive/AdaptiveProvider';
import ToursProvider from '../../features/tours/context/ToursProvider';
import { useAuth } from '../auth/AuthContext';
import { useAppInfo } from '../app-info/AppInfoContext';

const ContextBuffer: React.FC<PropsWithChildren> = ({ children }) => {
    const { tokenState, isStartingUp, isLoggingIn, isLoggingOut } = useAuth();
    const { authenticationState } = useAppInfo();

    const isLoadingUser = useMemo(() => isLoggingOut || isLoggingIn || isStartingUp, [isLoggingIn, isLoggingOut, isStartingUp]);
    const isLoadingToken = useMemo(() => tokenState === undefined, [tokenState]);
    const isLoadingAppInfo = useMemo(() => authenticationState === undefined, [authenticationState]);
    const isLoading = useMemo(() => isLoadingUser || isLoadingAppInfo || isLoadingToken, [isLoadingAppInfo, isLoadingToken, isLoadingUser]);

    return <>
        <Loader isVisible={isLoading} />

        {!isLoading && (
            <AdaptiveProvider>
                <ToursProvider>
                    {children}
                </ToursProvider>
            </AdaptiveProvider>
        )}
    </>;
};

export default ContextBuffer;