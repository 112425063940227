import React, { type PropsWithChildren } from 'react';
import MockAuthContextProvider, { AuthState } from '../auth/MockMsalAuthContextProvider';
import MockChatServiceProvider from '../chat/MockChatServiceProvider';
import MockAppInfoServiceProvider from '../app-info/MockAppInfoServiceProvider';
import MockBackendEventsServiceProvider from '../backend-events/MockBackendEventsServiceProvider';

const MockContextBootstrap: React.FC<PropsWithChildren<{ authState?: Partial<AuthState>; }>> = ({ children, authState: authStateOverride }) => {
    return (
        <MockAuthContextProvider>
            <MockBackendEventsServiceProvider>
                <MockAppInfoServiceProvider>
                    <MockChatServiceProvider>
                        {children}
                    </MockChatServiceProvider>
                </MockAppInfoServiceProvider>
            </MockBackendEventsServiceProvider>
        </MockAuthContextProvider>
    );
};

export default MockContextBootstrap;