import { useCallback, useEffect, useMemo, useState } from 'react';
import { isFunction } from '../helpers/typeHelpers';
import useRefLive from './useRefLive';

export default function useStorage(scope?: string, storeType: 'local' | 'session' = 'local', valueType: 'json' | 'string' = 'json') {
    const storage = useMemo(() => storeType === 'local' ? localStorage : sessionStorage, [storeType]);
    const getKeyWithScope = useCallback((key: string) => [scope, key].filter(Boolean).join(':'), [scope]);

    const get = useCallback((k: string) => {
        const key = getKeyWithScope(k);
        const item = storage.getItem(key);

        if (valueType === 'json')
            return item ? JSON.parse(item) : null;
        else
            return item as string;

    }, [getKeyWithScope, storage, valueType]);

    const set = useCallback((k: string, value: any) => {
        const key = getKeyWithScope(k);

        const storeValue = valueType === 'json' ? value ? JSON.stringify(value) : null : value;

        storage.setItem(key, storeValue as string);
    }, [getKeyWithScope, storage, valueType]);

    const remove = useCallback((k: string) => {
        const key = getKeyWithScope(k);
        storage.removeItem(key);
    }, [getKeyWithScope, storage]);

    const useStorageState = <T,>(key: string, initialValue?: T) => {
        // Initialize the state with the value from storage or the default value
        const [state, setState] = useState<T>(() => get(key) ?? initialValue);
        const stateRef = useRefLive(state);
        // Update the state and storage when the setter is called
        const setStorageState = useCallback((s: T | ((prev: T) => T)) => {
            const storageKey = getKeyWithScope(key);

            const action = isFunction(s) ? s : () => s;

            const val = action(stateRef.current);

            if (val === stateRef.current)
                return;

            setState(val);
            set(key, val);
            // StorageEvents are not fired in the originating window/tab, therefore, we fire a custom event to broadcast changes in the locally window.
            // Read more here: https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
            window.dispatchEvent(new CustomEvent('storageStateChange', { detail: { key: storageKey, storeType, value: val } }));

        }, [key, stateRef]);

        useEffect(() => {
            const handleStorageEvent = (event: StorageEvent | CustomEvent) => {
                const storageKey = getKeyWithScope(key);
                const details: {
                    key: string;
                    storeType: typeof storeType;
                    value: any;
                } = 'detail' in event ? event.detail : {
                    key: event.key,
                    storeType: event.storageArea === localStorage ? "local" : "session",
                    value: event.newValue
                };

                if (details.key !== storageKey || details.storeType !== storeType)
                    return;
                if (stateRef.current === details.value)
                    return;

                setState(details.value);
            };

            // Sync state when changes occur in the same document
            window.addEventListener('storageStateChange', handleStorageEvent as EventListener);
            // Sync state when changes occur across tabs/windows
            window.addEventListener('storage', handleStorageEvent);
            return () => {
                window.removeEventListener('storageStateChange', handleStorageEvent as EventListener);
                window.removeEventListener('storage', handleStorageEvent);
            };
        }, [key, stateRef]);

        return [state, setStorageState] as const;
    };

    return {
        get,
        set,
        remove,
        useStorageState
    };
}