import { CancellationToken } from '../../hooks/useEffectAsync';
import useStateRef from '../../hooks/useStateRef';
import { useCallback } from 'react';
import { SessionResponse } from '../../models/ChatTypes';
import { useChat } from '../chat/ChatContext';


const useChatsHistory = () => {
    const { getAllChats } = useChat();
    const [chatsHistory, setChatsHistory, chatsHistoryRef] = useStateRef<SessionResponse[]>();

    const loadChatsHistory = useCallback((async ({ token, force }: { token?: CancellationToken, force?: boolean; }) => {
        if (chatsHistory?.length && !force) return;

        try {
            if (token?.cancelled) return;
            const chats = await getAllChats();

            setChatsHistory([...chats]);
        } catch (e) {
            if (token?.cancelled) return;
            console.error({ e });
        }
    }), [chatsHistory, getAllChats, setChatsHistory]);

    const reloadChatsHistory = useCallback(async (force?: boolean) => {
        // only reload force is true or chat history has not been loaded
        if (force || !chatsHistoryRef.current)
            await loadChatsHistory({ force });
        return Promise.resolve<void>(undefined);
    }, [chatsHistoryRef, loadChatsHistory]);

    return {
        chatHistory: {
            items: chatsHistory,
            set: setChatsHistory,
            reload: reloadChatsHistory
        }
    };
};

export default useChatsHistory;