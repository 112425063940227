import NewChatWindow from '../../elements/new-chat-window/NewChatWindow';
import WorkflowWindow from '../../elements/workflow-window/WorkflowWindow';
import ChatWindow from '../../elements/chat-window/ChatWindow';
import { memo } from 'react';
import { ChatViewLayoutProps } from '../../layouts/chat-view-layout/ChatViewLayout';
import { useAdaptive } from '../../contexts/adaptive/AdaptiveContext';
import useInfo from '../../hooks/useInfo';

const ChatView = memo((props: ChatViewLayoutProps) => {
    const { chatInfo } = useAdaptive();
    const { getPersonaFromKey } = useInfo();
    if (props.newPrompt) return <NewChatWindow {...props} />;
    if (!props.newPrompt && chatInfo?.persona && getPersonaFromKey(chatInfo?.persona)?.type === "workflow") return <WorkflowWindow {...props} />;
    return <ChatWindow {...props} />;
});

export default ChatView;