import React from 'react';
import parse from 'html-react-parser';
import Button from '../button/Button';
import Modal from '../modal/Modal';
import useTerms from '../../hooks/useTerms';
import style from './UserInstructionsModal.module.scss';
import useLabels from '../../hooks/useLabels';
import Loader from '../loader/Loader';
import Alert from '../alert/Alert';

type Props = {
    isModalOpen: boolean;
    closeModal: () => void;
};

const UserInstructionsModal: React.FC<Props> = ({ isModalOpen, closeModal }) => {
    const labels = useLabels();
    const { terms, isLoading, error } = useTerms({ active: isModalOpen });

    return (<>
        <Modal
            className={style.modal}
            size='large'
            isOpen={isModalOpen}
            headline={labels.userInstructions}
            body={<>
                <Loader isVisible={isLoading} />
                {terms && parse(terms?.text?.trim())}
                {error && <Alert headline={error.message} type='error' />}
            </>}
            footer={<Button label={labels.disclaimerButton} onClick={closeModal} />}
            closeOnBackdropClick
            closeOnEscape
            onClose={closeModal}
        />
    </>
    );
};

export default UserInstructionsModal;