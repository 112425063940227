import useChatsHistory from './useChatsHistory';
import useCatalogFavorites from './useCatalogFavorites';
import useChatInfo from './useChatInfo';
import useRibbonMessage from './useRibbonMessage';
import { PropsWithChildren, useMemo } from 'react';
import { AdaptiveContext } from './AdaptiveContext';

const AdaptiveProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const chatHistoryProps = useChatsHistory();
    const catalogFavoritesProps = useCatalogFavorites();
    const chatInfo = useChatInfo();
    const ribbonMessage = useRibbonMessage();
    const value = useMemo(() => ({ ...chatInfo, ...chatHistoryProps, ...catalogFavoritesProps, ...ribbonMessage }), [catalogFavoritesProps, chatHistoryProps, chatInfo, ribbonMessage]);
    return <AdaptiveContext.Provider value={value}>
        {children}
    </AdaptiveContext.Provider>;
};

export default AdaptiveProvider;