import React, { ReactNode, useEffect, useMemo, useState, } from 'react';
import Modal from '../modal/Modal';
import ButtonAsync from '../button/ButtonAsync';
import { ButtonThemes, type ButtonProps } from '../button/Button';
import { useMediaSize } from '../../hooks/useMediaSize';
import { MediaSize } from '../../constants/consts';
import { getClassNames } from '../../helpers/classHelpers';
import style from './Prompt.module.scss';

export type PromptProps = {
    isOpen: boolean;
    headline: string;
    header?: ReactNode;
    body?: ReactNode;
    primaryButton: Omit<ButtonProps, 'theme'>;
    secondaryButton?: Omit<ButtonProps, 'theme' | 'onClick'>;
    closeOnBackdropClick?: boolean;
    closeOnEscape?: boolean;
    onClose?: () => void;

    className?: string;
    buttonContainerClassNames?: string;
};

const Prompt: React.FC<PromptProps> = ({ isOpen, headline, header, body, buttonContainerClassNames, primaryButton, secondaryButton, closeOnBackdropClick, closeOnEscape, onClose, className, }) => {
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => setIsMounted(true), []);

    const isMobile = useMediaSize(ms => ms <= MediaSize.xss);

    const renderFooter = useMemo(() => {
        return <div className={getClassNames([style.actions, buttonContainerClassNames])}>
            {onClose && <ButtonAsync
                fullWidth={isMobile}
                {...secondaryButton}
                theme={ButtonThemes.textPrimary}
                onClick={onClose}
            />}
            <ButtonAsync fullWidth={isMobile} {...primaryButton} />
        </div>;
    }, [buttonContainerClassNames, onClose, isMobile, secondaryButton, primaryButton]);

    if (!isMounted) return null;
    return (
        <Modal
            size='small'
            className={getClassNames([className, style.prompt])}
            isOpen={isOpen}
            headline={headline}
            header={header}
            body={body}
            footer={renderFooter}
            hideCloseIcon
            closeOnBackdropClick={closeOnBackdropClick}
            closeOnEscape={closeOnEscape}
            onClose={onClose}
        />
    );
};

export default Prompt;