import { Step } from 'react-joyride';
import { TourIds } from '../consts';
import { ObjectTypes } from '../../../ui/markdown-wrapper/hooks/useActions/useActions';
import { defaultPersonaKey } from '../../../constants/consts';
import { TourReturnType, ToursSharedTargets } from '../Tours';

export enum TourFileUploadTargets {
    icon = 'fu-icon',
    uploadBox = 'fu-area',
    statusIndicator = 'fu-status-indicator',
    submit = 'fu-submit-input',

}

export const DEMO_TOUR_FILE_UPLOAD_KEY = 'demo-tour';
export const DEMO_TOUR_SUCCESS_FILE_UPLOAD_KEY = 'demo-tour-success';

const tourId = TourIds.fileUpload;
const useTourFileUploads = (): TourReturnType => {
    return {
        id: tourId,
        label: 'Document Upload feature',
        data: {
            steps: [
                {
                    target: '#' + ToursSharedTargets.tourContainer,
                    title: 'Document Upload',
                    content: 'Upload documents and leverage AI models to answer questions based on their content.',
                    placement: 'center',
                    data: {
                        pathname: defaultPersonaKey,
                        hash: '',
                        timeout: 500
                    }
                },
                {
                    target: '#' + TourFileUploadTargets.icon,
                    content: 'Click on the Document Upload icon.',
                    placement: 'auto',
                    data: {
                        hash: ObjectTypes.documents + '-' + DEMO_TOUR_FILE_UPLOAD_KEY,
                        timeout: 500
                    },
                },
                {
                    target: '#' + TourFileUploadTargets.uploadBox,
                    content: 'Click on Browse or simply drop documents to upload in this area here.',
                    placement: 'auto',
                },
                {
                    target: '#' + TourFileUploadTargets.statusIndicator,
                    content: 'Wait for the documents to finish processing, and you are ready!',
                    placement: 'auto',
                    data: {
                        hash: ObjectTypes.documents + '-' + DEMO_TOUR_SUCCESS_FILE_UPLOAD_KEY,
                        timeout: 50,
                    }
                },
                {
                    target: '#' + TourFileUploadTargets.submit,
                    content: 'You can now ask questions about your documents!',
                    placement: 'auto',
                },
            ] as Step[],
        }
    };
};

export default useTourFileUploads;