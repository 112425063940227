import React from 'react';
import { UIPaginationProps } from './Pagination.types';
import { getClassNames } from '../../helpers/classHelpers';
import { Icons } from '../icon/icons/material';
import { Icon } from '../icon/Icon';
import style from './Pagination.module.scss';

const alias = style['pagination'];

/** Component's description */
const UIPagination: React.FC<UIPaginationProps> = ({
    totalCount,
    hrefNext = '',
    hrefPrev = '',
    pageIndex = 0,
    onNext,
    onBack,
    showBorders,

    className,
    hidden
}) => {
    const mosaicContainerClass = 'df-pagination-container';
    const classNames = getClassNames([alias, className, mosaicContainerClass, !showBorders && style['hide-borders']]);

    if (hidden || totalCount === 0) return null;

    return <nav className={classNames} aria-label={'Pagination'}>
        <div className='df-pagination'>
            <a
                href={hrefPrev}
                aria-label={'Previous page'}
                onClick={(e) => onBack?.({ e })}
            >
                <Icon.Base isDisabled={pageIndex === 1} title='Previous page' size={18} iconName={Icons.arrowBack} />
            </a>

            <span className='df-pagination-text'>{pageIndex}/{totalCount}</span>

            <a
                href={hrefNext}
                aria-label={'Next page'}
                onClick={(e) => onNext?.({ e })}
            >
                <Icon.Base isDisabled={pageIndex === totalCount} title='Next page' size={18} iconName={Icons.arrowForward} />
            </a>
        </div>
    </nav>;
};

export default UIPagination;
