import { useEffect, useState } from 'react';
import { useAppInfo } from '../contexts/app-info/AppInfoContext';
import type { TermsResponse } from '../models/types';
import useLabels from './useLabels';

export default function useTerms({ active }: { active?: boolean; } = { active: true }) {
    const { getTerms } = useAppInfo();

    const labels = useLabels();

    const [terms, setTerms] = useState<TermsResponse>();
    const [error, setError] = useState<Error>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!active) return setIsLoading(false);

        let promise: Promise<TermsResponse> | undefined;
        (async () => {
            setIsLoading(true);
            try {
                promise = getTerms();
                const terms = await promise;
                terms.text = terms.text?.replace(/<p>[\s]*<\/p>/gmi, '')
                setTerms(terms);
            } catch (e) {
                console.error(e);
                setError(new Error(labels.termsApiError));
            } finally {
                setIsLoading(false);
            }
        })();
    }, [active, getTerms, labels.termsApiError]);

    return {
        terms,
        error,
        isLoading,
    };
}