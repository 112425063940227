import React, { type PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser';
import AuthContext, { type IAuthService, TokenState } from './AuthContext';
import { getApiAccessToken, getDefaultAccessToken, getPhoto, loginRequest, loginUser, logoutUser } from '../../services/MsalAuthService';
import useIsDisposedRef from '../../hooks/useIsDisposedRef';



const MsalInnerAuthContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { instance, accounts, inProgress } = useMsal();
    useMsalAuthentication(InteractionType.Silent, loginRequest);

    const [tokenState, setTokenState] = useState<TokenState | undefined>(TokenState.Valid);

    const isDisposed = useIsDisposedRef();
    const getAccessToken = useCallback(() => {
        const p = getApiAccessToken();
        const doSetTokenState = (s: TokenState) => !isDisposed.current && setTokenState(s);
        p
            .then(() => doSetTokenState(TokenState.Valid))
            .catch((e: any) => {
                // Error types: https://learn.microsoft.com/en-us/entra/identity-platform/msal-error-handling-js#error-types
                if (e instanceof InteractionRequiredAuthError) doSetTokenState(TokenState.RequiresInteraction);
                else doSetTokenState(TokenState.Invalid);
            });

        return p;
    }, [isDisposed]);

    const suspendInProgressCapture = useRef(false);
    const inProgressDebouncedTimeout = useRef<NodeJS.Timeout>();
    const [inProgressDebounced, setInProgressDebounced] = useState(inProgress);
    useEffect(() => {
        if (suspendInProgressCapture.current)
            return;
        // Based on observation, the inProgress state provided by MSAL is set to None during the 
        // startup between the `InteractionStatus.Startup` state and other states that indicates an 
        // logging in activity is taking place. This poses an issue for us as we start rendering the 
        // UI based on this state as soon as it changes. 
        // The sequence goes like this: `Startup` -> `None` -> `SsoSilent`
        // The part of the sequence that is problematic is `None`. 
        // To workaround this, we will debounce the `None` state and set it if nothing changes within 
        // 100 ms. 
        clearTimeout(inProgressDebouncedTimeout.current);
        if (inProgress === InteractionStatus.None) {
            inProgressDebouncedTimeout.current = setTimeout(() => {
                setInProgressDebounced(inProgress);
            }, 100);
        }
        else setInProgressDebounced(inProgress);
    }, [inProgress]);

    const { isStartingUp, isLoggingOut, isLoggingIn } = useMemo(() => {
        let isStartingUp = false;
        let isLoggingOut = false;
        let isLoggingIn = false;

        switch (inProgressDebounced) {
            case InteractionStatus.Startup:
                isStartingUp = true;
                break;
            case InteractionStatus.Logout:
                isLoggingOut = true;
                break;
            case InteractionStatus.SsoSilent:
            case InteractionStatus.Login:
            case InteractionStatus.HandleRedirect:
                isLoggingIn = true;
                break;
        }
        return {
            isStartingUp,
            isLoggingOut,
            isLoggingIn
        };
    }, [inProgressDebounced]);

    const account = useMemo(() => {
        if (inProgressDebounced !== InteractionStatus.None)
            return undefined;
        if (accounts.length === 0) return undefined;
        return accounts[0];
    }, [accounts, inProgressDebounced]);


    const login = useCallback(() => {
        suspendInProgressCapture.current = true;
        loginUser(instance);
    }, [instance]);
    const logout = useCallback(() => {
        suspendInProgressCapture.current = true;
        logoutUser(instance, account);
    }, [instance, account]);


    const [avatar, setAvatar] = useState<string | undefined>(undefined);
    useEffect(() => {
        (async () => {
            const photo = await getPhoto(account, getDefaultAccessToken);
            setAvatar(photo);
        })();
    }, [account]);

    const user = useMemo(() => {
        if (!account) return undefined;
        return {
            ...account,
            avatar,
        };
    }, [account, avatar]);

    const service = useMemo<IAuthService>(() => {
        return {
            login,
            logout,
            getAccessToken,
            user,
            tokenState,
            isStartingUp,
            isLoggingIn,
            isLoggingOut,
        };
    }, [login, logout, user, tokenState, getAccessToken, isStartingUp, isLoggingIn, isLoggingOut]);

    return <AuthContext service={service}>
        {children}
    </AuthContext>;
};

export default MsalInnerAuthContextProvider;