import useStorage from '../../hooks/useStorage';
import { useCallback, useState } from 'react';
import { ChatInputModel } from '../../elements/chat-input';
import { LlmUIOption, PersonaOption } from '../../models/types';

const PERSISTENT_NEW_PROMPTS_SCOPE = 'session-prompt';

const usePersistentInputState = () => {
    const { get, set, remove } = useStorage(PERSISTENT_NEW_PROMPTS_SCOPE, 'local', 'json');
    const [lastInputState, setLastInputState] = useState<ChatInputModel>();

    const handleChangeInput = useCallback((sessionId: string, model: ChatInputModel) => {
        if (!model || !sessionId) return console.error('Invalid input model or session id');
        setLastInputState(model);
        return set(sessionId, model);
    }, [set]);

    const handleRemoveInput = useCallback((sessionId: string) => {
        setLastInputState(undefined);
        return remove(sessionId);
    }, [remove]);


    const handleGetInput = useCallback((sessionId: string, defaultModel?: ChatInputModel) => {
        const model = { ...(defaultModel ?? {}), ...(get(sessionId) as ChatInputModel) };
        return model;
    }, [get]);

    const handleMergeInput = useCallback((sessionId: string, inputModel: Partial<ChatInputModel>) => {
        const model = { ...(handleGetInput(sessionId) ?? {}), ...inputModel };
        return handleChangeInput(sessionId, model);
    }, [handleGetInput, handleChangeInput]);


    return {
        lastInputState,
        getInput: handleGetInput,
        setInput: handleChangeInput,
        mergeInput: handleMergeInput,
        removeInput: handleRemoveInput
    };
};

export default usePersistentInputState;


export const validateInputModelSelection = (model: ChatInputModel, llmOptions: LlmUIOption[], personaOptions: PersonaOption[]) => {
    const isValidLlmSelection = !model.llm || llmOptions.some(x => x.value === model.llm);
    const isValidPersonaSelection = !model.persona || personaOptions.some(x => x.key === model.persona);
    const isValidSelection = isValidLlmSelection && isValidPersonaSelection;
    return {
        isValidLlmSelection, isValidPersonaSelection, isValidSelection
    };
};