import { equals } from './stringHelpers';


type Enum<E> = Record<keyof E, number | string> & { [k: number]: string; };
type EnumValue<E> = E extends Record<keyof E, infer V> ? V : never;


export const forEachEnum = <E extends Enum<E>, K = keyof E, V = EnumValue<E>>(e: E, action: ((value: V, key: K) => void)) => {
    for (let key in e) {
        action((e as any)[key] as V, key as any as K);
    }
};

export const mapEnum = <E extends Enum<E>, R, K = keyof E, V = EnumValue<E>>(e: E, action: ((value: V, key: K) => R)) => {
    const map = [] as R[];
    forEachEnum(e, (value, key) => map.push(action(value as any, key as any)));
    return map;
};

export const enumKeyValue = <E extends Enum<E>>(e: E) =>
    mapEnum(e, (value, key) => ({ value, key }));

export const enumKeys = <E extends Enum<E>>(e: E) =>
    mapEnum(e, (_, key) => key);

export const enumValues = <E extends Enum<E>>(e: E) =>
    mapEnum(e, (value, _) => value);

export const isEnumKey = <E extends Enum<E>, K = keyof E>(e: E, k: any): k is K => {
    for (let key in e) if (key === k) return true;
    return false;
};

export const isEnumValue = <E extends Enum<E>, V = EnumValue<E>>(e: E, v: any): v is V => {
    for (let key in e) if (e[key] === v) return true;
    return false;
};

export const getEnumByKeyOrValue = <E>(e: E, v: any, ignoreCase?: boolean): EnumValue<E> | undefined => {
    if (v === undefined || v === null) {
        return undefined;
    }

    for (let key in e) {
        const ev = e[key] as any;
        if (equals(key, v, ignoreCase))
            return ev;
        if (equals(ev.toString(), v.toString(), ignoreCase))
            return ev;
    }
};