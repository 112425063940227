import EnvConfigHelper from '../helpers/envConfigHelper';
import { mockGetInfoResponse, mockGetTermsResponse } from '../data/mockData';
import { UserPreferences } from '../models/types';
import { IAppInfoService } from './IAppInfoService';
import ServiceBackendEventsHelper from './helpers/ServiceBackendEventsHelper';
import { IBackendEventsService } from './IBackendEventsService';
import EventHandler from './EventHandler';
import { PreferencesEvent } from '../models/BackendEvents';

const delay = <R>(response: R) => new Promise<R>((resolve) => setTimeout(() => resolve(response), 500));

class MockAppInfoService implements IAppInfoService {
    baseURL = EnvConfigHelper.get('api-base-url', '');

    backendEventsHelper: ServiceBackendEventsHelper;

    constructor(public getAccessToken: () => Promise<string | undefined>, backendEvents: IBackendEventsService) {
        this.backendEventsHelper = new ServiceBackendEventsHelper(this.events, backendEvents);
    }

    getInfo = async () => {
        this.getAccessToken();
        return delay(mockGetInfoResponse);
    };

    getTerms = async () => {
        return delay(mockGetTermsResponse);
    };

    getPersonaVersionReleaseNotes = async (persona: string, version: string) => {
        return delay("MOCK");
    };

    setUserPreferences = async (preferences: UserPreferences) => {};

    events = {
        preferences: new EventHandler<PreferencesEvent>()
    };

    bindEvents = () => {
        this.backendEventsHelper.bind();
    };
    unbindEvents = () => {
        this.backendEventsHelper.unbind();
    };
}

export default MockAppInfoService;