import { Icon } from '../icon/Icon';
import { Icons } from '../icon/icons/material';
import style from './RibbonMessage.module.scss';

export type RibbonMessageProps = {
    id?: string;
    label?: string;
    urlLabel?: string;
    url?: string;
    handleClose: () => void;
};

const RibbonMessage = ({ id, label, url, urlLabel, handleClose }: RibbonMessageProps) => {
    if (!id || !label) return null;

    return <div className={style['ribbon-message']} >
        <div className={style['content-container']}>
            <div className={style['text-container']}>
                <b>{label}</b>&nbsp;&nbsp;
                {url && <a href={url}>{urlLabel}</a>}
            </div>

            {handleClose && <Icon.Base title={''} iconName={Icons.clear} onClick={handleClose} />}
        </div>
    </div>;
};

export default RibbonMessage;