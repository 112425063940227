import { isString } from "./typeHelpers";

export function copyToClipboard(text?: string, convertMarkdown: boolean = false): Promise<void> {
    if (!text || !isString(text)) return Promise.reject(new Error("Invalid input"));

    return new Promise(async (resolve, reject) => {
        try {
            let contentToCopy = text;
            let htmlContent = '';

            // If markdown conversion is needed
            if (convertMarkdown) {
                const md = window.markdownit({
                    html: true,
                    linkify: true,
                    typographer: true,
                }).use(window.markdownitIncrementalDOM, window.IncrementalDOM);

                htmlContent = md.render(text);

                const tableStyle = `
                    <style>
                        table, th, td {
                            border: 1px solid black;
                            border-collapse: collapse;
                            padding: 8px;
                        }
                    </style>
                `;

                htmlContent = tableStyle + htmlContent;
            }

            if ('clipboard' in navigator) {
                if (convertMarkdown) {
                    // Copy both HTML and plain text when markdown is converted
                    const htmlBlob = new Blob([htmlContent], { type: 'text/html' });
                    const plainTextBlob = new Blob([text], { type: 'text/plain' });

                    const data = [
                        new ClipboardItem({
                            'text/html': htmlBlob,
                            'text/plain': plainTextBlob
                        })
                    ];

                    await navigator.clipboard.write(data);
                } else {
                    await navigator.clipboard.writeText(contentToCopy);
                }
                resolve();
            } else {
                const temp = document.createElement("textarea");

                temp.style.pointerEvents = "none";
                temp.style.opacity = "0";
                document.body.appendChild(temp);

                temp.value = contentToCopy;
                temp.select();
                const didCopy = document.execCommand("copy");

                document.body.removeChild(temp);

                didCopy ? resolve() : reject();
            }
        } catch (error) {
            reject();
        }
    });
}


export const equals = (t1?: string, t2?: string, ignoreCase = true) => {

    if (t1 === t2) return true;

    if (!t1 || !t2) return false;

    if (!ignoreCase) return false;

    return t1.toLowerCase() === t2.toLowerCase();
};


export const trimChar = (string: string, charsToRemove: string[] | string, start = true, end = true) => {
    if (!string) return string;
    while (start && charsToRemove.includes(string.charAt(0))) {
        string = string.substring(1);
    }

    while (end && charsToRemove.includes(string.charAt(string.length - 1))) {
        string = string.substring(0, string.length - 1);
    }

    return string;
};

