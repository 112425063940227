import React, { useState, type PropsWithChildren } from 'react';
import Button, { ButtonThemes } from '../button/Button';
import { getClassNames } from '../../helpers/classHelpers';
import { DfAlert } from '@danfoss/mosaic-react';
import { type AlertType } from '@danfoss/mosaic-core';
import { TestIds } from '../../mocks/ids';
import style from './Alert.module.scss';

export type AlertProps = {
    testId?: string;
    detailsTestId?: string;

    headline?: string;
    description?: string;
    type?: AlertType;

    isWithCloseButton?: boolean;
    isVisible?: boolean;

    onClose?: () => void;

    className?: string;

    detailsClassName?: string;
    details?: JSX.Element;
    collapseDetails?: boolean;
    /**The label of the button to display the content in case collapseContent is set to true.
     * Default: 'See more details'
     */
    displayDetailsCta?: string;
    displayDetailsHideCta?: string;
};

const Alert: React.FC<PropsWithChildren<AlertProps>> = ({ testId = TestIds.alertDetailsComponent, detailsTestId = TestIds.alertDetailsButton, headline, description, type, isWithCloseButton, isVisible = true, onClose, children, className, collapseDetails, displayDetailsCta = 'See more details', displayDetailsHideCta = 'Hide details', details, detailsClassName }) => {

    const [displayContent, setDisplayContent] = useState(!collapseDetails);

    return <>
        <DfAlert
            data-testid={testId}
            className={className}
            type={type}
            isVisible={isVisible}
            heading={headline}
            description={description}
            isWithCloseButton={!!onClose || isWithCloseButton}
            onAlertClose={onClose}
        >
            {children}
        </DfAlert>

        {details && <div data-test-id={detailsTestId} className={getClassNames([style.details, detailsClassName])}>
            <Button className={style.button} label={!displayContent ? displayDetailsCta : displayDetailsHideCta} theme={ButtonThemes.textSecondary} onClick={() => setDisplayContent(s => !s)} />
            {displayContent && details}
        </div>}
    </>;
};

export default Alert;