import Tour from './elements/Tour';
import useTourFileUploads from './hooks/useTourFileUploads';
import useTourState from './hooks/useTourState';
import useTourCatalog from './hooks/useTourCatalog';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CallBackProps, Step } from 'react-joyride';
import { TourIds } from './consts';
import { useTours } from './context/ToursContext';
import { useLayout } from '../../contexts/layout/LayoutContext';
import { useMediaSize } from '../../hooks/useMediaSize';
import { MediaSize } from '../../constants/consts';
import { useLocation, useNavigate } from 'react-router-dom';

export type TourReturnType = {
    id: TourIds;
    label: string;
    data: {
        steps: Step[];
        scrollDuration?: number;
    };
};

export type TourDataProps = {
    run: boolean;
    setRun: (value: boolean) => void;
    steps: Step[];
    stepIndex: number;
    onClickHandler: (data: CallBackProps) => void;
};

const MAX_TOUR_SLIDES_COUNT = 15;
const TOURS_SHOW_UP_DELAY = 1e3;

export enum ToursSharedTargets {
    tourContainer = 'tour-container'
}

const Tours = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { setTours, getTours } = useTours();

    const { getTourState } = useToursMap();
    const { isShowingTermsAndConditions, hasCookieConsent } = useLayout();
    const isMobile = useMediaSize((ms) => ms <= MediaSize.sm);
    const [tourIds, setTourIds] = useState<TourIds[]>();

    // Load last-seen onboarding steps from users preferences?
    useEffect(() => {
        if (isMobile) return;

        const checkForTours = (async () => {
            try {
                const { unseen } = await getTours();
                setTourIds(unseen as TourIds[]);
            }
            catch (e) {
                console.error('Tour retrieval error occurred: ' + e);
            }
        });

        const h = setTimeout(() => {
            if (isShowingTermsAndConditions) return;
            // Since 'localhost' doesn't have cookies, we can't check for consent 
            if (window.location.host === 'localhost:3000' ? false : hasCookieConsent === undefined) return;
            checkForTours();
        }, TOURS_SHOW_UP_DELAY);

        return () => { clearTimeout(h); };
    }, [getTours, hasCookieConsent, isMobile, isShowingTermsAndConditions]);

    const toursData = useMemo(() => tourIds && getTourState(tourIds), [tourIds, getTourState]);
    const props = useTourState();


    const handleStart = useCallback(async (): Promise<void> => Promise.resolve(), []);

    // Return user to the original route
    const pathname = useRef(window.location.pathname);
    const search = useRef(window.location.search);
    const hash = useRef(window.location.hash);

    const handleFinish = useCallback(async () => {
        if (!toursData?.reviewIds) return;
        setTours(toursData?.reviewIds);
        navigate({
            pathname: pathname.current,
            hash: hash.current,
            search: search.current
        }, { replace: true });
    }, [navigate, setTours, toursData]);

    const hasSidePanelOpen = useRef(location.hash !== '');

    const handleNavigate = useCallback((hash?: string, pathname?: string, timeout?: number): Promise<void> | undefined => {
        if (hash && pathname) {
            return new Promise((r) => {
                navigate({ pathname, hash });
                setTimeout(() => r(), timeout); // Delay to allow the params to be set
            });
        }
        else if (hash) {
            return new Promise((r) => {
                navigate({ hash });
                setTimeout(() => r(), timeout); // Delay to allow the hash to be set
            });
        }
        else if (pathname) {
            return new Promise((r) => {
                navigate({ pathname });
                setTimeout(() => r(), timeout); // Delay to allow the pathname to be set
            });
        }
    }, [navigate]);

    const handleNext = useCallback(async (props: CallBackProps): Promise<void> => {
        const hash = props.step.data?.hash;
        const pathname = props.step.data?.pathname;
        const timeout = props.step.data?.timeout;
        hasSidePanelOpen.current = !!hash;
        await handleNavigate(hash, pathname, timeout);
    }, [handleNavigate]);

    const handleBack = useCallback(async (props: CallBackProps): Promise<void> => {
        const hash = props.step.data?.onBackHash;
        const pathname = props.step.data?.onBackPathname;
        const timeout = props.step.data?.timeout;
        await handleNavigate(hash, pathname, timeout);

        hasSidePanelOpen.current = false;
    }, [handleNavigate]);

    if (isMobile || !tourIds?.length) return null;


    return <Tour
        {...props}
        steps={toursData?.steps}
        onClickHandler={props.onClickHandler({
            onStart: handleStart,
            onNext: handleNext,
            onPrev: handleBack,
            onClose: handleFinish,
            onSkipped: handleFinish,
            onFinish: handleFinish,
        })}
    />;
};

export default Tours;


const useToursMap = () => {
    const tourFileUpload = useTourFileUploads();
    const tourCatalog = useTourCatalog();
    const allTours = useMemo(() => [tourFileUpload, tourCatalog], [tourFileUpload, tourCatalog]);

    const generateToursOverview = useCallback((labels: string[]) => {
        return {
            target: '#' + ToursSharedTargets.tourContainer,
            title: labels.length > 1 ? 'New features are here!' : 'A new feature is here!',
            content: <div>
                <ul>{labels.map((label) => <li style={{ listStyle: 'disc', marginLeft: '16px' }} key={label}>{label}</li>)}</ul>
                <br />
                Take a tour to get to know more.
            </div>,
            placement: 'center',
        } as Step;
    }, []);

    const getTourState = useCallback((ids: TourIds[]) => {
        const toursData = allTours?.filter((tour) => ids.includes(tour.id));
        if (!toursData) return;

        const tours = [];
        let slideCount = 0;

        for (const tour of toursData) {
            const unseenTourLength = tour?.data?.steps.length;
            if ((slideCount + unseenTourLength) >= MAX_TOUR_SLIDES_COUNT) break;
            slideCount += tour?.data?.steps.length;
            tours.push(tour);
        }

        const initialStep = generateToursOverview(tours.map((tour) => tour.label));
        const steps = [initialStep, ...tours.reduce((acc, tour) => [...acc, ...tour.data.steps], [] as Step[])];

        steps.forEach((step) => { step.disableBeacon = true; });
        return { steps, reviewIds: tours.map(x => x.id) };
    }, [allTours, generateToursOverview]);

    return { getTourState };
};