
import MarkdownWrapper from '../markdown-wrapper/MarkdownWrapper';
import { SectionData } from '../../models/SidePanelTypes';
import { getClassNames } from '../../helpers/classHelpers';
import { ReactNode } from 'react';
import styles from './Section.module.scss';

type Props = SectionData['properties'] & { className?: string; children?: ReactNode; };

const Section: React.FC<Props> = ({ className, title, subtitle, text, children }: Props) => {
    return (
        <div className={getClassNames([className, styles['section']])}>
            {title && <h5>{title}</h5>}
            {subtitle && <b>{subtitle}</b>}
            {text && <MarkdownWrapper className={styles.text} markdown={text} />}

            {children && <div className={styles.children}>
                {children}
            </div>}
        </div>
    );
};

export default Section;