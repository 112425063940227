import EventHandler from "./EventHandler";

export const cancellationError = "cancelled";
export const isCancellationError = (error: any) => error === cancellationError;

/**Shorthand for isCancellationError  */
const ice = isCancellationError;

/** Extends the Promise type with methods to handle promise cancellation.\
 * Can be used with`async` `await`.\
 * To test if the exception thrown is cancellation exception, use the method `isCancelled`
 * @param isCancellationError - used by the `failed` and `cancelled` method to check if the caught error is a cancellation error. Defaults to `isCancellationError`
 */
export default class TaskPromise<T> extends Promise<T> {
    /**Is disposed. */
    private _isDisposed?: boolean;
    private disposeHandler?: EventHandler<boolean>;
    get isDisposed() { return this._isDisposed; };
    readonly dispose = () => {
        if (this.isDisposed) return;
        this._isDisposed = true;

        if (!this.disposeHandler) return;
        this.disposeHandler.invoke(true);
        this.disposeHandler.offAll();
        this.disposeHandler = undefined;
    };

    readonly disposed = (callback: () => void) => {
        if (this.isDisposed) {
            setTimeout(callback);
            return;
        }
        if (!this.disposeHandler) this.disposeHandler = new EventHandler<boolean>();
        this.disposeHandler.on(callback);
    };

    /**
     * Attaches callbacks for the resolution and/or rejection of the Promise if it hasn't been disposed or cancelled.
     * @param onSucceeded The callback to execute when the Promise is resolved *ONLY* if it has not been disposed.
     * @param onFailed The callback to execute when the Promise is rejected *ONLY* if it has not been disposed or cancelled.
     * @returns A Promise for the completion of which ever callback is executed.
     */
    done = <TResult1 = T, TResult2 = never>(onSucceeded?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null, onFailed?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null): Promise<TResult1 | TResult2 | undefined> => {
        return this.then<TResult1 | undefined, TResult2 | undefined>(r => {
            if (!this._isDisposed && onSucceeded) return onSucceeded(r);
        }, e => {
            if (!this._isDisposed && !ice(e) && onFailed) return onFailed(e);
        });
    };

    /**
    * Attaches callbacks for the rejection of the Promise if it has not been disposed or cancelled.
    * @param onFailed The callback to execute when the Promise is rejected if it has not been disposed or cancelled.
    * @returns A Promise for the completion of which ever callback is executed.
    */
    failed = <TResult = never>(onFailed?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): Promise<T | TResult | undefined> => {
        return this.catch(e => {
            if (!this._isDisposed && !ice(e) && onFailed) return onFailed(e);
        });
    };

    /**
    * Attaches callbacks for the rejection of the Promise if it has been cancelled.
    * @param onFailed The callback to execute when the Promise is cancelled (rejected with the error `cancellationError`)
    * @returns A Promise for the completion of which ever callback is executed.
    */
    cancelled = <TResult = never>(onCancelled?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): Promise<T | TResult | undefined> => {
        return this.catch(e => {
            if (ice(e) && onCancelled) return onCancelled(e);
        });
    };

    /**
     * Similar to finally, but is only called back if the promise is not disposed.
     * 
     * It also include its own .catch() block to prevent errors - it is needed because
     * this method is used on promises that are not chained and lacking error boundaries.
     *  
     * @param action action to execute.
     */
    completed = (action: () => void) => {
        return this.catch(() => {}).finally(() => {
            if (!this._isDisposed) return action();
        });
    };

    transform = <TResult>(transformer: (data: T) => TResult) => {
        let rejectHandler = (_: any) => {};

        const promise = new TaskPromise<TResult>((resolve, reject) => {
            this.then(d => resolve(transformer(d)));
            rejectHandler = reject;
        });

        promise.disposed(() => {
            rejectHandler(cancellationError);
        });
        return promise;
    };

    static delay(delay: number) {
        let resolveHandler = () => {};
        let rejectHandler = (_: any) => {};
        const promise = new TaskPromise<boolean>((resolve, reject) => {
            resolveHandler = () => resolve(true);
            setTimeout(resolveHandler, delay);
            rejectHandler = reject;
        });
        promise.disposed(() => {
            resolveHandler = () => {};
            rejectHandler(cancellationError);
        });
        return promise;
    }

    static resolveTask<T>(result: T) {
        return new TaskPromise<T>(r => r(result));
    }

    static rejectTask<T>(error: any) {
        return new TaskPromise<T>((_, r) => r(error));
    }

    static wrap<T>(executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void) {
        return new TaskPromise<T>(executor);
    }

    static wrapWithDelay<T>(executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void, delay: number) {
        const delayPromise = TaskPromise.delay(delay);
        const promise = new TaskPromise<T>((resolve, reject) => {
            delayPromise.catch(reject);
            delayPromise.done(() => {
                const executionPromise = this.wrap(executor);
                // Remove delayPromise.dispose handler and assign the executionPromise.dispose as the cancellation handler.
                promise.disposeHandler?.off(delayPromise.dispose);
                promise.disposed(executionPromise.dispose);
                executionPromise.then(resolve, reject);
                executionPromise.cancelled(() => {
                    promise.disposeHandler?.offAll();
                    reject(cancellationError);
                });
            });
        });
        // If wrapper promise is cancelled, dispose delay promise to cancel inner execution.
        promise.disposed(delayPromise.dispose);
        return promise;
    }
}


