import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isArray } from '../../helpers/typeHelpers';
import { isObject } from '@danfoss/mosaic-core';
// import styles from './useSkeleton.module.scss';

const DEFAULT_SKELETON_ROWS = 3;

const AWAIT_BEFORE_SHOWING_SKELETON_TIMEOUT = 10;
const AWAIT_HIDING_SHOWING_SKELETON_TIMEOUT = 3000;
const EARLY_TERMINATION_TIMEOUT = 300;

/** It relies on __data__ being set to __undefined__ once content is loading, to trigger the skeleton loader */
const useSkeleton = <T,>({ active = false, data, columns, maxRowNumber = 10 }: { active?: boolean, data?: T; columns: string[]; maxRowNumber?: number; }) => {
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [previousData, setPreviousData] = useState<T | null>();
    const isShowingSkeleton = useMemo(() => showSkeleton, [showSkeleton]);
    const lastActivePageLengthRef = useRef(DEFAULT_SKELETON_ROWS);

    const [allowEarlySkeletonTermination, setAllowEarlySkeletonTermination] = useState(false);

    // // Append skeleton class to each column
    // const data = useMemo(() => {
    //     if (!isArray(incomingData)) return incomingData;

    //     return (incomingData as Record<string, any>[]).map(column => {
    //         // Loop through each key in the object
    //         for (let key in column) {
    //             if (column.hasOwnProperty(key)) {
    //                 // Update each value to "1"
    //                 column[key] = `<span class='${styles.skeleton}'>${column[key]}</span>`;
    //             }
    //         }

    //         return column;
    //     });
    // }, [incomingData]);

    // Prolong skeleton display if data is not loaded yet
    useEffect(() => {
        if (!active) return;

        const removeTimer = () => {
            setShowSkeleton(false);
            if (data !== undefined) setPreviousData(data);
        };

        if (allowEarlySkeletonTermination && data !== undefined) {
            return removeTimer();
        };

        const earlyTerminationTimeout = setTimeout(() => {
            setAllowEarlySkeletonTermination(true);
        }, EARLY_TERMINATION_TIMEOUT);

        const initSkeletonTimerInstance = setTimeout(() => {
            if (data !== undefined) return;
            setShowSkeleton(true);
        }, AWAIT_BEFORE_SHOWING_SKELETON_TIMEOUT);

        const cleanupSkeletonTimer = () => setTimeout(() => {
            if (data === undefined) cleanupSkeletonTimer();
            else removeTimer();
        }, AWAIT_HIDING_SHOWING_SKELETON_TIMEOUT);

        const cleanupSkeletonTimerInstance = cleanupSkeletonTimer();

        return () => {
            clearTimeout(earlyTerminationTimeout);
            clearTimeout(initSkeletonTimerInstance);
            clearTimeout(cleanupSkeletonTimerInstance);
        };
    }, [active, allowEarlySkeletonTermination, data]);

    const getSkeletonColumnPlaceholder = useCallback((rowNumber: number) => {
        if (!active) return [];

        return Array.from({ length: rowNumber }).map(() => {
            return columns.reduce((prev, id) => {
                return { ...prev, [id]: '' };
            }, {} as Record<string, string>);
        });
    }, [active, columns]);

    const skeletonPlaceholder = useMemo(() => {
        if (!active) return [];
        return getSkeletonColumnPlaceholder(lastActivePageLengthRef.current);
    }, [active, getSkeletonColumnPlaceholder]);

    useEffect(() => {
        if (!active) return;
        if (data === undefined || !columns.length || isShowingSkeleton) return;
        lastActivePageLengthRef.current = maxRowNumber ?? (isArray(data) && data.length) ?? (isObject(data) && Object.keys(data).length);
    }, [active, columns.length, data, isShowingSkeleton, maxRowNumber]);

    const returnData = useMemo(() => {
        if (!active) return data ?? [];

        if (data === undefined || isShowingSkeleton) return previousData ? previousData : skeletonPlaceholder;
        return data;
    }, [active, data, isShowingSkeleton, previousData, skeletonPlaceholder]);

    return {
        isShowingSkeleton: !active ? false : isShowingSkeleton,
        isInitialSkeleton: !active || !isShowingSkeleton ? false : !previousData,
        skeletonPlaceholder: !active ? [] : getSkeletonColumnPlaceholder(lastActivePageLengthRef.current),
        data: returnData,
    };
};

export default useSkeleton;