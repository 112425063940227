import React, { memo, useCallback, useMemo, useState } from 'react';
import TemperatureSlider from '../temperature-slider/TemperatureSlider';
import useLabels from '../../hooks/useLabels';
import Divider from '../../ui/divider/Divider';
import InputLabel from '../../ui/input-label/InputLabel';
import Accordion from '../../ui/accordion/Accordion';
import Select from '../../ui/select/Select';
import { MediaSize, defaultTemperature } from '../../constants/consts';
import { useMediaSize } from '../../hooks/useMediaSize';
import { AccordionVariantEnum } from '@danfoss/mosaic-core';
import { ChatInputModel, ChatInputPropsBase } from './models';
import { TestIds } from '../../mocks/ids';
import styles from './ChatInput.module.scss';

export type ChatInputOptionsProps = ChatInputPropsBase & Pick<ChatInputModel, "llm" | "persona" | "temperature">;

export const ChatInputOptions: React.NamedExoticComponent<ChatInputOptionsProps> = memo(({ llm, temperature, persona, onModelMerge, llmOptions, isInputDisabled }) => {
    const labels = useLabels();
    const l = useMemo(() => {
        return {
            accordionHl: labels.advancedSettings,
            beta: labels.beta,
            model: labels.model,
            modelIconTitle: labels.modelIconTitle,
            modalHl: labels.modelDescriptions,
            choosePersona: labels.choosePersona,
            choosePersonaDescriptions: labels.choosePersonaDescriptions,
            choosePersonaIconTitle: labels.choosePersonaIconTitle
        };
    }, [labels]);


    const isMobile = useMediaSize(ms => ms <= MediaSize.sm);
    const advancedPersonaSelected = !persona;

    const slider = <TemperatureSlider value={(temperature ?? defaultTemperature) * 10} onChange={v => onModelMerge({ temperature: parseFloat((v * 0.1).toFixed(3)) })} isDisabled={isInputDisabled} />;

    const llmModalBody = <LlmModalBody llmOptions={llmOptions} />;
    const llmUIOptions = useMemo(() => llmOptions.map(x => ({ ...x, 'data-testid': TestIds.dfSelectOption })), [llmOptions]);
    const renderAdvancedPersonaOptions = advancedPersonaSelected && <>
        {isMobile && <Accordion
            isDisabled={isInputDisabled}
            className={styles.accordion}
            isInitiallyOpen={true}
            headline={l.accordionHl}
            content={<>
                <Divider />
                {slider}
            </>} />}
        <div>
            <InputLabel
                testId={TestIds.advancedOptionsModal}
                iconTestId={TestIds.advancedOptionsModalIcon}
                label={l.model}
                tooltipPlacement={'top-start'}
                modal={{ headline: l.modalHl, body: llmModalBody, }}
                iconTitle={l.modelIconTitle}
                isDisabled={isInputDisabled}
                className={styles['select-label']}
            />
            <Select
                testId={TestIds.dfSelect}
                isDisabled={isInputDisabled}
                showSelectedOptions
                options={llmUIOptions}
                value={llmUIOptions.find(x => x.value === llm)?.label ?? llmOptions?.[0]?.label}
                onChange={(e) => onModelMerge({ llm: e?.value as string })}
            />
        </div>

        {!isMobile && slider}
    </>;

    return <div className={styles['settings-container']}>
        {renderAdvancedPersonaOptions}
    </div>;
});

const LlmModalBody: React.NamedExoticComponent<Pick<ChatInputPropsBase, "llmOptions">> = memo(({ llmOptions }) => {
    const [openAccordions, setOpenAccordions] = useState<number[]>([]);
    const onToggleAccordionHandler = useCallback((index: number) => {
        setOpenAccordions(prev => {
            const isOpen = prev.includes(index);
            return isOpen ? prev.filter(i => i !== index) : [...prev, index];
        });
    }, []);

    return <>
        {
            llmOptions.map((llm, i) => <Accordion
                key={i}
                headline={llm.label}
                content={<p className={styles['accordion-desc']}>{llm.description}</p>}

                externalState={{
                    isOpen: openAccordions.includes(i),
                    onToggle: (isOpen: boolean) => onToggleAccordionHandler(i)
                }}
                variant={AccordionVariantEnum.WithDivider}
            />)
        }
    </>;
});