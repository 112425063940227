import { PropsWithChildren, createContext, useContext } from 'react';
import { ToursResponseType } from '../types/ToursTypes';
import { IToursService } from '../services/IToursService';
import { TourIds } from '../consts';


const defaultToursService: IToursService = {
    getTours: function (): Promise<ToursResponseType> {
        throw new Error('Function not implemented.');
    },
    setTours: function (tourIds: TourIds[]): Promise<void> {
        throw new Error('Function not implemented.');
    },
};

export const InitToursContext = createContext<IToursService>(defaultToursService);

const ToursContext = (props: PropsWithChildren<IToursService>) => {
    return (
        <InitToursContext.Provider value={props}>
            {props.children}
        </InitToursContext.Provider>
    );
};


export const useTours = () => useContext(InitToursContext);

export default ToursContext;