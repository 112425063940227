import { useEffect, useRef } from "react";

/**Retains a ref that is always updated with the latest value. Useful when a non-reactive ref is need to a reactive state*/
export default function useIsDisposedRef() {
    const ref = useRef(false);
    useEffect(() => {
        ref.current = false;
        return () => {
            ref.current = true;
        };
    }, []);
    return ref;
} 